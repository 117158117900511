import React from "react";
import EventAnalytics from "../../images/EventAnalytics.jpg";

export default function FutureEventAnalytics() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <img
        style={{ padding: 5 }}
        height={"100%"}
        width={"100%"}
        src={EventAnalytics}
      />
    </div>
  );
}
