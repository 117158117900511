import { Box, Button, Modal } from "@mantine/core";
import React, { useState } from "react";
import InputModal from "./InputModal";
interface Props {
  onClosePress: () => void;
  customData: (data: any) => void;
}

type ModalState = "initial" | "Phone Number" | "Email" | "Address" | "Custom";
export default function CreateNewProfileSectionsModalBox({
  onClosePress,
  customData,
}: Props) {
  const [modalState, setModalState] = useState<ModalState>("initial");

  const fields = [
    {
      name: "Phone Number",
      // icon: <Phone />,
      onPress: () => setModalState("Phone Number"),
    },
    {
      name: "Email",
      // icon: <Email />,
      onPress: () => setModalState("Email"),
    },
    {
      name: "Address",
      // icon: <Address />,
      onPress: () => setModalState("Address"),
    },
    {
      name: "Custom",
      // icon: <Custom />,
      onPress: () => setModalState("Custom"),
    },
    // {
    //   name: "Cancel",
    //   // icon: <Cancel />,
    //   onPress: onClosePress,
    // },
  ];

  return (
    <>
      <Modal
        opened={true}
        onClose={onClosePress}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={"New Sections"}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {modalState === "initial" ? (
            <Button.Group orientation="vertical">
              {fields.map((item, index) => (
                <Button variant="default" onClick={item.onPress} key={index}>
                  {item.name}
                </Button>
              ))}
            </Button.Group>
          ) : (
            <InputModal
              title={modalState}
              onSubmitData={(data) => customData(data)}
              closeModal={onClosePress}
            />
          )}
          {/* </div> */}
        </Box>
      </Modal>
    </>
  );
}
