import React, { useEffect, useState } from "react";
import { Accordion } from "@mantine/core";
import MainLink from "../../Components/MainLink";
import { useRecoilState } from "recoil";
import { corpListState, sideBarSearchText } from "../../recoil_state";
import {
  analysis,
  client,
  corp,
  device,
  events,
  futureCorpAdmin,
  futureDirectory,
  futureEvent,
  futureEventAnalytics,
  home,
  inbox,
  notifications,
  profile,
  staff,
  tags,
  user,
  tempPhoneNumber,
} from "./LinkObject";
import SearchBar from "../../Bars/SearchBar";

function CorpLinkList() {
  const [corpList] = useRecoilState(corpListState);
  const [searchText, setSearchText] = useRecoilState(sideBarSearchText);
  const [currentCorpList, setCurrentCorpList] = useState(corpList);
  const corpPathList = [
    profile,
    device,
    // client,
    // notifications,
    // inbox,
    analysis,
  ];

  useEffect(() => {
    const filter = corpList.filter((item) =>
      item.corporate_name.toLowerCase().includes(searchText.toLowerCase())
    );

    if (filter.length != 0) {
      setCurrentCorpList(filter);
    }
  }, [searchText,corpList]);

  const showList = currentCorpList.map((corp, index) => (
    <Accordion.Item
      value={corp.corporate_name ? corp.corporate_name : "loop"}
      key={index}
    >
      <Accordion.Control>{corp.corporate_name}</Accordion.Control>
      <Accordion.Panel>
        {corpPathList &&
          corpPathList.map((item) => (
            <MainLink {...item} key={item.label + corp.id} state={corp.id} />
          ))}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return showList;
}

export function SuperAdminLinks() {
  const showCorpList = CorpLinkList();
  const [searchText, setSearchText] = useRecoilState(sideBarSearchText);
  const corpPathList = [
    corp,
    user,
    staff,
    profile,
    device,
    // client,
    events,
    // notifications,
    // inbox,
    analysis,
    tags,
    tempPhoneNumber,
    futureEvent,
    futureEventAnalytics,
    futureCorpAdmin,
    futureDirectory,
  ];
  const showList = (
    <Accordion.Item value="super admin">
      <Accordion.Control>super admin</Accordion.Control>
      <Accordion.Panel>
        {corpPathList &&
          corpPathList.map((item) => (
            <MainLink {...item} key={item.label} state={"superAdmin"} />
          ))}
      </Accordion.Panel>
    </Accordion.Item>
  );

  return (
    <>
      <div style={{ paddingBottom: 5 }}>
        <SearchBar
          onSearch={setSearchText}
          onRemove={() => setSearchText("")}
        />
      </div>
      {/* <MainLink {...home} key={home.label} /> */}
      <Accordion
        variant="separated"
        chevronPosition="left"
        defaultValue="customization"
      >
        {showList}
        {showCorpList}
      </Accordion>
    </>
  );
}

export function CorpadminLinks() {
  const showCorpList = CorpLinkList();

  return (
    <>
      <MainLink {...home} key={home.label} />
      <Accordion
        variant="separated"
        chevronPosition="left"
        defaultValue="customization"
      >
        {showCorpList}
      </Accordion>
    </>
  );
}
