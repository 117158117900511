import { Button } from "@mantine/core";
import React from "react";

interface Props {
  name?: string;
  disabled?: boolean;
  onActive: () => void;
}

export default function ActivateButton(props: Props) {
  return (
    <Button
      disabled={props.disabled ? props.disabled : false}
      color="blue"
      onClick={props.onActive}
    >
      {props.name ? props.name : "Activate"}
    </Button>
  );
}
