import {Alert, Tabs } from "@mantine/core";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import {
  editingProfileState,
} from "../../recoil_state";
import EditButton from "../Buttons/EditButton";
import {
  deleteMethodWithBody,
  getMethod,
  patchMethod,
  postMethod,
} from "../../utilities/fetchMethod";
import CreateNewProfileSectionsModalBox from "../ModalBox/CreateNewProfileSectionsModalBox";
import InputModal from "../ModalBox/InputModal";
interface ProfileInfoProps {
  headName: string;
}

export default function ProfileSections({ headName }: ProfileInfoProps) {
  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);

  const [newModal, setNewModal] = useState(false);
  const [error, setError] = useState<{ color: string; message: string }>({
    color: "red",
    message: "",
  });
  //-------------------------------------------------------------------------------------------
  // Edit and new sections
  //-------------------------------------------------------------------------------------------

  async function save( //Edit and new sections
    data: { type: string; title: string; value: string },
    newSections: boolean,
    sectionsId?: number
  ) {
    let _Method;
    let body;
    let id;
    if (newSections) {
      body = {
        type: data.type,
        section_title: data.type == "email" ? "Email" : data.title,
        section_info:
          data.type == "email" ? data.value.toLowerCase().trim() : data.value,
      };
      _Method = await postMethod(body);
      id = editingProfile.profile.id;
    } else {
      body = {
        section_title: data.type == "email" ? "Email" : data.title,
        section_info:
          data.type == "email" ? data.value.toLowerCase().trim() : data.value,
      };
      _Method = await patchMethod(body);
      id = sectionsId;
    }
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/profiles/contact/${encodeURI(
        String(id)
      )}`,
      _Method
    );
    const result = await res.json();

    if (result.message && result.message === "success") {
      const _getMethod = await getMethod();
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profiles/${encodeURI(
          String(editingProfile.profile.id)
        )}`,
        _getMethod
      );
      const result = await res.json();
      if (result.profile) {
        setEditingProfile(result);
        window.history.replaceState(
          { key: window.history.state.key, usr: result },
          ""
        );
        setError({color: 'green', message: 'edit success!'})
      } else {
        setError({color: 'red', message: 'Please try again'})
      }
    } else {
      setError({color: 'red', message: 'Please try again'})
    }
  }
  //-------------------------------------------------------------------------------------------
  // Remove Sections
  //-------------------------------------------------------------------------------------------

  async function removeSections(sectionsId: number) {
    const body = [{ id: sectionsId }];
    const _deleteMethodWithBody = await deleteMethodWithBody(body);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/profiles/contacts`,
      _deleteMethodWithBody
    );
    const result = await res.json();
    if (result.message && result.message === "success") {
      const _getMethod = await getMethod();
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profiles/${encodeURI(
          String(editingProfile.profile.id)
        )}`,
        _getMethod
      );
      const result = await res.json();
      if (result.profile) {
        setEditingProfile(result);
        window.history.replaceState(
          { key: window.history.state.key, usr: result },
          ""
        );
      }
    } else {
      console.log(result);
    }
  }

  return (
    <Tabs.Panel
      value={headName}
      pt="xs"
      style={{ padding: 10, marginBottom: 25 }}
    >
      <div>
        {/* create new sevtion */}
        {newModal && (
          <CreateNewProfileSectionsModalBox
            onClosePress={() => setNewModal(false)}
            customData={(data) => save(data, true)}
          />
        )}
        {error.message != "" && (
        <Alert title="!!!!!" color={error.color}>
          {error.message}
        </Alert>
      )}
        <EditButton
          name={"+ New Section"}
          onEdit={() => setNewModal(true)}
          style={{ width: "100%" }}
        />
        {/* map section */}
        {editingProfile.profile_sections &&
          editingProfile.profile_sections.map((item) => (
            <div
              style={{
                width: "100%",
                borderWidth: 0.5,
                borderColor: "grey",
                border: "solid",
                marginBlock: 10,
              }}
              key={item.id}
            >
              <div>
                <InputModal
                  title={item.type}
                  onSubmitData={(data) => save(data, false, item.id)}
                  data={item}
                  onRemove={() => {
                    removeSections(item.id);
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </Tabs.Panel>
  );
}
