import { Button, TextInput } from "@mantine/core";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { editingProfileState, newGalleryState } from "../../recoil_state";
import DeleteButton from "../Buttons/DeleteButton";
import EditButton from "../Buttons/EditButton";
import CreateNewGalleryModalBox from "../ModalBox/CreateNewGalleryModalBox";

interface EditGalleryProps {
  gallery: {
    active: boolean;
    id: number;
    picture_url?: string;
    image_url?: string;
    resized_url: string;
    description: string;
    link: string;
  };
  products: boolean ;
  onEdit: (withGallery: boolean) => void;
  onRemove: () => void;
}
export default function EditGallery({
  gallery,
  products,
  onEdit,
  onRemove,
}: EditGalleryProps) {
  const [descriptionTemp, setDescriptionTemp] = useState<string>("");
  const [linkTemp, setLinkTemp] = useState<string>("");
  const [editModal, setEditModal] = useState(false);
  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);

  useEffect(() => {
    setDescriptionTemp(gallery.description);
    setLinkTemp(gallery.link);
  }, []);
  const resetNewGalleryState = useResetRecoilState(newGalleryState);
  return (
    <div
      style={{
        width: "100%",
        borderWidth: 0.5,
        borderColor: "grey",
        border: "solid",
        marginBlock: 10,
      }}
    >
      {editModal && (
        <CreateNewGalleryModalBox
          onClose={() => setEditModal(false)}
          onRefresh={() => {}}
          onSubmit={() => {
            onEdit(true);
            setEditModal(false);
            resetNewGalleryState();
          }}
          createNewGallery={false}
          products={products}
        />
      )}
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "20%" }}>
          <div>original</div>
          <img
            style={{ padding: 5 }}
            height={100}
            width={100}
            src={gallery.picture_url ? gallery.picture_url : gallery.image_url}
          />
        </div>
        <div style={{ width: "20%" }}>
          <div>resized</div>
          <img
            style={{ padding: 5 }}
            height={100}
            width={100}
            src={gallery.resized_url}
          />
        </div>
        <div style={{ width: "59%" }}>
          <TextInput
            style={{ paddingBlock: 3 }}
            placeholder="description"
            label="description"
            value={descriptionTemp}
            onChange={(event) => {
              const value = event.currentTarget.value;
              if (value) {
                setDescriptionTemp(value);
              } else {
                setDescriptionTemp("");
              }
            }}
            onBlurCapture={(event) => {
              if (products) {
                setEditingProfile(
                  produce((draftState) => {
                    draftState.products[
                      draftState.products.findIndex(
                        (item) => item.id == gallery.id
                      )
                    ].description = descriptionTemp;
                  })
                );
              } else {
                setEditingProfile(
                  produce((draftState) => {
                    draftState.gallery[
                      draftState.gallery.findIndex(
                        (item) => item.id == gallery.id
                      )
                    ].description = descriptionTemp;
                  })
                );
              }
            }}
          />

          <TextInput
            style={{ paddingBlock: 3 }}
            placeholder="link"
            label="link"
            value={linkTemp}
            onChange={(event) => {
              const value = event.currentTarget.value.trim();
              if (value) {
                setLinkTemp(value);
              } else {
                setLinkTemp("");
              }
            }}
            onBlurCapture={(event) => {
              if (products) {
                setEditingProfile(
                  produce((draftState) => {
                    draftState.products[
                      draftState.products.findIndex(
                        (item) => item.id == gallery.id
                      )
                    ].link = linkTemp;
                  })
                );
              } else {
                setEditingProfile(
                  produce((draftState) => {
                    draftState.gallery[
                      draftState.gallery.findIndex(
                        (item) => item.id == gallery.id
                      )
                    ].link = linkTemp;
                  })
                );
              }
            }}
          />
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ padding: 3 }}>
            <EditButton
              name={"Save"}
              onEdit={() => {
                onEdit(false);
              }}
            />
          </div>
          <div style={{ padding: 3 }}>
            <Button
              color="green"
              onClick={() => {
                setEditModal(true);
              }}
            >
              upload new gallery
            </Button>
          </div>
        </div>
        <div style={{ padding: 3 }}>
          <DeleteButton
            onRemove={() => {
              onRemove();
            }}
          />
        </div>
      </div>
    </div>
  );
}
