import React, { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { userState } from "../recoil_state";
import { getMethod } from "./fetchMethod";

export default function ProtectedRoute(props: PropsWithChildren) {
  //-------------------------------------------------------------------------------------------
  // usestate
  //-------------------------------------------------------------------------------------------

  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  async function checking() {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      const id = localStorage.getItem("id");
      const _getMethod = await getMethod();
      if (user.id === 0) {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/users/${id}`,
          _getMethod
        );

        const result = await res.json();
        setUser(result);
      }
    }
  }
  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------
  useEffect(() => {
    checking()
  });

  return <>{props.children}</>;
}
