import { Group, NativeSelect } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";

import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { UserInfo } from "../utilities/models";

interface Props {
  filteredData: (value: UserInfo[]) => void;
  backupData: UserInfo[];
  searchText?: string;
}

export default function UserDashboardFilterBar(props: Props) {
  const { RangePicker } = DatePicker;

  const [dateString, setDateString] = useState<[string, string] | null>([
    "",
    "",
  ]);

  const [filterActive, setFilterActive] = useState("");
  const [filterAble, setFilterAble] = useState("");
  const [filterProfileType, setFilterProfileType] = useState("");

  dayjs.extend(customParseFormat);

  //main useEffect
  useEffect(() => {
    let toFilterData = props.backupData;

    //search bar
    if (props.searchText) {
      const lowerCaseSearchText = props.searchText.toLowerCase();
      toFilterData = toFilterData.filter(
        (item) =>
          item.phone_number.includes(lowerCaseSearchText!) ||
          item.email.includes(lowerCaseSearchText!)
      );
    }
    // Created_at
    if (dateString && dateString[0].length > 0) {
      toFilterData = filterDate(dateString, toFilterData);
    }
    // Active ["All", "Active", "Non-Active"]
    if (filterActive.length > 0 && filterActive != "All") {
      if (filterActive == "Active") {
        toFilterData = toFilterData.filter((item) => item.active == true);
      } else if (filterActive == "Non-Active") {
        toFilterData = toFilterData.filter((item) => item.active == false);
      }
    }

    // Active ["All", "Able", "Disable"]
    if (filterAble.length > 0 && filterAble != "All") {
      if (filterAble == "Able") {
        toFilterData = toFilterData.filter((item) => {
          return item.disable == false;
        });
      } else if (filterAble == "Disable") {
        toFilterData = toFilterData.filter((item) => item.disable == true);
      }
    }

    props.filteredData(toFilterData);
  }, [
    filterProfileType,
    filterAble,
    filterActive,
    dateString,
    props.searchText,
  ]);

  //=======================================================================================

  //filter Date function
  function filterDate(dateString: [string, string], toFilterData: UserInfo[]) {
    const formatCreated_at = (created_at: string) => {
      return dayjs(created_at).format("YYYY-MM-DD HH:mm:ss");
    };

    const filter = toFilterData.filter(
      (item) =>
        formatCreated_at(item.created_at) >= dateString[0] &&
        formatCreated_at(item.created_at) <= dateString[1]
    );
    return filter;
  }
  //==========================================================================================
  const onChangeDateString = (
    date: null | (Dayjs | null)[],
    dateString: [string, string]
  ) => {
    if (date) {
      setDateString(dateString);
    } else {
      setDateString(null);
    }
  };

  return (
    <Group>
      <div style={{ display: "flex" }}>
        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>Created_at : </div>

          <RangePicker
            onChange={onChangeDateString}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [
                dayjs("00:00:00", "HH:mm:ss"),
                dayjs("11:59:59", "HH:mm:ss"),
              ],
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>Active :</div>

          <NativeSelect
            data={["All", "Active", "Non-Active"]}
            onChange={(e) => {
              setFilterActive(e.currentTarget.value);
            }}
            size="xs"
          />
        </div>

        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>Able :</div>

          <NativeSelect
            data={["All", "Able", "Disable"]}
            onChange={(e) => {
              setFilterAble(e.currentTarget.value);
            }}
            size="xs"
          />
        </div>
      </div>
    </Group>
  );
}
