import { Box, Button, Group, Modal } from "@mantine/core";
import React, { useState } from "react";
import { patchMethod } from "../../utilities/fetchMethod";
import { useRecoilState } from "recoil";
import { corpListState } from "../../recoil_state";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  link: string;
  corpId: number;
}

export default function ApplyModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit users
  async function applyItem() {
    setDisable(true);
    setError("");

    const _patchMethod = await patchMethod({ corpId: props.corpId });
    const res = await fetch(props.link, _patchMethod);
    const result = await res.json();
    if (res.status === 200) {
      props.onRefresh();
      props.onClose();
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("Please try again");
      setDisable(false);
    }
  }
  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Apply`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <div style={{ color: "red", fontSize: 15 }}>{error}</div>
          <>
            <div>{`This action will update all Staff Profiles according to the Corporate Main Profile. Once confirmed, it cannot be reversed. 5 items included:`}</div>
            <div>{"1) Profile banner"}</div>
            <div>{"2) Grid Links"}</div>
            <div>{"3) List Links"}</div>
            <div>{"4) Gallery"}</div>
            <div>{"5) Products"}</div>
          </>

          <Group position="right" mt="md">
            <Button
              disabled={disable}
              color="red"
              type="submit"
              onClick={() => applyItem()}
            >
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
