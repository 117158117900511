import { Box, Button, Group, Modal } from "@mantine/core";
import React, { useState } from "react";
import { deleteMethodWithBody, patchMethod } from "../../utilities/fetchMethod";

interface Props {
  itemArray: number[];
  onClose: () => void;
  onRefresh: () => void;
  link: string;
  name?: string;
}

export default function AbleModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit users
  async function disableItem() {
    setDisable(true);
    setError("");
    const _deleteMethod = await deleteMethodWithBody(props.itemArray);
    const res = await fetch(props.link, _deleteMethod);
    const result = await res.json();
    if (res.status === 200) {
      props.onRefresh();
      props.onClose();
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("Please try again");
      setDisable(false);
    }
  }

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`delete`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <div style={{ color: "red", fontSize: 15 }}>{error}</div>
          {props.name ? (
            <>
              <div>{`Are you sure you want to able  : ${props.name} ?`}</div>
              <div>This action cannot be returned !</div>
            </>
          ) : (
            <>
              <div>{`Are you sure you want to able ids : ${props.itemArray},`}</div>
              <div>{`total ${props.itemArray.length} items ? `}</div>
            </>
          )}
          <Group position="right" mt="md">
            <Button
              disabled={disable}
              color="red"
              type="submit"
              onClick={() => disableItem()}
            >
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
