import { Alert, Box, Button, Group, Modal, PasswordInput } from "@mantine/core";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../recoil_state";
import { patchMethod } from "../../utilities/fetchMethod";

interface Props {
  onClose: () => void;
}

export default function ContactLoopModal(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Contact LOOP`}
      >
        <Box sx={{ maxWidth: 350 }} mx="auto">
          <Alert>
            <div>{`You can contact LOOP with the following:`}<br/>
            {"Email: gdlabhk@gmail.com"}</div>
          </Alert>
        </Box>
      </Modal>
    </>
  );
}
