import { Alert, Box, Modal, Select } from "@mantine/core";
import produce from "immer";
import React, { useState } from "react";
import { useRecoilState,} from "recoil";
import { editingProfileState } from "../../recoil_state";
import { getMethod, patchMethod } from "../../utilities/fetchMethod";
import { ProfileLinks } from "../../utilities/models";
import EditButton from "../Buttons/EditButton";

interface Props {
  custom: boolean;
  fetchUrl: string;
  links: ProfileLinks[];
  onClose: () => void;
  onRefresh: () => void;
}

export default function ChangeOrderingSocialLinkModalBox(props: Props) {
  const [newOrder, setNewOrder] = useState<ProfileLinks[]>(props.links);
  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);
  const [error, setError] = useState<{ color: string; message: string }>({
    color: "red",
    message: "",
  });

  async function save() {
    const _patchMethod = await patchMethod(newOrder);

    let url = "";
    if (props.custom) {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/profiles/links/ordering/${encodeURI(
        String(editingProfile.profile.id)
      )}/custom`;
    } else {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/profiles/links/ordering/${encodeURI(
        String(editingProfile.profile.id)
      )}/social`;
    }

    const res = await fetch(url, _patchMethod);
    const result = await res.json();

    if (result.message === "success") {
      const _getMethod = await getMethod();
      const res = await fetch(props.fetchUrl, _getMethod);
      const result = await res.json();
      if (result) {
        setEditingProfile(result);
        window.history.replaceState(
          { key: window.history.state.key, usr: editingProfile },
          ""
        );
        props.onClose();
        props.onRefresh();
      } else {
        setError({ color: "red", message: "Please try again" });
      }
    } else {
      setError({ color: "red", message: "Please try again" });
    }
  }

  function buildSelector() {
    let selector: { value: string; label: string }[] = [];
    for (let i = 1; i < props.links.length + 1; i++) {
      selector.push({ value: String(i), label: String(i) });
    }
    return selector;
  }

  function arrayMove(
    arr: any[] | undefined,
    oldIndex: number,
    newIndex: number
  ) {
    if (!arr || newIndex >= arr.length) {
      return;
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Change Ordering`}
        size={"xl"}
      >
        <Box sx={{ maxWidth: 1000 }} mx="lg">
          <EditButton
            name={"save ordering"}
            onEdit={() => save()}
            style={{ width: "100%" }}
          />
          {error.message !== "" && (
            <Alert title="!!!!!" color={error.color}>
              {error.message}
            </Alert>
          )}
          {newOrder &&
            newOrder.map((item, index) => (
              <div
                key={item.id}
                style={{
                  marginTop: 20,
                  padding: 10,
                  borderWidth: 1,
                  borderColor: "grey",
                  border: "solid",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ height: 45, width: 45, paddingRight: 20 }}
                    src={item.icon_url}
                    alt=""
                  />
                  <div>
                    <div>{`Title: ${item.link_title}`}</div>
                    <div>{`Link: ${item.social_link}`}</div>
                  </div>
                </div>
                <div>
                  <Select
                    data={buildSelector()}
                    value={(index + 1).toString()}
                    onChange={(value) => {
                      if (!value) {
                        return;
                      }
                      setNewOrder(
                        produce((draft) => {
                          arrayMove(draft, index, parseInt(value) - 1);
                        })
                      );
                    }}
                  />
                </div>
              </div>
            ))}
        </Box>
      </Modal>
    </>
  );
}
