import { Button } from '@mantine/core'
import React from 'react'

interface Props {
    color: string
    name: string
}

export default function DisableButton(props: Props) {
  return (
    <Button
    sx={{
        '&[data-disabled]': { opacity: 0.4, color: props.color},
      }}
    disabled>
    {props.name}
    </Button>
  )
}
