import React from "react";
import Directory from "../../images/Directory.png";

export default function FutureDirectory() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <img
        style={{ padding: 5 }}
        height={"100%"}
        width={"100%"}
        src={Directory}
      />
    </div>
  );
}
