import {
  Box,
  Button,
  Group,
  Modal,
  NativeSelect,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  phoneLocationsDataState,
  phoneLocationsState,
} from "../../recoil_state";
import { postMethod } from "../../utilities/fetchMethod";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
}

export default function CreateUserModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const phoneLocations = useRecoilValue(phoneLocationsState);
  const phoneLocationsData = useRecoilValue(phoneLocationsDataState);

  //form
  const form = useForm({
    initialValues: {
      phone_location: 0,
      phone_number: "",
      email: "",
    },
    validate: {
      phone_number: (value) =>
        isNaN(parseInt(value)) ? "Invalid phone number" : null,
      email: (value) =>
        !/^\S+@\S+$/.test(value) ? "Invalid email"  : null,
      phone_location: (value) => !value ? "please choose a location" : null,
    },
    validateInputOnChange: true,
    clearInputErrorOnChange: false,
  });

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //create users
  async function createItem(form: any) {
    setError("");
    form.phone_location = phoneLocations.find(
      (item) => item.id == form.phone_location
    );

    const _postMethod = await postMethod(form);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/users`,
      _postMethod
    );
    const result = await res.json();
    if (result.message === "user successfully created") {
      props.onRefresh();
      props.onClose();
    } else {
      setError(result.error);
    }
  }
  //-------------------------------------------------------------------------------------------
  // useEffect error handling
  //-------------------------------------------------------------------------------------------
  // useEffect(() => {
  //   console.log(error)
  //   if (error.length > 1) {
  //     form.validate();
  //   }
  // }, [error, form]);
  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Create User`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <form onSubmit={form.onSubmit((form) => createItem(form))}>
            <TextInput
              withAsterisk
              label={"Phone Number"}
              {...form.getInputProps(`phone_number`)}
            />
            <TextInput
              withAsterisk
              label={"Email"}
              {...form.getInputProps(`email`)}
            />
             <Select
              data={phoneLocationsData}
              label="Phone location"
              placeholder="Phone location"
              radius="xs"
              searchable
              withAsterisk
              min={0}
              {...form.getInputProps(`phone_location`)}
            />
            <div style={{color: "red"}}>{error}</div>
            <Group position="right" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </>
  );
}
