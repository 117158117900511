import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCalendarDays,
  faChartSimple,
  faEnvelope,
  faHouse,
  faInbox,
  faStar,
  faUser,
  faTags,
  faPortrait,
  faBuilding,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";

export const home = {
  link: "/dashboard/home",
  icon: <FontAwesomeIcon icon={faHouse} size={"sm"} />,
  color: "blue",
  label: "Home",
  superAdmin: true,
  corpAdmin: true,
};

export const corp = {
  link: "/dashboard/corp",
  icon: <FontAwesomeIcon icon={faBuilding} size={"sm"} />,
  state: "",
  color: "grape",
  label: "Corp Center",
  superAdmin: true,
  corpAdmin: true,
};

export const staff = {
  link: "/dashboard/staff",
  icon: <FontAwesomeIcon icon={faUser} size={"sm"} />,
  state: "",
  color: "blue",
  label: "Staff Center",
  superAdmin: true,
  corpAdmin: true,
};

export const user = {
  link: "/dashboard/user",
  icon: <FontAwesomeIcon icon={faUser} size={"sm"} />,
  state: "",
  color: "blue",
  label: "User Center",
  superAdmin: true,
  corpAdmin: false,
};

export const profile = {
  link: "/dashboard/profile",
  icon: <FontAwesomeIcon icon={faPortrait} size={"sm"} />,
  color: "blue",
  label: "Profile Center",
  superAdmin: true,
  corpAdmin: true,
};
export const device = {
  link: "/dashboard/device",
  icon: <FontAwesomeIcon icon={faBolt} size={"sm"} />,
  color: "blue",
  label: "Device Center",
  superAdmin: true,
  corpAdmin: false,
};
export const client = {
  link: "/dashboard",
  icon: <FontAwesomeIcon icon={faEnvelope} size={"sm"} />,
  color: "blue",
  label: "Client Center",
  superAdmin: true,
  corpAdmin: true,
};
export const products = {
  link: "/dashboard",
  icon: <FontAwesomeIcon icon={faStar} size={"sm"} />,
  color: "blue",
  label: "Products",
  superAdmin: true,
  corpAdmin: true,
};

export const events = {
  link: "/dashboard/events",
  icon: <FontAwesomeIcon icon={faCalendarDays} size={"sm"} />,
  color: "teal",
  label: "Events",
  superAdmin: true,
  corpAdmin: true,
};
export const notifications = {
  link: "/dashboard",
  icon: <FontAwesomeIcon icon={faBell} size={"sm"} />,
  color: "grape",
  label: "Notifications",
  superAdmin: true,
  corpAdmin: true,
};
export const inbox = {
  link: "/dashboard",
  icon: <FontAwesomeIcon icon={faInbox} size={"sm"} />,
  color: "grape",
  label: "inbox",
  superAdmin: true,
  corpAdmin: true,
};

export const analysis = {
  link: "/dashboard/analysis",
  icon: <FontAwesomeIcon icon={faChartSimple} size={"sm"} />,
  color: "grape",
  label: "Analysis",
  superAdmin: true,
  corpAdmin: true,
};

export const tags = {
  link: "/dashboard/tags",
  icon: <FontAwesomeIcon icon={faTags} size={"sm"} />,
  color: "grape",
  label: "Tags",
  superAdmin: true,
  corpAdmin: false,
};

export const futureEvent = {
  link: "/dashboard/futureEvent",
  icon: <FontAwesomeIcon icon={faCalendarDays} size={"sm"} />,
  color: "red",
  label: "FutureEvent",
  superAdmin: true,
  corpAdmin: false,
};

export const tempPhoneNumber = {
  link: "/dashboard/tempPhoneNumber",
  icon: <FontAwesomeIcon icon={faChartSimple} size={"sm"} />,
  color: "red",
  label: "TempPhoneNumber",
  superAdmin: true,
  corpAdmin: false,
};

export const futureEventAnalytics = {
  link: "/dashboard/futureEventAnalytics",
  icon: <FontAwesomeIcon icon={faCalendarDays} size={"sm"} />,
  color: "red",
  label: "FutureEventAnalytics",
  superAdmin: true,
  corpAdmin: false,
};

export const futureCorpAdmin = {
  link: "/dashboard/futureCorpAdmin",
  icon: <FontAwesomeIcon icon={faCalendarDays} size={"sm"} />,
  color: "red",
  label: "FutureCorpAdmin",
  superAdmin: true,
  corpAdmin: false,
};

export const futureDirectory = {
  link: "/dashboard/futureDirectory",
  icon: <FontAwesomeIcon icon={faCalendarDays} size={"sm"} />,
  color: "red",
  label: "FutureDirectory",
  superAdmin: true,
  corpAdmin: false,
};
