import {
  Box,
  Button,
  Group,
  Modal,
  Switch,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { tagsPatchMethod } from "../../utilities/fetchMethod";
import { EditTags } from "../../utilities/models";

interface Props {
  editTags: EditTags;
  onClose: () => void;
  onRefresh: () => void;
}
export default function EditTagsModalBox(props: Props) {
  const [error, setError] = useState('')

  let submitValue: any = undefined || {};
  let AObj = props.editTags.AObj;
  let itemHead = Object.keys(AObj).filter(
    (item) =>
      item !== "id" &&
      item !== "created_at" &&
      item !== "updated_at" &&
      item !== "delete_time"
  );
  async function submitEdit(value: object) {
    let valueheadArray = Object.keys(value);

    if (valueheadArray.length > 0) {
      let submitValue = { table: props.editTags.head, id: AObj.id };
      submitValue = {
        ...submitValue,
        ...value,
      };
      const _tagsPatchMethod = await tagsPatchMethod(submitValue);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/tags/editTag`,
        _tagsPatchMethod
      );
      const result = await res.json();
      if (res.status === 200) {
        props.onRefresh();
        props.onClose();
      } else if (result.error){
        setError(result.error)
      } else {
        setError('Please try again')
      }
    }

  }

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.1}
        overlayBlur={3}
        overflow="inside"
        title={`Edit table name : ${props.editTags.head}, tags : ${AObj.id}`}
        key={`${AObj.id}`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {itemHead.map((items) => {
            if (items !== "active") {
              return (
                <TextInput
                  withAsterisk
                  defaultValue={AObj[items]}
                  label={items}
                  key={`${items}`}
                  onChange={(e) => {
                    submitValue[items] = e.target.value;
                  }}
                />
              );
            } else {
              return (
                <Switch
                  key={`${items}`}
                  style={{ margin: 15 }}
                  defaultChecked={AObj[items]}
                  label={items}
                  onLabel="Active"
                  offLabel="Deactive"
                  onChange={(e) => {
                    submitValue[items] = e.target.checked;
                  }}
                />
              );
            }
          })}
          <Group position="right" mt="md">
            <Button
              type="submit"
              onClick={() => {
                submitEdit(submitValue);
              }}
            >
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
