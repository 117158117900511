import { Box, Button, Group, Modal } from "@mantine/core";
import React, { useState } from "react";
import { deleteMethodWithBody } from "../../utilities/fetchMethod";
import { useRecoilState } from "recoil";
import { corpListState } from "../../recoil_state";

interface Props {
  itemArray: number[];
  onClose: () => void;
  onRefresh: () => void;
  link: string;
  name?: string;
}

export default function DeleteModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [corpList, setCorpList] = useRecoilState(corpListState);
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false)

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit users
  async function deleteItem() {
    setDisable(true)
    setError("");

    const _deleteMethod = await deleteMethodWithBody(props.itemArray);
    const res = await fetch(props.link, _deleteMethod);
    const result = await res.json();
    if (res.status === 200) {
      if(result.token && result.corpList){
        localStorage.setItem("token", result.token);
        setCorpList(result.corpList);
      }
      props.onRefresh();
      props.onClose();
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("Please try again");
      setDisable(false)
    }
  }

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Delete`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <div style={{ color: "red", fontSize: 15 }}>{error}</div>
          {props.name ? (
            <>
              <div>{`Are you sure you want to delete  : ${props.name} ?`}</div>
              <div>This action cannot be returned !</div>
            </>
          ) : (
            <>
              <div>{`Are you sure you want to delete ids : ${props.itemArray},`}</div>
              <div>{`total ${props.itemArray.length} items ? `}</div>
              <div>This action cannot be returned !</div>
            </>
          )}
          <Group position="right" mt="md">
            <Button disabled={disable} color="red" type="submit" onClick={() => deleteItem()}>
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
