import { Alert, MultiSelect, Tabs } from "@mantine/core";
import produce from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { editingProfileState } from "../../recoil_state";
import { getMethod, postMethod } from "../../utilities/fetchMethod";
import {
  OwnerProfile,
  ProfileSection,
  ProfilesInfo,
  profileType,
} from "../../utilities/models";
import EditButton from "../Buttons/EditButton";
import RepresentativeCard from "./RepresentativeCard";

interface Props {
  fetchUrl: string;
  headName: string;
  getProfileState:string;
}

var lodash = require("lodash");

export default function RepresentativeSection(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [editingEvent, setEditingEvent] = useRecoilState(editingProfileState);
  const [profileList, setProfileList] = useState<OwnerProfile[]>();
  const [selectorProfileList, setSelectorProfileList] =
    useState<{ value: string; label: string }[]>();
  const [selected, setSelected] = useState<string[]>(
    editingEvent.profile_representative.map((item) => String(item.id))
  );
  const [error, setError] = useState<{ color: string; message: string }>({
    color: "red",
    message: "",
  });

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  async function save() {
    setError({ color: "red", message: "" });
    if (selected.length == 0) {
      setError({
        color: "red",
        message: "Please at least select one Representative",
      });
      return;
    }

    //parsing string [] into number[]
    let idArray = [];
    for (const id of selected) {
      idArray.push(parseInt(id));
    }

    const _postMethod = await postMethod(idArray);
    const res = await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/profiles/representatives/${encodeURI(String(editingEvent.profile.id))}`,
      _postMethod
    );
    const result = await res.json();

    if (result.message == "success") {
      const _getMethod = await getMethod();
      // get the profile result
      const res = await fetch(props.fetchUrl, _getMethod);
      const profileResult = await res.json();

      if (res.ok) {
        setEditingEvent(profileResult);
        window.history.replaceState(
          { key: window.history.state.key, usr: editingEvent },
          ""
        );
        setError({ color: "green", message: "success!" });
      } else {
        setError({ color: "red", message: profileResult.message });
      }
    } else {
      setError({ color: "red", message: result.message });
    }
  }

  const fetchAllProfiles = useCallback(async () => {
    const _getMethod = await getMethod();

    console.log(props.getProfileState)


    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/forselect/${encodeURI(
        String(props.getProfileState)
      )}`,
      _getMethod
    );
    const result = await res.json();
    if (res.ok) {
      setProfileList(result);
      buildProfileSelector(result);
    } else {
      console.log("something wrong happened");
    }
  }, []);

  const buildProfileSelector = useCallback((profiles: OwnerProfile[]) => {
    let list = [];
    for (const profile of profiles) {
      if (
        profile.active === true &&
        profile.profile_type !== profileType.corporate_main &&
        profile.username
      ) {
        list.push({
          value: String(profile.id),
          label: profile.username,
        });
      }
    }
    setSelectorProfileList(list);
  }, []);

  function setValueAndSent(list: string[]) {
    let representatives: ProfileSection[] = [];
    for (const profile of list) {
      const selected = profileList!.find(
        (item) => item.id === parseInt(profile)
      );
      if (selected) {
        //corporate can be null
        representatives.push(selected as any);
      }
    }
    setEditingEvent(
      produce((draft) => {
        draft.profile_representative = representatives;
      })
    );
  }

  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchAllProfiles();
  }, [fetchAllProfiles, buildProfileSelector]);

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <Tabs.Panel value={props.headName} pt="xs" style={{ padding: 10 }}>
      <EditButton
        name={"save"}
        onEdit={() => save()}
        style={{ width: "100%" }}
      />
      {error.message !== "" && (
        <Alert title="!!!!!" color={error.color}>
          {error.message}
        </Alert>
      )}
      {selectorProfileList && (
        <MultiSelect
          style={{ width: "100%" }}
          value={selected}
          onChange={(value) => {
            setSelected(value);
            setValueAndSent(value);
          }}
          data={selectorProfileList}
          label="Select Representatives"
          placeholder="Representatives"
          clearButtonLabel="Clear selection"
          clearable
          searchable
          nothingFound="Nothing found"
        />
      )}
      {selected.length > 0 &&
        editingEvent.profile_representative.length > 0 && (
          <>
            <div style={{ marginTop: 20 }}>{`Representative List`}</div>
            {lodash
              .sortBy(editingEvent.profile_representative, [
                "representative_order",
              ])
              .map((item: any) => (
                <RepresentativeCard
                  key={item.id}
                  username={item.username}
                  profileIcon={item.icon_url}
                  firstName={item.profile_first_name}
                  Lastname={item.profile_last_name}
                  corporate={item.corporate ? item.corporate : null}
                />
              ))}
          </>
        )}
    </Tabs.Panel>
  );
}
