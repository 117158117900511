import React, { useEffect, useState } from "react";
import { getMethod } from "../../utilities/fetchMethod";
import { initialUserinfo, UserInfoProps } from "../../utilities/models";
import { Button, Table } from "@mantine/core";
import { useRecoilState } from "recoil";
import { allUserState, isLoadingAllUserState } from "../../recoil_state";
import EditAdminModalBox from "../../Components/ModalBox/EditAdminModal";
import SearchBar from "../../Bars/SearchBar";
import DisableButton from "../../Components/Buttons/DisableButton";

export default function StaffDashboard() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [userList, setUserList] = useRecoilState(allUserState);
  const [currentList, setCurrentList] = useState([initialUserinfo]);
  const [openedEdit, setOpenedEdit] = useState(false);
  const [editId, setEditId] = useState({ id: 0, super_admin: false });
  const [isLoadingAllUser, setLoadingAllUser] = useRecoilState(
    isLoadingAllUserState
  );
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //fetch all users
  async function fetchAll() {
    setLoadingAllUser("fetching");
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user`,
      _getMethod
    );
    const result = await res.json();
    setUserList(result);
    setCurrentList(result);
    setLoadingAllUser("fetched");
  }

  //search function
  async function search(text: string) {
    setCurrentList(userList.filter((item) => item.phone_number.includes(text)));
  }

  //remove search function
  async function removeSearchText() {
    setCurrentList(userList);
  }

  //refresh
  async function refresh() {
    fetchAll();
  }
  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (isLoadingAllUser !== "notFetch") {
      setCurrentList(userList);
    } else {
      fetchAll();
    }
  }, [userList, isLoadingAllUser]);

  //-------------------------------------------------------------------------------------------
  // setting rows & headers
  //-------------------------------------------------------------------------------------------

  //rows
  function Rows(props: UserInfoProps) {
    const userInfo = props.userInfo;
    const array = Object.entries(userInfo);
    if (!userInfo.active) return <></>;
    return (
      <tr key={userInfo.id}>
        <td>
          {userInfo.active &&
            (userInfo.super_admin ? (
              <Button
                color={"cyan"}
                onClick={() => {
                  setEditId({
                    id: userInfo.id,
                    super_admin: userInfo.super_admin,
                  });
                  setOpenedEdit(true);
                }}
              >
                edit password
              </Button>
            ) : (
              <Button
                color={"green"}
                onClick={() => {
                  setEditId({
                    id: userInfo.id,
                    super_admin: userInfo.super_admin,
                  });
                  setOpenedEdit(true);
                }}
              >
                set password
              </Button>
            ))}
        </td>
        {userInfo.phone_location ? (
          <td key={`${userInfo.id}-phone_location-phone_location`} width={1500}>
            {userInfo.phone_location.location_number}
          </td>
        ) : (
          <td key={`${userInfo.id}-phone_location-phone_location`} width={1500}>
            <div>NA</div>
          </td>
        )}
        {userInfo.phone_number ? (
          <td key={`${userInfo.id}-user-user`} width={1500}>
            {userInfo.phone_number}
          </td>
        ) : (
          <td key={`${userInfo.id}-user-user`} width={1500}>
            <div>NA</div>
          </td>
        )}
        {array.map(([key, value]) => {
          if (key == "phone_location") {
            return <td></td>;
          } else {
            return (
              <td key={`${userInfo.id}-${key}-${value}`}>
                {value === true || value === false ? `${value}` : value}
              </td>
            );
          }
        })}
      </tr>
    );
  }

  //headers
  function Headers(props: UserInfoProps) {
    const array = Object.keys(props.userInfo);
    return (
      <tr>
        <th>settings</th>
        <th>user phone localtion</th>
        <th>user phone number</th>
        {array.map((keys) => {
          if (!(keys == "phone_location" || keys == "phone_number")) {
            return <th key={keys}>{keys}</th>;
          }
        })}
      </tr>
    );
  }

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <div style={{ fontSize: 25, fontWeight: "800" }}>StaffDashboard</div>
        <SearchBar
          onSearch={(text) => search(text)}
          onRemove={() => removeSearchText()}
        />
      </div>
      {openedEdit && (
        <EditAdminModalBox
          info={editId}
          onClose={() => setOpenedEdit(false)}
          onRefresh={() => refresh()}
        />
      )}
      <div style={{ overflow: "auto" }}>
        {currentList.length > 0 && (
          <Table>
            <thead>
              <Headers userInfo={currentList[0]} />
            </thead>
            <tbody>
              {currentList
                .filter((item) => item.active == true)
                .map((user) => (
                  <Rows key={user.id} userInfo={user} />
                ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
}
