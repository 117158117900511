import { atom } from "recoil";
import {
  initialCorpinfo,
  initialInterestsSubTags,
  initialInterestsTags,
  initialJobSubTags,
  initialJobTags,
  initialNewGallery,
  initialProfileinfo,
  initialUserinfo,
} from "./utilities/models";

export const userState = atom({
  key: "userState",
  default: {
    id: 0,
    phone_number: "12345678",
    email: "admin@admin.com",
    super_admin: false,
  },
});

export const isSuperAdminState = atom({
  key: "isSuperadminState",
  default: false,
});

export const allUserState = atom({
  key: "allUserState",
  default: [initialUserinfo],
});

export const isLoadingAllUserState = atom({
  key: "isLoadingAllUserState",
  default: "notFetch",
});

export const allCorpState = atom({
  key: "allCorpState",
  default: [initialCorpinfo],
});

export const isLoadingAllCorpState = atom({
  key: "isLoadingAllCorpState",
  default: "notFetch",
});

export const eventState = atom({
  key: "eventState",
  default: {
    id: 0,
    phone_number: "12345678",
    email: "admin@admin.com",
    super_admin: false,
  },
});

export const corpListState = atom({
  key: "corpListState",
  default: [
    {
      corporate_name: "loop",
      top_limit:0,
      active: true,
      created_at: "",
      deleted_time: "",
      id: -1,
      updated_at: "",
    },
  ],
});

export const phoneLocationsState = atom({
  key: "phoneLocationsState",
  default: [
    {
      active: true,
      created_at: "",
      delete_time: null,
      id: 0,
      location: "",
      location_number: "",
      updated_at: "",
    },
  ],
});

export const phoneLocationsDataState = atom({
  key: "phoneLocationsDataState",
  default: [{ value: "", label: "" }],
});

export const profileListDataState = atom({
  key: "profileListDataState",
  default: [{ value: "", label: "" }],
});

export const selectUserListState = atom({
  key: "selectUserListState",
  default: [{ value: "", label: "" }],
});

export const corporatesListDataState = atom({
  key: "corporatesListDataState",
  default: [{ value: "0", label: "" }],
});

export const editingProfileState = atom({
  key: "editingProfileState",
  default: initialProfileinfo,
});

export const newGalleryState = atom({
  key: "newGalleryState",
  default: initialNewGallery,
});

export const jobTagsState = atom({
  key: "jobTagsState",
  default: [initialJobTags],
});

export const jobSubTagsState = atom({
  key: "jobSubTagsState",
  default: [initialJobSubTags],
});

export const jobTagsListDataState = atom({
  key: "JobTagsListData",
  default: [{ value: "0", label: "" }],
});

export const jobSubTagsListDataState = atom({
  key: "JobSubTagsListData",
  default: [{ value: "0", label: "", group: "" }],
});

export const interestTagsState = atom({
  key: "interestTagsState",
  default: [initialInterestsTags],
});

export const interestSubTagsState = atom({
  key: "interestSubTagsState",
  default: [initialInterestsSubTags],
});

export const interestTagsListDataState = atom({
  key: "interestTagsListDataState",
  default: [{ value: "0", label: "" }],
});

export const interestSubTagsListDataState = atom({
  key: "interestSubTagsListData",
  default: [{ value: "0", label: "", group: "" }],
});

export const sideBarSearchText = atom({
  key: "sideBarSearchText",
  default: "",
});