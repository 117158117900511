import React, { useState } from "react";
import Avatar from "../../images/44-avatar-user-in-circle.svg";
import { pushProfile } from "../../Pages/analysisPages/AnalysisHomePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mantine/core";
interface Props {
  image?: string | null;
  title: string;
  connectProfiles: pushProfile[];
}

export default function ConnectboardCard(props: Props) {
  const { image, title, connectProfiles } = props;
  const [openConnecttionProfiles, setOpenConnecttionProfiles] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {image !== undefined && (
            <img
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                marginRight: 10,
              }}
              src={image == null ? Avatar : image}
            />
          )}
          <div>
            <p style={{ marginBottom: 0, fontWeight: "700" }}>{title}</p>
            <p style={{ marginTop: 0, color: "gray" }}>
              {" have "}
              {connectProfiles.length}
              {" connections"}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color={"grape"}
            onClick={() => setOpenConnecttionProfiles(!openConnecttionProfiles)}
            disabled={connectProfiles.length > 0 ? false : true}
          >
            {openConnecttionProfiles ? (
              <FontAwesomeIcon icon={faMinus} size={"xl"} />
            ) : (
              <FontAwesomeIcon icon={faPlus} size={"xl"} />
            )}
          </Button>
        </div>
      </div>
      {openConnecttionProfiles &&
        connectProfiles.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: 25,
              paddingTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderColor: "gray",
                border: "1px solid",
                borderRadius: 10,
                width: "100%",
                padding: 20,
              }}
            >
              <img
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  marginRight: 10,
                }}
                src={item.icon_url ? item.icon_url : Avatar}
              />
              <div>
                <p style={{ margin: 0, fontWeight: "700" }}>
                  {item.profile_first_name + " " + item.profile_last_name}
                </p>
                <p style={{ margin: 0, color: "gray" }}>
                  {" Company name : "}
                  {item.company_name}
                </p>
                <p style={{ margin: 0, color: "gray" }}>
                  {" Title : "}
                  {item.profile_title}
                </p>
                <p style={{ margin: 0, color: "gray" }}>
                  {" Email: "}
                  {item.email}
                </p>
                <p style={{ margin: 0, color: "gray" }}>
                  {" Phone : "}
                  {item.phone_number}
                </p>
                <p style={{ margin: 0, color: "gray" }}>
                  {" username : "}
                  {item.username}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}></div>
          </div>
        ))}
    </>
  );
}
