import {
  Box,
  Button,
  Group,
  Modal,
  FileButton,
  Select,
  NumberInput,
  LoadingOverlay,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { postMethod } from "../../utilities/fetchMethod";
import { eventStatusType, ProfilesInfo } from "../../utilities/models";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  eventList: ProfilesInfo[];
}

export default function SendEmailToProfile(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [doubleConfirm, setDoubleConfirm] = useState(false);
  const [templates, setTemplates] = useState<number | undefined>();
  const [selectEventList, setSelectEventList] =
    useState<{ value: string; label: string }[]>();
  const [selectedEvent, setSelectedEvent] = useState<ProfilesInfo | null>(null);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState({ color: "", message: "" });

  //-------------------------------------------------------------------------------------------
  // calling apis
  //-------------------------------------------------------------------------------------------

  async function sendEmail() {
    setLoading(true);
    setDisabled(false);
    setError({ color: "", message: "" });

    if (!selectedEvent || !templates) return;

    const body = {
      eventId: selectedEvent.profile.eventSection.id,
      templates: templates,
    };

    const _postMethod = await postMethod(body);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/events/sendEmailByTemplates`,
      _postMethod
    );
    const result = await res.json();

    if (res.ok) {
      if (result.length > 0) {
        const profileList = result.map((item: any) => item.username);
        setError({
          color: "red",
          message: `Email sent! but the following profiles cannot be reach : ${profileList}`,
        });
      } else {
        setError({ color: "green", message: `Email sent!` });
        setLoading(false);

        props.onClose();
        return;
      }
    } else {
      setError({ color: "red", message: result.message });
    }
    setLoading(false);
  }

  //-------------------------------------------------------------------------------------------
  // building list
  //-------------------------------------------------------------------------------------------
  const buildList = useCallback(() => {
    if (props.eventList) {
      let list: { value: string; label: string }[] = [];
      for (const event of props.eventList) {
        if (
          event.profile.active &&
          (event.profile.eventSection.status === eventStatusType.approved ||
            event.profile.eventSection.status === eventStatusType.inProgress)
        ) {
          const item = {
            value: event.profile.id.toString(),
            label: event.profile.username,
          };
          list.push(item);
        }
      }
      setSelectEventList(list);
    }
  }, []);

  useEffect(() => {
    buildList();
  }, [buildList]);

  useEffect(() => {
    if (selectedEvent && templates) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedEvent, templates]);

  //-------------------------------------------------------------------------------------------
  // double Confirm Modal
  //-------------------------------------------------------------------------------------------

  interface DoubleConfirmProps {
    onClose: () => void;
    confirm: () => void;
    templates: number;
    msg?: string;
    event?: string;
    eventId?: number;
  }

  function DoubleConfirmModal(props: DoubleConfirmProps) {
    return (
      <>
        <Modal
          opened={true}
          onClose={props.onClose}
          overlayColor={"grey"}
          overlayOpacity={0.55}
          overlayBlur={3}
          overflow="inside"
          title={`Double Confirm !!!`}
        >
          <Box sx={{ maxWidth: 400 }} mx="auto">
            {props.event && (
              <div
                style={{
                  margin: 10,
                  color: "black",
                  fontSize: 20,
                  fontWeight: 200,
                }}
              >
                You are now going to send email to profiles who join{" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  {props.event}
                </span>{" "}
                using templates{" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  {props.templates}
                </span>
              </div>
            )}
            <Group style={{ width: "100%", justifyContent: "center" }}>
              <Button
                color="red"
                onClick={() => {
                  props.confirm();
                  props.onClose();
                }}
                style={{ marginLeft: 10 }}
              >
                YES
              </Button>

              <Button
                color="green"
                onClick={props.onClose}
                style={{ marginLeft: 10 }}
              >
                NO
              </Button>
            </Group>
          </Box>
        </Modal>
      </>
    );
  }

  return (
    <>
      <LoadingOverlay
        visible={loading}
        overlayBlur={2}
        style={{ position: "fixed" }}
      />
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Send email to joined event profile`}
      >
        <Box sx={{ maxWidth: 400 }} mx="auto">
          {doubleConfirm && templates && (
            <DoubleConfirmModal
              onClose={() => setDoubleConfirm(false)}
              confirm={() => {
                sendEmail();
              }}
              templates={templates}
              event={
                selectedEvent
                  ? selectedEvent.profile.eventSection.event_name
                  : ""
              }
            />
          )}
          {selectEventList && (
            <Select
              data={selectEventList}
              label="event"
              placeholder="select event"
              withAsterisk
              radius="xs"
              value={selectedEvent ? String(selectedEvent?.profile.id) : null}
              onChange={(item) => {
                if (!item) {
                  setSelectedEvent(null);
                } else {
                  const selected = props.eventList!.find(
                    (event) => event.profile.id == parseInt(item)
                  );
                  if (selected) {
                    setSelectedEvent(selected);
                  } else {
                    setSelectedEvent(null);
                  }
                }
              }}
              searchable
            />
          )}
          <NumberInput
            style={{ marginTop: 20 }}
            placeholder={"Template ID"}
            label={"Please input the template you are going to use"}
            withAsterisk
            value={templates}
            min={0}
            onChange={(value) => setTemplates(value)}
          />
          {error.message && (
            <div style={{ color: error.color, marginTop: 20 }}>
              Error: {error.message} <br /> Please try again.
            </div>
          )}
          <Group
            style={{ width: "100%", justifyContent: "flex-end", marginTop: 20 }}
          >
            <Button
              disabled={disabled}
              color="green"
              onClick={() => {
                setDoubleConfirm(true);
              }}
              style={{ marginLeft: 10 }}
            >
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
