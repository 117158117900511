import { Box, Button, Group, Modal, Select, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  getMethod,
  patchMethod,
  patchMethodwithFormData,
  postMethod,
} from "../../utilities/fetchMethod";
import { ProfilesInfo, ProfilesInfoWithoutPopulated, profileType } from "../../utilities/models";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  selectCorporateList: { value: string; label: string }[];
  editProfile: ProfilesInfoWithoutPopulated;
}

export default function SetAndDownGradeCorpMainModal(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [corporateId, setCorporateId] = useState<string | null>(null);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //update profile status
  async function createItem() {
    setError("");

    const formData = new FormData();

    const parseIntID = corporateId
      ? parseInt(corporateId) > 0
        ? parseInt(corporateId)
        : null
      : null;

    let body;
    if (parseIntID) {
      body = {
        profile_type: profileType.corporate_main,
        admin: false,
        corporate: {id: parseIntID},
        user: null,
      };
    } else {
      body = {
        profile_type: profileType.individual,
        corporate: null,
        user: null,
      };
    }
    formData.append("profileInfo", JSON.stringify(body));
    const _patchMethod = await patchMethodwithFormData(formData);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/${encodeURI(
        String(props.editProfile.profile.id)
      )}`,
      _patchMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      props.onRefresh();
      props.onClose();
    } else {
      setError("Please try again");
    }
  }

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Create Profile`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <Select
            data={props.selectCorporateList}
            label="Please select corporate"
            placeholder="select Corporate"
            radius="xs"
            value={corporateId}
            onChange={setCorporateId}
            searchable
          />
          <div style={{ color: "red", fontSize: 15 }}> {error} </div>
          <Group position="right" mt="md">
            <Button onClick={() => createItem()} type="submit">
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
