import { Box, Button, Group, Modal, FileButton, Select } from "@mantine/core";
import produce from "immer";
import Papa from "papaparse";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  corporatesListDataState,
  phoneLocationsState,
} from "../../recoil_state";
import { patchMethod, postMethod } from "../../utilities/fetchMethod";
import {
  eventStatusType,
  Phone_locations,
  ProfilesInfo,
  profileType,
} from "../../utilities/models";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  eventList?: ProfilesInfo[];
  corpId?: number;
  eventId?: number;
  device?: boolean;
  superAdminCreateUser?: boolean;
}

interface Data {
  index: string;
  PhoneLocation: string;
  PhoneNumber: string;
  Email: string;
  Salute: string;
  FirstName: string;
  LastName: string;
  Title: string;
  Location: string;
  CompanyName: string;
  AboutMe: string;
  ContactMessage: string;
}

interface CreateUseForm {
  phone_location: string | Phone_locations;
  phone_number: string;
  email: string;
}

export interface CreateProfileDto {
  profile_salute?: string;
  profile_first_name?: string;
  profile_last_name?: string;
  profile_email?: string;
  company_name?: string;
  profile_location?: string;
  profile_title?: string;
  phone_number?: string;
  phone_location?: Phone_locations;

  user_id?: number;

  about_me?: string;

  public?: boolean;
  profile_type?: string;
  contact_message?: string;
  username?: string;
}

interface InvitationData {
  email: string;
}

export default function InputCSV(props: Props) {
  console.log(props);

  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const resetRef = useRef<() => void>(null);
  const [errorItem, setErrorItem] = useState<
    { item: string; errorMsg: string }[]
  >([]);
  const [errorMsg, setErrorMsg] = useState("");
  const phoneLocations = useRecoilValue(phoneLocationsState);
  const [selectEventList, setSelectEventList] =
    useState<{ value: string; label: string }[]>();
  const [doubleConfirm, setDoubleConfirm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ProfilesInfo | null>(null);
  const corporatesListData = useRecoilValue(corporatesListDataState);
  const [corpName, setCorpName] = useState("");
  const [countItem, setCountItem] = useState(0);
  const checkList = [
    "PhoneLocation",
    "PhoneNumber",
    "Email",
    "Salute",
    "FirstName",
    "LastName",
    "Title",
    "Location",
    "CompanyName",
    "AboutMe",
    "ContactMessage",
  ];

  const unexpectedHeaderError =
    "Error Unexpected Header ,expected Header:  " +
    "[ " +
    "PhoneLocation ," +
    "PhoneNumber ," +
    "Email ," +
    "Salute ," +
    "FirstName ," +
    "LastName ," +
    "Title ," +
    "Location ," +
    "CompanyName ," +
    "AboutMe ," +
    "ContactMessage " +
    " ]";

  //-------------------------------------------------------------------------------------------
  // csv related
  //-------------------------------------------------------------------------------------------
  const clearFile = () => {
    setErrorItem([]);
    setFile(null);
    resetRef.current?.();
  };

  const addErrorItem = (Erroritem: { item: string; errorMsg: string }) => {
    setErrorItem(
      produce((item) => {
        item.push(Erroritem);
      })
    );
  };

  const addCountItem = () => {
    setCountItem(
      produce((count) => {
        return count + 1;
      })
    );
  };

  const csvToObject = async (file: File) => {
    setErrorItem([]);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      // dynamicTyping:true,
      step: props.eventList
        ? stepForEvent
        : props.corpId
        ? stepForCoprStaff
        : props.device
        ? stepForUploadingDevice
        : props.superAdminCreateUser
        ? stepForSuperAmainCreateUser
        : stepForInvitation,
    });
  };

  //-------------------------------------------------------------------------------------------
  // building list
  //-------------------------------------------------------------------------------------------

  const buildList = useCallback(() => {
    if (props.eventList) {
      let list: { value: string; label: string }[] = [];
      for (const event of props.eventList) {
        if (
          event.profile.active &&
          (event.profile.eventSection.status === eventStatusType.approved ||
            event.profile.eventSection.status === eventStatusType.inProgress)
        ) {
          const item = {
            value: event.profile.id.toString(),
            label: event.profile.username,
          };
          list.push(item);
        }
      }
      setSelectEventList(list);
    }
  }, []);

  //-------------------------------------------------------------------------------------------
  // alling apis
  //-------------------------------------------------------------------------------------------

  //create users
  const createProfileAndJoin = async (createProfileForm: CreateProfileDto) => {
    const _postMethod = await postMethod(createProfileForm);

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/csv/event/${
        selectedEvent!.profile.eventSection.id
      }`,
      _postMethod
    );

    return res;
  };

  const createProfile = async (
    createProfileForm: CreateProfileDto,
    corpCreate: boolean
  ) => {
    const body = createProfileForm;
    const _postMethod = await postMethod(body);
    const url = corpCreate
      ? `profile?corporate=${encodeURI(String(props.corpId))}`
      : `csv/superAdminCreateUser`;
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/${url}`,
      _postMethod
    );

    // console.log("result", result); //{statusCode: 500, message: 'CSV Input Error', error: 'Internal Server Error'}

    return res;
  };

  //-------------------------------------------------------------------------------------------
  // this is the step will run on each csv row For Event
  //-------------------------------------------------------------------------------------------

  async function stepForEvent(results: any, parser: any) {
    parser.pause(); // pause the parser
    const inputData = results.data as Data;

    const fields: string[] = results.meta.fields;

    for (let i = 0; i < checkList.length; i++) {
      if (!(checkList[i] === fields[i])) {
        setErrorMsg(unexpectedHeaderError);
        parser.abort();
        return;
      }
    }

    const phone_location = phoneLocations.find(
      (item) =>
        item.location_number == (("+" + inputData.PhoneLocation) as string)
    );

    if (phone_location && inputData.PhoneNumber && inputData.Email) {
      const createProfileForm: CreateProfileDto = {
        profile_salute: inputData.Salute,
        profile_first_name: inputData.FirstName,
        profile_last_name: inputData.LastName,
        profile_email: inputData.Email,
        company_name: inputData.CompanyName,
        profile_location: inputData.Location,
        profile_title: inputData.Title,
        phone_number: String(inputData.PhoneNumber),
        phone_location: phone_location,
        about_me: inputData.AboutMe,
        public: true,
        profile_type: profileType.individual,
        contact_message: inputData.ContactMessage,
      };

      const res = await createProfileAndJoin(createProfileForm);
      const result = await res.json();
      if (res.ok) {
        parser.resume(); // resume the parser
      } else {
        addErrorItem({ item: inputData.PhoneNumber, errorMsg: result.message });

        parser.resume(); // resume the parser
      }
    } else {
      addErrorItem({ item: inputData.PhoneNumber, errorMsg: "Input Error" });

      parser.resume(); // resume the parser
    }
  }

  //-------------------------------------------------------------------------------------------
  // this is the step will run on each csv row For device
  //-------------------------------------------------------------------------------------------

  async function stepForUploadingDevice(results: any, parser: any) {
    parser.pause(); // pause the parser
    const inputData = results.data as { link: string };

    const fields: string[] = results.meta.fields;

    if (fields.length != 1) {
      setErrorMsg(unexpectedHeaderError);
      parser.abort();
      return;
    }

    if (inputData) {
      const uuid = new URL(inputData.link).pathname.replace("/i/", "");

      const _postMethod = await postMethod({
        uuid: uuid,
      });

      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/device/uuid/hardcode`,
        _postMethod
      );

      const result = await res.json();

      if (res.ok) {
        addCountItem();
        parser.resume(); // resume the parser
      } else if (result.message === "User is not the super admin") {
        setErrorMsg(result.message);
        parser.abort();
      } else {
        addErrorItem({ item: inputData.link, errorMsg: result.message });
      }
      parser.resume(); // resume the parser
    } else {
      addErrorItem({ item: String(results.data), errorMsg: "Input Error" });

      parser.resume(); // resume the parser
    }
  }

  //-------------------------------------------------------------------------------------------
  // this is the step will run on each csv row For Event
  //-------------------------------------------------------------------------------------------
  async function stepForCoprStaff(results: any, parser: any) {
    parser.pause(); // pause the parser

    const fields: string[] = results.meta.fields;

    for (let i = 0; i < checkList.length; i++) {
      if (!(checkList[i] === fields[i])) {
        setErrorMsg(unexpectedHeaderError);
        parser.abort();
        return;
      }
    }

    const inputData = results.data as Data;

    const phone_location = phoneLocations.find(
      (item) => item.location_number == "+" + inputData.PhoneLocation
    );

    if (phone_location && inputData.PhoneNumber && inputData.Email) {
      const createProfileForm: CreateProfileDto = {
        username: "",
        profile_salute: inputData.Salute,
        profile_first_name: inputData.FirstName,
        profile_last_name: inputData.LastName,
        profile_email: inputData.Email,
        company_name: inputData.CompanyName,
        profile_location: inputData.Location,
        profile_title: inputData.Title,
        phone_number: String(inputData.PhoneNumber),
        phone_location: phone_location,
        about_me: inputData.AboutMe,
        profile_type: profileType.corporate,
        contact_message: inputData.ContactMessage,
      };
      const res = await createProfile(createProfileForm, true);
      const result = await res.json();
      if (res.ok) {
        addCountItem();
        parser.resume(); // resume the parser
      } else if (result.message === "corp profile on top limit") {
        setErrorMsg(
          "Corp profile on limit can not create more ,Please connact LOOP."
        );

        parser.abort();
      } else {
        addCountItem();
        addErrorItem({ item: inputData.PhoneNumber, errorMsg: result.message });

        parser.resume(); // resume the parser
      }
    } else {
      addCountItem();
      addErrorItem({ item: inputData.PhoneNumber, errorMsg: "Input Error" });

      parser.resume(); // resume the parser
    }
  }
  //-------------------------------------------------------------------------------------------
  // this is the step will run on each csv row For Event
  //-------------------------------------------------------------------------------------------
  async function stepForSuperAmainCreateUser(results: any, parser: any) {
    parser.pause(); // pause the parser

    const fields: string[] = results.meta.fields;

    for (let i = 0; i < checkList.length; i++) {
      if (!(checkList[i] === fields[i])) {
        setErrorMsg(unexpectedHeaderError);
        parser.abort();
        return;
      }
    }

    const inputData = results.data as Data;

    const phone_location = phoneLocations.find(
      (item) => item.location_number == "+" + inputData.PhoneLocation
    );

    if (phone_location && inputData.PhoneNumber && inputData.Email) {
      const createProfileForm: CreateProfileDto = {
        username: "",
        profile_salute: inputData.Salute,
        profile_first_name: inputData.FirstName,
        profile_last_name: inputData.LastName,
        profile_email: inputData.Email,
        company_name: inputData.CompanyName,
        profile_location: inputData.Location,
        profile_title: inputData.Title,
        phone_number: String(inputData.PhoneNumber),
        phone_location: phone_location,
        about_me: inputData.AboutMe,
        profile_type: profileType.corporate,
        contact_message: inputData.ContactMessage,
      };
      const res = await createProfile(createProfileForm, false);
      const result = await res.json();
      if (res.ok) {
        addCountItem();
        parser.resume(); // resume the parser
      } else {
        addCountItem();
        addErrorItem({ item: inputData.PhoneNumber, errorMsg: result.message });

        parser.resume(); // resume the parser
      }
    } else {
      addCountItem();
      addErrorItem({ item: inputData.PhoneNumber, errorMsg: "Input Error" });

      parser.resume(); // resume the parser
    }
  }
  //-------------------------------------------------------------------------------------------
  // this is the step will run on each csv row For Invitation
  //-------------------------------------------------------------------------------------------

  async function stepForInvitation(results: any, parser: any) {
    parser.pause(); // pause the parser
    const inputData = results.data as InvitationData;

    const fields: string[] = results.meta.fields;

    if (!("email" === fields[0] && fields.length === 1)) {
      setErrorMsg(unexpectedHeaderError);
      parser.abort();
      return;
    }

    if (inputData.email) {
      const _postMethod = await postMethod({ email: inputData.email });

      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/admin/csv/eventInvitation/${props.eventId}`,
        _postMethod
      );

      if (res.ok) {
        addCountItem();
        parser.resume(); // resume the parser
      } else {
        addErrorItem({ item: inputData.email, errorMsg: "error email" });

        parser.resume(); // resume the parser
      }
    } else {
      addErrorItem({ item: "inputData", errorMsg: "error inputData" });

      parser.resume(); // resume the parser
    }
  }

  useEffect(() => {
    buildList();
  }, [buildList]);

  useEffect(() => {
    if (props.corpId) {
      const corp = corporatesListData.find(
        (item) => +item.value == props.corpId
      );
      if (corp) {
        setCorpName(corp.label);
      }
    }
  }, []);

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  //-------------------------------------------------------------------------------------------
  // double Confirm Modal
  //-------------------------------------------------------------------------------------------

  interface DoubleConfirmProps {
    onClose: () => void;
    confirm: () => void;
    superAdminCreateUser: boolean;
    fileName: string;
    event?: string;
    corpName?: string;
    eventId?: number;
  }

  function DoubleConfirmModal(props: DoubleConfirmProps) {
    return (
      <>
        <Modal
          opened={true}
          onClose={props.onClose}
          overlayColor={"grey"}
          overlayOpacity={0.55}
          overlayBlur={3}
          overflow="inside"
          title={`Double Confirm !!!`}
        >
          <Box sx={{ maxWidth: 400 }} mx="auto">
            {props.event && (
              <div
                style={{
                  margin: 10,
                  color: "black",
                  fontSize: 20,
                  fontWeight: 200,
                }}
              >
                You are input {props.fileName} to create user and profile for
                event : {props.event}
              </div>
            )}
            {props.corpName && (
              <div
                style={{
                  margin: 10,
                  color: "black",
                  fontSize: 20,
                  fontWeight: 200,
                }}
              >
                You are input {props.fileName} to create staff profile for
                corporate : {props.corpName}
              </div>
            )}
            {!props.corpName && !props.event && !props.superAdminCreateUser && (
              <div
                style={{
                  margin: 10,
                  color: "black",
                  fontSize: 20,
                  fontWeight: 200,
                }}
              >
                You are going to input {props.fileName} for sending invitation
                on event: {props.eventId}
              </div>
            )}
            {props.superAdminCreateUser && (
              <div
                style={{
                  margin: 10,
                  color: "black",
                  fontSize: 20,
                  fontWeight: 200,
                }}
              >
                You are input {props.fileName} to create profile !
              </div>
            )}
            <Group style={{ width: "100%", justifyContent: "center" }}>
              <Button
                disabled={!file}
                color="red"
                onClick={() => {
                  props.confirm();
                  props.onClose();
                }}
                style={{ marginLeft: 10 }}
              >
                YES
              </Button>

              <Button
                disabled={!file}
                color="green"
                onClick={props.onClose}
                style={{ marginLeft: 10 }}
              >
                NO
              </Button>
            </Group>
          </Box>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`input CSV`}
      >
        <Box sx={{ maxWidth: 400 }} mx="auto">
          {doubleConfirm && file != null && (
            <DoubleConfirmModal
              onClose={() => setDoubleConfirm(false)}
              confirm={() => {
                csvToObject(file);
                if (errorItem.length > 0) {
                  console.log("errorItem", errorItem);
                }
                // props.onClose();
              }}
              fileName={file.name}
              superAdminCreateUser={props.superAdminCreateUser ?? false}
              event={
                selectedEvent
                  ? selectedEvent.profile.eventSection.event_name
                  : ""
              }
              corpName={props.corpId ? corpName : ""}
              eventId={props.eventId ? props.eventId : undefined}
            />
          )}

          <div style={{ color: "red", fontSize: 15 }}> {error} </div>
          <Group style={{ width: "100%", justifyContent: "center" }}>
            <FileButton
              resetRef={resetRef}
              onChange={(item) => {
                if (item?.type === "text/csv") {
                  setFile(item);
                }
              }}
              accept=".csv"
            >
              {(props) => <Button {...props}>Upload CSV</Button>}
            </FileButton>

            <Button
              disabled={!file}
              color="red"
              onClick={clearFile}
              style={{ marginLeft: 10 }}
            >
              Reset
            </Button>

            <Button
              disabled={
                file &&
                (selectedEvent ||
                  props.corpId ||
                  props.eventId ||
                  props.device ||
                  props.superAdminCreateUser)
                  ? false
                  : true
              }
              color="green"
              onClick={() => {
                props.device ? csvToObject(file!) : setDoubleConfirm(true);
              }}
              style={{ marginLeft: 10 }}
            >
              Submit
            </Button>
          </Group>
          {props.eventList &&
            props.eventList?.length > 0 &&
            selectEventList && (
              <Select
                data={selectEventList}
                label="event"
                placeholder="select event"
                radius="xs"
                value={selectedEvent ? String(selectedEvent?.profile.id) : null}
                onChange={(item) => {
                  if (!item) {
                    setSelectedEvent(null);
                  } else {
                    const selected = props.eventList!.find(
                      (event) => event.profile.id == parseInt(item)
                    );
                    if (selected) {
                      setSelectedEvent(selected);
                    } else {
                      setSelectedEvent(null);
                    }
                  }
                }}
                searchable
              />
            )}

          {file && (
            <div style={{ marginTop: 20 }}>Picked file: {file.name}</div>
          )}
          {countItem > 0 && (
            <div style={{ marginTop: 20 }}>Item count : {countItem}</div>
          )}

          {errorItem.length > 0 && (
            <div style={{ marginTop: 20 }}>
              Error Item : {JSON.stringify(errorItem)}
            </div>
          )}
          {errorMsg && (
            <div style={{ marginTop: 20, color: "red" }}>
              Error : {errorMsg}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}
