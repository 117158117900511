import { Box, Button, Group, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { patchMethod } from "../../utilities/fetchMethod";
import { CorpInfo } from "../../utilities/models";

interface Props {
  corpInfo: CorpInfo;
  onClose: () => void;
  onRefesh: () => void;
}

export default function EditCorpModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [error, setError] = useState("");
  const form = useForm({
    initialValues: props.corpInfo,
    validate: {
      corporate_name: (value) =>
        value.length < 1 ? "Invalid corporate name" : error ? error : null,
    },
    validateInputOnChange: true,
  });

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit users
  async function editItem(form: Object) {
    setError("");
    const array = Object.entries(form);
    const newForm = [];
    for (const item of array) {
      if (
        item[0] === "active" ||
        item[0] === "id" ||
        item[0] === "delete_time" ||
        item[0] === "created_at" ||
        item[0] === "updated_at" ||
        item[0] === "top_limit"
      ) {
        continue;
      } else {
        newForm.push({ [item[0]]: item[1] });
      }
    }

    const _patchMethod = await patchMethod(newForm[0]);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/corporates/${encodeURI(
        String(props.corpInfo.id)
      )}`,
      _patchMethod
    );
    const result = await res.json();
    if (res.status === 200) {
      props.onRefesh();
      props.onClose();
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("Please try again");
    }
  }
  //-------------------------------------------------------------------------------------------
  // useEffect error handling
  //-------------------------------------------------------------------------------------------
  useEffect(() => {
    if (error.length > 1) {
      form.validate();
    }
  }, [error, form]);
  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Edit corporate id : ${props.corpInfo.id}`}
        key={`${props.corpInfo.id}`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <form onSubmit={form.onSubmit((form) => editItem(form))}>
            {Object.entries(props.corpInfo).map(
              ([key, info]) =>
                key === "corporate_name" && (
                  <TextInput
                    withAsterisk
                    label={"Corporate name"}
                    key={`${props.corpInfo.id}--${key}`}
                    {...form.getInputProps(`corporate_name`)}
                  />
                )
            )}

            <Group position="right" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </>
  );
}
