import { Alert, Divider, MultiSelect, NativeSelect, Tabs } from "@mantine/core";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  editingProfileState,
  interestSubTagsListDataState,
  interestSubTagsState,
  interestTagsListDataState,
  interestTagsState,
  jobSubTagsListDataState,
  jobSubTagsState,
  jobTagsListDataState,
  jobTagsState,
} from "../../recoil_state";
import EditButton from "../Buttons/EditButton";
import { getMethod, patchMethod } from "../../utilities/fetchMethod";
import lodash from "lodash";
import { profileType } from "../../utilities/models";

interface ProfileInfoProps {
  headName: string;
}

export default function ProfileTags({ headName }: ProfileInfoProps) {
  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);

  const [jobtagError, setJobtagError] = useState<string>("");

  const [selectedJobTags, setSelectedJobTags] = useState<string | undefined>(
    editingProfile.jobtags[0] ? editingProfile.jobtags[0].jobtag.job_tag : undefined
  );
  const [selectedJobSubTags, setSelectedJobSubTags] = useState<string | undefined>(
    editingProfile.jobtags[0] ? editingProfile.jobtags[0].job_subtag : undefined
  );

  const [filterJobSubTags, setFilteredJobSubTags] =
    useState<{ value: string; label: string; group: string }[]>();

  const jobTags = useRecoilValue(jobTagsState);
  const jobSubTags = useRecoilValue(jobSubTagsState);
  const jobTagsListData = useRecoilValue(jobTagsListDataState);
  const jobSubTagsListData = useRecoilValue(jobSubTagsListDataState);

  const interestTags = useRecoilValue(interestTagsState);
  const interestSubTags = useRecoilValue(interestSubTagsState);

  const interestTagsListData = useRecoilValue(interestTagsListDataState);
  const interestSubTagsListData = useRecoilValue(interestSubTagsListDataState);

  const [interestSelectedTags, setInterestSelectedTags] = useState<string[]>();
  const [interestSelectedSubTags, setInterestSelectedSubTags] =
    useState<string[]>();

  const [profileLoadingState, setProfileLoadingState] = useState(true);
  const [error, setError] = useState<{ color: string; message: string }>({
    color: "red",
    message: "",
  });

  //-------------------------------------------------------------------------------------------
  // set default value
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (!profileLoadingState) return;

    if (editingProfile.jobtags[0]) {
      setSelectedJobTags(String(editingProfile.jobtags[0].jobtag.id));
      setSelectedJobSubTags(String(editingProfile.jobtags[0].id));
    } else {
      setSelectedJobTags("1");
      setSelectedJobSubTags("1");
    }

    if (editingProfile.interestTags && editingProfile.interestTags.length > 0) {
      let tagArrary: string[] = [];
      for (const tag of editingProfile.interestTags) {
        tagArrary.push(String(tag.id));
      }
      const newArray = lodash.uniq(tagArrary);

      setInterestSelectedTags(newArray);
    } else {
      setInterestSelectedTags([]);
    }

    if (
      editingProfile.interestSubTags &&
      editingProfile.interestSubTags.length > 0
    ) {
      let tagArrary: string[] = [];
      for (const tag of editingProfile.interestSubTags) {
        tagArrary.push(String(tag.id));
      }
      setInterestSelectedSubTags(tagArrary);
    } else {
      setInterestSelectedSubTags([]);
    }

    setProfileLoadingState(false);
  }, [profileLoadingState, editingProfile]);

  //-------------------------------------------------------------------------------------------
  // filter tag list
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    // filter jobSub tag list
    if (selectedJobTags) {
      let findTag = jobTags.find((tag) => tag.id === parseInt(selectedJobTags));
      if (findTag) {
        setFilteredJobSubTags(
          jobSubTagsListData.filter((item) => item.group == findTag?.job_tag)
        );
      }
    }
  }, [selectedJobTags, jobTags]);

  //-------------------------------------------------------------------------------------------
  // tags related
  //-------------------------------------------------------------------------------------------

  async function save() {
    setError({
      color: "red",
      message: "",
    });
    if (editingProfile.jobtags.length < 1) {
      //set Error
      setJobtagError("Please Select JobTag");
      return;
    }

    const profileId = editingProfile.profile.id;

    const submitJobTag = { jobSubtags: editingProfile.jobtags[0].id };

    let selectedInterestTagId: number[] = [];
    let selectedInterestSubTagId: number[] = [];
    for (const tag of editingProfile.interestTags) {
      selectedInterestTagId.push(tag.id);
    }
    for (const tag of editingProfile.interestSubTags) {
      selectedInterestSubTagId.push(tag.id);
    }
    const submitInterestTag = {
      interestsTags: {
        selectedInterestTags: selectedInterestTagId,
        selectedInterestSubTags: selectedInterestSubTagId,
      },
    };
    const submitObject = [submitJobTag, submitInterestTag];
    for (const body of submitObject) {
      const _patchMethod = await patchMethod([body]);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profiles/${encodeURI(
          String(profileId)
        )}`,
        _patchMethod
      );
    }

    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/profiles/${encodeURI(
        String(profileId)
      )}`,
      _getMethod
    );
    const result = await res.json();

    if (res.ok) {
      setEditingProfile(result);
      window.history.replaceState(
        { key: window.history.state.key, usr: result },
        ""
      );
      setError({
        color: "green",
        message: "edit success!",
      });
    } else {
      setError({
        color: "red",
        message: "please try agian",
      });
    }
  }

  //-------------------------------------------------------------------------------------------
  // tags related
  //-------------------------------------------------------------------------------------------
  return (
    <Tabs.Panel
      value={headName}
      pt="xs"
      style={{ padding: 10, marginBottom: 25 }}
    >
      <div>
        {/* //============================================================================== */}
        {/* // Job Tags tags            ==================================================== */}
        {/* //============================================================================== */}

        {editingProfile.jobtags &&
          jobTagsListData &&
          filterJobSubTags &&
          selectedJobTags &&
          selectedJobSubTags &&
          jobTags && (
            <>
              <div>
                <Divider style={{ marginTop: 20 }} />

                <NativeSelect
                  style={{ marginTop: 20 }}
                  label={"Job Tags"}
                  value={selectedJobTags}
                  placeholder="Pick a Tags"
                  onChange={(event) => {
                    setSelectedJobTags(event.currentTarget.value);
                    let findTag = jobSubTags.find(
                      (tag) =>
                        tag.jobtag.id === parseInt(event.currentTarget.value)
                    );
                    if (findTag) {
                      setSelectedJobSubTags(findTag.job_subtag);
                      setEditingProfile(
                        produce((state) => {
                          state.jobtags[0] = findTag!;
                        })
                      );
                    }
                  }}
                  data={jobTagsListData}
                />

                <NativeSelect
                  style={{ marginTop: 20 }}
                  label={"JobSub Tags"}
                  value={selectedJobSubTags}
                  placeholder="Pick a SubTags"
                  error={jobtagError ? jobtagError : ""}
                  onChange={(event) => {
                    setSelectedJobSubTags(event.currentTarget.value);
                    let findTag = jobSubTags.find(
                      (tag) => tag.id === parseInt(event.currentTarget.value)
                    );
                    if (findTag) {
                      setEditingProfile(
                        produce((state) => {
                          state.jobtags[0] = findTag!;
                        })
                      );
                    }
                  }}
                  data={filterJobSubTags}
                />
              </div>
            </>
          )}
        {/* //============================================================================== */}
        {/* // Interest Tags tags            =============================================== */}
        {/* //============================================================================== */}

        <>
          {editingProfile.profile.profile_type !==
            profileType.corporate_main && (
            <div>
              <Divider style={{ marginTop: 20 }} />
              {interestSelectedTags && interestTagsListData && (
                <MultiSelect
                  style={{ marginTop: 20 }}
                  label={"interest Tags"}
                  value={interestSelectedTags}
                  onChange={(values) => {
                    setInterestSelectedTags(values);
                    setEditingProfile(
                      produce((draft) => {
                        let newTags: { id: number; interest: string }[] = [];

                        for (const value of values) {
                          for (const interestTag of interestTags) {
                            if (
                              String(interestTag.id) == value &&
                              !newTags.find(
                                (item) => item.id === interestTag.id
                              )
                            )
                              newTags.push(interestTag);
                          }
                        }
                        draft.interestTags = lodash.uniqBy(newTags, "id");
                      })
                    );
                  }}
                  data={interestTagsListData}
                  clearable
                  searchable
                  nothingFound="Nothing found"
                />
              )}

              {interestSelectedSubTags && (
                <MultiSelect
                  style={{ marginTop: 20 }}
                  value={interestSelectedSubTags}
                  onChange={(values) => {
                    setInterestSelectedSubTags(values);
                    setEditingProfile(
                      produce((draft) => {
                        let newTags = [];
                        for (const tag of values) {
                          for (const subtag of interestSubTags) {
                            if (String(subtag.id) == tag) newTags.push(subtag);
                          }
                        }
                        draft.interestSubTags = newTags;
                      })
                    );
                  }}
                  data={interestSubTagsListData}
                  label={"interest Sub Tags"}
                  clearable
                  searchable
                  nothingFound="Nothing found"
                />
              )}
            </div>
          )}
        </>
      </div>
      {error.message != "" && (
        <Alert title="!!!!!" color={error.color}>
          {error.message}
        </Alert>
      )}
      <div
        style={{
          paddingBlock: 3,
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 20,
        }}
      >
        <EditButton
          name={"Save profile Tags"}
          onEdit={save}
          style={{ marginRight: 10 }}
        />
      </div>
    </Tabs.Panel>
  );
}
