import { Box, Button, Group, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { corpListState } from "../../recoil_state";
import { postMethod } from "../../utilities/fetchMethod";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
}

export default function CreateCorpModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [corpList, setCorpList] = useRecoilState(corpListState);
  //form
  const form = useForm({
    initialValues: {
      corporate_name: "",
    },
    validate: {
      corporate_name: (value) =>
        value.length < 1 ? "Invalid corporate name" : error ? error : null,
    },
    validateInputOnChange: true,
    clearInputErrorOnChange: false,
  });

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //create corporates
  async function createItem(form: Object) {
    const _postMethod = await postMethod(form);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/corporates`,
      _postMethod
    );
    const result = await res.json();
    if (result) {
      localStorage.setItem("token", result.token);
      setCorpList(result.corpList);
      props.onRefresh();
      props.onClose();
    } else {
      setError(result.error);
    }
  }

  //-------------------------------------------------------------------------------------------
  // useEffect error handling
  //-------------------------------------------------------------------------------------------
  useEffect(() => {
    if (error.length) {
      form.validate();
    }
  }, [error, form]);
  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Create Corp`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <form onSubmit={form.onSubmit((form) => createItem(form))}>
            <TextInput
              withAsterisk
              label={"corporate name"}
              {...form.getInputProps(`corporate_name`)}
            />
            <Group position="right" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </>
  );
}
