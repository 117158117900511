import { Button } from '@mantine/core'
import React from 'react'

interface Props {
    name?: string
    disable?: boolean
    onEdit: () => void
    style?: {}
   
}

export default function EditButton(props: Props) {
  return (
    <Button onClick={props.onEdit} disabled={props.disable ? props.disable : false} style={props.style ? props.style : {}}>
      {props.name ? props.name : 'Edit'}
    </Button>
  )
}
