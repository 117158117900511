export const getMethod = async () => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return settings;
};

export const deleteMethod = async () => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return settings;
};

export const deleteMethodWithBody = async (body: any) => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  };

  return settings;
};

export const patchMethod = async (body : any) => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  };

  return settings;
};

export const postMethod = async (body : Object | Object[] | null) => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    },
    body: body == null ? null : JSON.stringify(body)
  };

  return settings;
};

export const postMethodWithFormData = async (body : any) => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: body
  };

  return settings;
};

export const tagsDeleteMethod = async (body : Object | Object[]) => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  };

  return settings;
};

export const tagsPatchMethod = async (body : Object | Object[]) => {
  const token = localStorage.getItem("token");
  const settings = {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  };

  return settings;
};

export const patchMethodwithFormData = async (body: FormData) => {
  const token = localStorage.getItem('token');

  const settings = {
    method: 'PATCH',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    body: body,
  };

  return settings;
};