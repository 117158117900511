/** @jsxImportSource @emotion/react */
import { css, ThemeProvider } from "@emotion/react";
import React from "react";
import styles from "../../styles/InputModal.module.css";
import { useForm } from "react-hook-form";
import EditButton from "../Buttons/EditButton";
import DeleteButton from "../Buttons/DeleteButton";

type InputModalProps = {
  title: string;
  onSubmitData: (data: any) => void;
  closeModal?: () => void;
  data?: {
    id: number;
    type: string;
    section_title: string;
    section_info: string;
  };
  onRemove?: () => void;
};

const InputModal: React.FC<InputModalProps> = ({
  title,
  onSubmitData,
  closeModal,
  data,
  onRemove,
}) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => {
    onSubmitData(data);
    if (closeModal) {
      closeModal();
    }
  });

  if (data) {
    console.log("data",data);
    console.log("title",title);
    switch (title) {
      case "phone number":
        title = "Phone Number";
        break;
      case "email":
        title = "Email";
        break;
      case "address":
        title = "Address";
        break;
      default:
    }

    // register("type", { value: data.type });
    register("title", { value: data.section_title });
    register("value", { value: data.section_info });
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        {title === "Phone Number" ? (
          <div style={data ? { padding: 3 } : {}}>
            <div className={styles.subtitle}>{"Phone Type"}</div>
            <input type="hidden" {...register("type")} value="phone number" />
            <select
              defaultValue={data?.type}
              className={styles.input}
              {...register("title")}
              required={title === "Phone Number" ? true : false}
            >
              <option selected value="Home">
                {"Home"}
              </option>
              <option selected value="Work">
                {"Work"}
              </option>
              <option selected value="Mobile">
                {"Mobile"}
              </option>
            </select>
            <div className={styles.subtitle} style={{ marginTop: "20px" }}>
              {"Phone Number"}
            </div>
            <input
              className={styles.input}
              placeholder={"e.g. 65447654"}
              {...register("value")}
              type={"text"}
              required={title === "Phone Number" ? true : false}
            />
          </div>
        ) : title === "Email" ? (
          <div style={data ? { padding: 3 } : { marginBottom: "104px" }}>
            <div className={styles.subtitle}>{"Email Address"}</div>
            <input type="hidden" {...register("type")} value="email" />
            <input type="hidden" {...register("title")} value="email" />
            <input
              className={styles.input}
              placeholder={"e.g. example@gmail.com"}
              {...register("value")}
              type={"email"}
              required={title === "Email" ? true : false}
            />
          </div>
        ) : title === "Address" ? (
          <div style={data ? { padding: 3 } : {}}>
            <div className={styles.subtitle}>{"Address Type"}</div>
            <input type="hidden" {...register("type")} value="address" />
            <select
              defaultValue={data?.type}
              className={styles.input}
              {...register("title")}
              required={title === "Address" ? true : false}
            >
              <option selected value="Home">
                {"Home"}
              </option>
              <option selected value="Work">
                {"Work"}
              </option>
            </select>
            <div className={styles.subtitle} style={{ marginTop: "20px" }}>
              {"Address"}
            </div>
            <input
              className={styles.input}
              placeholder={"932 Cheung Sha Wan Road"}
              {...register("value")}
              type={"text"}
              required={title === "Address" ? true : false}
            />
          </div>
        ) : (
          <div style={data ? { padding: 3 } : {}}>
            <div className={styles.subtitle}>{"Custom Title"}</div>
            <input type="hidden" {...register("type")} value="custom" />
            <input
              className={styles.input}
              placeholder={"My Business Title"}
              {...register("title")}
              type={"text"}
              required={title === "Custom" ? true : false}
            />
            <div className={styles.subtitle} style={{ marginTop: "20px" }}>
              {"Info"}
            </div>
            <input
              className={styles.input}
              placeholder={"932 Cheung Sha Wan Road"}
              {...register("value")}
              type={"text"}
              required={title === "Custom" ? true : false}
            />
          </div>
        )}
        <div style={{ display: "flex" }}>
          <input
            className={data ? styles.editButton : styles.submitButton}
            type={"submit"}
            value={data ? "Save Edit" : "Submit"}
          />
          {data && onRemove ? (
            <DeleteButton
              onRemove={() => {
                onRemove();
              }}
              style={{
                marginBlock: "2%",
                marginInline: "0.5%",
                height: "40px",
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </form>
    </>
  );
};

export default InputModal;
