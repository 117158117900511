import { Box, Navbar, ScrollArea } from "@mantine/core";
import React from "react";
import { CorpadminLinks, SuperAdminLinks } from "./components/SideBarLinks";

interface Props {
  superAdmin: boolean;
}

export default function SideBar(props: Props) {
  return (
    <Navbar height={"100%"} p="xs" width={{ base: 340 }}>

      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <Box py="md">
          <div>
            {props.superAdmin ? <SuperAdminLinks /> : <CorpadminLinks />}
          </div>
          <div style={{ height: 200 }}></div>
        </Box>
      </Navbar.Section>
    </Navbar>
  );
}
