import { Box, Modal, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { newGalleryState } from "../../recoil_state";
import EditButton from "../Buttons/EditButton";
import ImageViewAndEdit from "../ImageViewAndEdit";
import { useRecoilState } from "recoil";
import produce from "immer";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  onSubmit: () => void;
  createNewGallery: boolean;
  products: boolean;
}

export default function CreateNewGalleryModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // setting
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [newGallery, setNewGallery] = useRecoilState(newGalleryState);
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------
  const createNewGalleryString = props.createNewGallery ? "Create" : "Edit";
  const productsString = props.products ? "Product" : "Gallery";
  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={createNewGalleryString + " " + productsString}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {props.createNewGallery && (
            <>
              <TextInput
                style={{ paddingBlock: 3 }}
                placeholder="link"
                label="link"
                value={newGallery.link ? newGallery.link : ""}
                onChange={(event) =>
                  setNewGallery(
                    produce((draftState) => {
                      draftState.link = event.currentTarget.value;
                    })
                  )
                }
              />

              <TextInput
                style={{ paddingBlock: 3 }}
                placeholder="description"
                label="description"
                value={newGallery.description ? newGallery.description : ""}
                onChange={(event) =>
                  setNewGallery(
                    produce((draftState) => {
                      draftState.description = event.currentTarget.value;
                    })
                  )
                }
              />
            </>
          )}
          <div style={{ paddingBlock: 3, color: "red" }}>{error}</div>
          <div style={{ paddingBlock: 3 }}>
            <ImageViewAndEdit
              type={"icon"}
              title={"profile"+" "+ productsString}
              onRemove={() => {}}
              imageBase64={(base64) => {
                setNewGallery(
                  produce((draftState) => {
                    draftState.resized_url = base64;
                  })
                );
              }}
              imageFile={(file) => {
                setNewGallery(
                  produce((draftState) => {
                    draftState.picture_url = file;
                  })
                );
              }}
            />
          </div>
          <EditButton
            name={"Save" +" "+productsString}
            onEdit={() => {
              if (newGallery.resized_url) {
                props.onSubmit();
              } else {
                setError("Please select crop ");
              }
            }}
            style={{ width: "100%" }}
          />
        </Box>
      </Modal>
    </>
  );
}
