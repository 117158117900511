import {
  Alert,
  Box,
  Button,
  Group,
  Modal,
  PasswordInput,
  Switch,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useRef, useState } from "react";
import { postMethod } from "../../utilities/fetchMethod";

interface Props {
  info: { id: number; super_admin: boolean };
  onClose: () => void;
  onRefresh: () => void;
}

export default function EditAdminModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const passwordRef = useRef<HTMLInputElement>(null);
  const adminRef = useRef<HTMLInputElement>(null);

  const [disable, setDisable] = useState(false)

  //form
  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
      super_admin: props.info.super_admin,
    },
    validate: {
      password: (value) =>
        value.length > 2
          ? null
          : adminRef.current?.checked
          ? "password must contain at least 3 characters"
          : null,
      confirmPassword: (value) =>
        value === passwordRef.current?.value
          ? null
          : "please retype the password again",
    },
  });

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit admin
  async function editAdmin(form: {
    password: string;
    confirmPassword: string;
    super_admin: boolean;
  }) {
    setDisable(true)
    const body = {
      super_admin: form.super_admin,
      id: props.info.id,
      password: form.password,
    };

    const _postMethod = await postMethod(body);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin`,
      _postMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      props.onRefresh();
      props.onClose();
    } else {
      setError(result.error);
      setDisable(false)
    }
  }

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Edit Super Admin ${props.info.id}`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <div style={{ color: "red", fontSize: 15 }}> {error} </div>
          <Alert color={"red"} title={"!!!!"}>
            {`This dashboard is for authorizing super admin role, or setting password to an account for entering the CMS. 
              If you want to set a corporate admin, 
              please go to the profile dashboard, and change it inside profile Info section by editing the profile`}
          </Alert>
          <form onSubmit={form.onSubmit((form) => editAdmin(form))}>
            <Switch
              style={{ margin: 15 }}
              onLabel="Yes"
              offLabel="No"
              ref={adminRef}
              label={"Super Admin"}
              labelPosition={"left"}
              {...form.getInputProps(`super_admin`, { type: "checkbox" })}
            />

            <PasswordInput
              withAsterisk
              label={"Password"}
              ref={passwordRef}
              {...form.getInputProps(`password`)}
            />
            <PasswordInput
              withAsterisk
              label={"Comfirm Password"}
              {...form.getInputProps(`confirmPassword`)}
            />

            <Group position="right" mt="md">
              <Button disabled={disable} type="submit">Submit</Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </>
  );
}
