import { faRemove, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Group, TextInput } from "@mantine/core";
import React, { useState } from "react";

interface Props {
  onSearch: (value: string) => void;
  onRemove: () => void;
}

export default function SearchBar(props: Props) {
  const [text, setText] = useState("");

  return (
    <Group>
      <TextInput
        placeholder="Search"
        value={text}
        onChange={(event) => {
          setText(event.currentTarget.value);
        }}
      />
      <Button color={"grape"} onClick={() => props.onSearch(text)}>
        <FontAwesomeIcon icon={faSearch} size={"sm"} />
      </Button>
      <Button
        color={"red"}
        onClick={() => {
          props.onRemove();
          setText("");
        }}
      >
        <FontAwesomeIcon icon={faRemove} size={"sm"} />
      </Button>
    </Group>
  );
}
