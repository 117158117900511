import React, { useCallback, useEffect, useState } from "react";
import EventPng from "../../images/Event.png";
import {
  Button,
  Dialog,
  Group,
  LoadingOverlay,
  Table,
  Text,
} from "@mantine/core";
import SearchBar from "../../Bars/SearchBar";
import { useLocation } from "react-router-dom";
import { getMethod } from "../../utilities/fetchMethod";
import { user_phone_temp } from "../../utilities/models";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";

export default function TempPhoneNumber() {
  const [loading, setLoading] = useState(true);
  let { state } = useLocation();
  const [error, setError] = useState("");
  const [tempList, setTempList] = useState<user_phone_temp[]>();
  const [opened, { toggle, close }] = useDisclosure(false);
  // ------------------------------------------------------------------
  //  Function
  // ------------------------------------------------------------------

  const fetchAll = useCallback(async () => {
    setLoading(true);
    setError("");

    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/users/phoneNumberTemp`,
      _getMethod
    );
    const result = await res.json();

    if (result.length > 0 && state == "superAdmin") {
      setTempList(result);
    }

    setLoading(false);
  }, [setTempList, setError, state]);
  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);
  //-------------------------------------------------------------------------------------------
  // setting rows & headers
  //-------------------------------------------------------------------------------------------

  //rows
  function Rows(props: user_phone_temp) {
    const day = dayjs(props.created_at).format("YYYY-MM-DD HH:mm");
    return (
      <tr key={props.id}>
        <td>{day}</td>
        <td>{props.phone_number}</td>
      </tr>
    );
  }

  //headers
  function Headers() {
    return (
      <tr>
        <th>Date</th>
        <th>phone number</th>
      </tr>
    );
  }

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
          style={{ position: "fixed" }}
        />

        <div style={{ fontSize: 25, fontWeight: "800" }}>
        Temp Phone Dashboard
        </div>
        {/* <SearchBar
          onSearch={(text) => search(text)}
          onRemove={() => removeSearchText()}
        /> */}
      </div>
      {state == "superAdmin" && <Group></Group>}

      {tempList && tempList.length > 0 && (
        <>
          <Table>
            <thead>
              <Headers />
            </thead>
            {tempList && tempList.length > 0 && (
              <tbody>
                {tempList.map((temp) => (
                  <Rows
                    key={temp.id}
                    id={temp.id}
                    created_at={temp.created_at}
                    updated_at={temp.updated_at}
                    phone_number={temp.phone_number}
                  />
                ))}
              </tbody>
            )}
          </Table>
        </>
      )}
      <Dialog
        opened={opened}
        withCloseButton
        onClose={close}
        size="lg"
        radius="md"
      >
        <Text size="sm" mb="xs" weight={500}>
          {error}
        </Text>
      </Dialog>
    </>
  );
}
