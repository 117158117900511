import { Button } from "@mantine/core";
import React from "react";

interface Props {
  name?: string;
  onRemove: () => void;
  style?: {};
  color?: string;
}

export default function DeleteButton(props: Props) {
  return (
    <Button
      color={props.color ? props.color : "red"}
      onClick={props.onRemove}
      style={props.style ? props.style : {}}
    >
      {props.name ? props.name : "Delete"}
    </Button>
  );
}
