import React from "react";
import { ProfilesInfo, ProfilesInfoWithoutPopulated } from "../utilities/models";
import Avatar from "../images/44-avatar-user-in-circle.svg"

interface Props {
  profileInfo: ProfilesInfoWithoutPopulated;
}

export default function ProfileCards(props: Props) {
  const { profileInfo } = props;

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          width: 300,
          height: 200,
          borderRadius: 20,
          margin: 10,
          paddingLeft: 20,
          paddingRight: 20,
          boxShadow: "0px 3px 9px lightGrey",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <img
            style={{ width: 100, height: 100, borderRadius: 200 }}
            src={
              profileInfo.profile.icon_url
                ? profileInfo.profile.icon_url
                : Avatar
            }
          />
          <div style={{ marginLeft: 20 }}>
            <p
              style={{ fontWeight: "500", color: "black" }}
            >{`${profileInfo.profile.profile_first_name} ${profileInfo.profile.profile_last_name}`}</p>
            <p
              style={{ color: "black" }}
            >{`${profileInfo.profile.profile_title}`}</p>
            <div style={{ color: "lightgray" }}>
              <p style={{ marginBottom: 0 }}>{`Profile Status : ${
                profileInfo.profile.active ? "Active" : "Inactive"
              } `}</p>
              <p
                style={{ marginTop: 0 }}
              >{`Device Number : ${profileInfo.profile.device.length}`}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
