/** @jsxImportSource @emotion/react */
import { css, ThemeProvider } from "@emotion/react";
import { FileInput } from "@mantine/core";
import { isEmpty } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

interface Props {
  type: string;
  title: string;
  src?: string | null;
  onRemove: () => void;
  imageBase64: (base64: string) => void;
  imageFile: (imageFile: File) => void;
}

const ImageViewAndEdit = forwardRef((props: Props, ref) => {
  const [fileInputValue, setFileInputValue] = useState<File | null>();
  const [photo, setPhoto] = useState<HTMLImageElement>(new Image());
  const loadImagesRef = useRef<HTMLImageElement>(null);
  const [imageOnload, setImageOnload] = useState(false);
  const [objectUrl, setObjectUrl] = useState("");

  const [aspect, setAspect] = useState<number>();

  const [crop, setCrop] = useState<Crop>({
    unit: "px", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [base64, setBase64] = useState("");

  useEffect(() => {
    if (props.type === "banner") {
      setAspect(2.5 / 1);
    } else if (props.type === "icon") {
      setAspect(1);
    }
  }, [objectUrl, props.type]);

  const loadImages = React.useMemo(() => {
    return <img src={objectUrl} ref={loadImagesRef} />;
  }, [objectUrl]);

  const cropImage = useCallback((crop: PixelCrop) => {

    let scale = 1;
    let rotate = 0;
    const TO_RADIANS = Math.PI / 180;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = photo.naturalWidth / photo.width;
    const scaleY = photo.naturalHeight / photo.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the photo back down if you want to download/upload and be
    // true to the photos natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = photo.naturalWidth / 2;
    const centerY = photo.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the photo
    ctx.scale(scale, scale);
    // 1) Move the center of the photo to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    if (loadImagesRef.current) {
      ctx.drawImage(
        photo,
        0,
        0,
        photo.naturalWidth * (photo.width / loadImagesRef.current?.width),
        photo.naturalHeight * (photo.height / loadImagesRef.current?.height),
        0,
        0,
        photo.naturalWidth,
        photo.naturalHeight
      );
    }
    ctx.restore();
    // Converting to base64
    if (canvas.width !== 0 && canvas.height !== 0) {
      let base64Image = canvas.toDataURL("image/jpeg");
      let quality = 1.0; 
      do {
        base64Image = canvas.toDataURL("image/jpeg", quality);
        quality -= 0.05;  // Reduce quality in steps
      } while (base64Image.length > 500 * 1024 && quality > 0);
      
      setBase64(base64Image);
      props.imageBase64(base64Image);
    }
  },[base64]);

  useEffect(() => {
    if (completedCrop) {
      cropImage(completedCrop);
    }
  }, [completedCrop, cropImage]);

  useImperativeHandle(ref, () => ({
    removeImage() {
      setBase64("");
      setImageOnload(false);
      setObjectUrl("");
      setPhoto(new Image());
      setFileInputValue(null);
    },
  }));

  function removeImage() {
    setBase64("");
    setImageOnload(false);
    setObjectUrl("");
    setPhoto(new Image());
    setFileInputValue(null);
    props.onRemove();
  }

  return (
    <div
      css={css`
        .img {
          max-width: 300;
        }
        .crop {
          max-width: 80%;
        }
      `}
    >
      {objectUrl && imageOnload && (
        <div>
          <ReactCrop
            className="crop"
            aspect={aspect}
            crop={crop}
            onChange={(c, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(c) => {
              setCompletedCrop(c);
            }}
          >
            {loadImages}
          </ReactCrop>
          <p>preview</p>
          {base64.length > 1 && <img className="crop" src={base64} alt="" />}
          <br />
          <button
            onClick={() => {
              removeImage();
            }}
          >
            Remove
          </button>
        </div>
      )}
      <FileInput
        clearable
        label={`Upload ${props.title}`}
        placeholder="Upload files"
        accept="image/png,image/jpeg,image/jpg,image/webp"
        value={fileInputValue}
        onChange={(event) => {

          setFileInputValue(event);
          if (event) {
            props.imageFile(event);
          }
          if (event) {
            const objectURL = URL.createObjectURL(event);
            photo.src = objectURL;
            photo.onload = () => {
              setImageOnload(true);
            };
            setObjectUrl(objectURL);
          }
        }}
      />
    </div>
  );
});

export default ImageViewAndEdit;
