import React from "react";
import { ThemeIcon, UnstyledButton, Group, Text } from "@mantine/core";
import { Link } from "react-router-dom";

interface MainLinkProps {
  link: string;
  state?: number | string;
  icon: React.ReactNode;
  color: string;
  label: string;
  superAdmin: boolean;
  corpAdmin: boolean;
}

export default function MainLink({
  link,
  icon,
  color,
  label,
  state,
}: MainLinkProps) {
  return (
    <Link style={{ textDecoration: "none" }} to={link} state={state}>
      <UnstyledButton
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color:
            theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>

          <Text size="sm" style={{}}>
            {label}
          </Text>
        </Group>
      </UnstyledButton>
    </Link>
  );
}
