import React, { useCallback, useEffect, useState } from "react";
import HorizontalBarsContainer from "../../Components/analysis/HorizontalBarsContainer";
import LeaderboardCard from "../../Components/analysis/LeaderboardCard";
import LeaderboardContainer from "../../Components/analysis/LeaderboardContainer";
import SmallSquare from "../../Components/analysis/SmallSquare";
import VerticalBars from "../../Components/analysis/VerticalBars";
import { getMethod } from "../../utilities/fetchMethod";
import {
  EditProfilesInfo,
  initialProfileinfo,
  OwnerProfile,
  ProfilesInfo,
} from "../../utilities/models";
import { useLocation } from "react-router-dom";
import ConnectBoardContainer from "../../Components/analysis/ConnectBoardContainer";
import AnalysisNumber from "../../Components/analysis/AnalysisNumber";

export interface pushProfile {
  isAppUser: boolean;
  profile_first_name: string;
  profile_last_name: string;
  profile_title: string;
  company_name: string;
  email: string;
  phone_number: string;
  icon_url: string;
  username: string;
}

export default function AnalysisHomePage() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [loading, setLoading] = useState(true);
  const [profileList, setProfileList] = useState([initialProfileinfo]);
  const [buildedProfileList, setBuildedProfileList] = useState<
    {
      image: string | null | undefined;
      title: string;
      subtitle: string;
      id: number;
      up: boolean;
    }[]
  >([]);
  const [buildedConnectonsProfileList, setBuildedConnectonsProfileList] =
    useState<
      {
        corporateProfile: EditProfilesInfo;
        connectProfiles: pushProfile[];
      }[]
    >([]);
  let { state } = useLocation();
  const [analysisNumber, setanalysisNumber] = useState<
    { title: string; number: number }[]
  >([]);

  useEffect(() => {
    console.log("analysisNumber", analysisNumber);
  }, [analysisNumber]);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  // build list
  // const buildList = useCallback(
  //   async (fetchedProfiles: ProfilesInfo[]) => {
  //     let list: {
  //       id: number;
  //       image: string | null | undefined;
  //       title: string;
  //       subtitle: string;
  //       up: boolean;
  //     }[] = [];

  //     const profiles = fetchedProfiles
  //       .filter((item) => item.profile.active === true)
  //       .slice(0, 10);

  //     for (const profile of profiles) {
  //       const item = {
  //         image: profile.profile.icon_url,
  //         title: `${profile.profile.profile_first_name} ${profile.profile.profile_last_name}`,
  //         subtitle: "637 taps",
  //         id: profile.profile.id,
  //         up: Math.floor(Math.random() * 10) > 5 ? true : false,
  //       };
  //       list.push(item);
  //     }
  //     setBuildedProfileList(list);
  //   },
  //   [setBuildedProfileList]
  // );

  //fetch all profile
  // const fetchAll = useCallback(async () => {
  //   const _getMethod = await getMethod();
  //   const res = await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}/admin/profile/${encodeURI(
  //       String(state)
  //     )}`,
  //     _getMethod
  //   );
  //   const result = await res.json();

  //   setProfileList(result);
  //   buildList(result);
  // }, [setProfileList, buildList, state]);

  const fetchProfileConnectData = useCallback(async () => {
    setLoading(true);
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/connect/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const result: {
      corporateProfile: EditProfilesInfo;
      connectProfiles: pushProfile[];
    }[] = await res.json();
    setBuildedConnectonsProfileList(result);
    setLoading(false);
  }, [state]);

  //fetch analysis Number
  const fetchAnalysisNumber = useCallback(async () => {
    console.log(state);

    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/analysisNumber/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const result = await res.json();
    console.log("result", result);
    setanalysisNumber(result);
  }, [state]);

  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    // fetchAll();
    fetchProfileConnectData();
    fetchAnalysisNumber();
  }, [state]);

  //-------------------------------------------------------------------------------------------
  // samples
  //-------------------------------------------------------------------------------------------

  const sampleData = [
    {
      title: `Sales and marketing`,
      subtitle: `52 Points / User - 97% Correct`,
      id: 1,
      up: true,
    },
    {
      title: `Accounting`,
      subtitle: `52 Points / User - 95% Correct`,
      id: 2,
      up: false,
    },
    {
      title: `Finance`,
      subtitle: `52 Points / User -  87% Correct`,
      id: 3,
      up: true,
    },
    {
      title: `Human Resources`,
      subtitle: `52 Points / User`,
      id: 4,
      up: true,
    },
    {
      title: `Southeast Region`,
      subtitle: `52 Points / User`,
      id: 5,
      up: false,
    },
    {
      title: `District Managers`,
      subtitle: `52 Points / User`,
      id: 6,
      up: false,
    },
    {
      title: `Senior Managers`,
      subtitle: `52 Points / User`,
      id: 7,
      up: false,
    },
    {
      title: `New Hires`,
      subtitle: `52 Points / User`,
      id: 8,
      up: false,
    },
    {
      title: `Southwest Region`,
      subtitle: `52 Points / User`,
      id: 9,
      up: false,
    },
    {
      title: `Northwest Region`,
      subtitle: `52 Points / User`,
      id: 10,
      up: false,
    },
  ];

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div>
        {/* <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              width: 700,
              height: 400,
              padding: 10,
              overflow: "auto",
            }}
          >
            <SmallSquare title={"Active Users"} total={80} partial={27} />
            <SmallSquare title={"Contact saved"} total={"3,298"} />
            <SmallSquare title={"Av.Session Length"} total={"2m 34s"} />
            <SmallSquare
              title={"Starting Knowledge"}
              total={"64%"}
              lineData={[1, 7, 6, 10, 5, 4, 6, 5, 7]}
              lineLabels={["a", "b", "c", "d", "e", "f", "g", "h", "i"]}
            />
            <SmallSquare
              title={"Current Knowledge"}
              total={"86%"}
              lineData={[10, 2, 3, 7, 5, 8, 9, 0, 1]}
              lineLabels={["a", "b", "c", "d", "e", "f", "g", "h", "i"]}
            />
            <SmallSquare
              title={"Connections Gain"}
              total={"+34%"}
              lineData={[29, 23, 45, 56, 87, 12, 0, 90, 23]}
              lineLabels={["a", "b", "c", "d", "e", "f", "g", "h", "i"]}
            />
          </div>
          <div
            style={{
              display: "flex",
              padding: 10,
              width: 700,
              height: 400,
              overflow: "auto",
            }}
          >
            <VerticalBars
              data={[
                100, 120, 130, 150, 300, 600, 250, 400, 100, 130, 350, 300,
              ]}
            />
          </div>
        </div> */}
        {/* <div style={{ display: "flex" }}>
          <HorizontalBarsContainer
            title={"Weakest Topics "}
            data={[
              {
                image: `${process.env.REACT_APP_FRONTEND_URL}/rectangle2370.png`,
                label: "Food Safety",
                data: 74,
              },
              {
                image: `${process.env.REACT_APP_FRONTEND_URL}/rectangle2371.png`,
                label: "Compliance Basics Procedures",
                data: 52,
              },
              {
                image: `${process.env.REACT_APP_FRONTEND_URL}/rectangle2372.png`,
                label: "Company Networking",
                data: 36,
              },
            ]}
            startColor={"#F4B24A"}
            endColor={"#EC6073"}
            background={"#FAE1E0"}
          />
          <HorizontalBarsContainer
            title={"Strongest Topics"}
            data={[
              {
                image: `${process.env.REACT_APP_FRONTEND_URL}/rectangle2373.png`,
                label: "Covid Protocols",
                data: 95,
              },
              {
                image: `${process.env.REACT_APP_FRONTEND_URL}/rectangle2374.png`,
                label: "Cyber Security Basics",
                data: 92,
              },
              {
                image: `${process.env.REACT_APP_FRONTEND_URL}/rectangle2375.png`,
                label: "Social Media Policies",
                data: 89,
              },
            ]}
            startColor={"#7FDD53"}
            endColor={"#2FEA9B"}
            background={"#D9F1E0"}
          />
        </div> */}
        {/* <div style={{ display: "flex" }}>
          <LeaderboardContainer
            title={"User Leaderboard - Connections tapped"}
            data={buildedProfileList}
          />

          <LeaderboardContainer
            title={"Department Leaderboard"}
            data={sampleData}
          />
        </div> */}

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {analysisNumber &&
            analysisNumber.map((item, index) => (
              <AnalysisNumber
                title={item.title}
                number={item.number}
                key={index}
              />
            ))}
        </div>

        <div style={{ display: "flex" }}>
          {buildedConnectonsProfileList && (
            <ConnectBoardContainer
              title={"Profile Connections "}
              connectonDatas={buildedConnectonsProfileList}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>
  );
}
