import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Select,
  Switch,
  TextInput,
} from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
// import { DateRangePicker } from "@mantine/dates";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { getMethod, postMethod } from "../../utilities/fetchMethod";
import { CorpInfo, usernameRegex } from "../../utilities/models";

interface Props {
  buildedProfileList: { value: string; label: string }[];
  onClose: () => void;
  onRefresh: () => void;
}

export default function CreateEventsModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [usernameError, setUsernameError] = useState<string>("");
  const [profileError, setProfileError] = useState<string>("");
  const [username, setUsername] = useState<string>();
  const [profileId, setProfileId] = useState<string | null>(null);
  const [corporateId, setCorporateId] = useState<string | null>(null);
  const [fulldayChecked, setfulldayChecked] = useState(false);

  const [selectCorporateList, setSelectorCorporateList] =
    useState<{ value: string; label: string }[]>();

  //date related
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  const buildList = useCallback(async () => {
    let list: { value: string; label: string }[] = [];

    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/corporates/all`,
      _getMethod
    );

    const result: CorpInfo[] = await res.json();

    list = [];

    for (const company of result) {
      if (company.active) {
        const item = {
          value: company.id.toString(),
          label: company.corporate_name,
        };
        list.push(item);
      }
    }

    setSelectorCorporateList(list);
  }, []);

  //create users
  async function createItem() {
    if (
      !profileId ||
      !startDate ||
      !endDate ||
      !startTime ||
      !endTime ||
      !corporateId ||
      !username ||
      usernameError !== ""
    ) {
      setError("please fill in all categories");
      return;
    }
    const body = {
      username: username,
      corporate: +corporateId,
      owner_profile: parseInt(profileId),
      event_date: [
        {
          date: startDate,
          start_time: startTime,
          end_time: endTime,
        },
        {
          date: endDate,
          start_time: startTime,
          end_time: endTime,
        },
      ],
    };

    const _postMethod = await postMethod(body);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/events`,
      _postMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      props.onRefresh();
      props.onClose();
    } else {
      setError(result.error);
    }
  }

  async function usernameValidation(username: string) {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/profiles/username/unique/${encodeURI(username)}`,
      _getMethod
    );
    let result = await res.json();

    if (result.error) {
      setUsernameError("username duplicated");
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  }

  useEffect(() => {
    buildList();
  }, [buildList]);

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Create Event`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {selectCorporateList ? (
            <>
              <TextInput
                withAsterisk
                label={"Username"}
                error={usernameError}
                value={username}
                onChange={(event) => {
                  const username = event.currentTarget.value
                    .toLowerCase()
                    .trim();
                  const isMatched = username.match(usernameRegex);
                  if (isMatched) {
                    setUsername(username);
                    setUsernameError("");
                  } else if (!username) {
                    setUsername(username);
                    setUsernameError("username should not be empty");
                  } else {
                    setUsernameError(
                      "username should be in english, lowercase and have no other symbols and spaces"
                    );
                  }
                }}
                onBlurCapture={async (event) => {
                  const value = event.currentTarget.value.trim().toLowerCase();
                  await usernameValidation(value);
                }}
              />
              <Select
                data={props.buildedProfileList}
                label="owner_profile"
                placeholder="select profile"
                radius="xs"
                withAsterisk
                searchable
                error={profileError}
                value={profileId}
                onChange={(value) => {
                  setProfileError("");
                  setProfileId(value);
                }}
              />

              <Select
                data={selectCorporateList}
                label="Please select corporate"
                placeholder="select Corporate"
                radius="xs"
                withAsterisk
                value={corporateId}
                onChange={setCorporateId}
                searchable
              />
              {/* //============================================================================== */}
              {/* //event date      ============================================================== */}
              {/* //============================================================================== */}

              <div style={{ border: "solid", padding: 10, marginTop: 25 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  {`Full day ?`}
                  <Switch
                    checked={fulldayChecked}
                    onChange={(event) => {
                      setfulldayChecked(event.currentTarget.checked);
                      if (event.currentTarget.checked) {
                        setStartTime(new Date("July 1, 1999, 00:00:00"));
                        setEndTime(new Date("July 1, 1999, 00:00:00"));
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <DatePicker
                    style={{ marginRight: 10 }}
                    placeholder="Pick date"
                    label="Event Start Date"
                    withAsterisk
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                  />
                  <DatePicker
                    style={{ marginTop: 10 }}
                    placeholder="Pick date"
                    label="Event End Date"
                    withAsterisk
                    value={endDate}
                    onChange={(value) => {
                      setEndDate(value);
                      setEndTime(dayjs(value).hour(dayjs(endTime, "HH:mm").hour()).minute(dayjs(endTime, "HH:mm").minute()).toDate())
                    }}
                    minDate={dayjs(startDate).startOf("days").toDate()}
                    error={
                      //@ts-ignore
                      !dayjs(endDate).isSameOrAfter(dayjs(startDate), "day")
                        ? "End Date must be the same or after Start Date"
                        : false
                    }
                    disabled={startDate ? false : true}
                  />
                </div>
                {!fulldayChecked && (
                  <div>
                    <Divider />
                    <div style={{}}>
                      <TimeInput
                        style={{ marginRight: 10 }}
                        label="Event Start Time"
                        value={startTime}
                        withAsterisk
                        onChange={(value) => startDate!=null? setStartTime(dayjs(startDate).hour(dayjs(value, "HH:mm").hour()).minute(dayjs(value, "HH:mm").minute()).toDate()):setStartTime(value)}
              />
                      <TimeInput
                        style={{ marginTop: 10 }}
                        label="Event End Time"
                        value={endTime}
                        withAsterisk
                        onChange={(value) => endDate!=null? setEndTime(dayjs(endDate).hour(dayjs(value, "HH:mm").hour()).minute(dayjs(value, "HH:mm").minute()).toDate()):setEndTime(value)}
                        error={
                          !dayjs(endTime).isAfter(dayjs(startTime, "min"))
                            ? "End time must be later than Start time"
                            : false
                        }
                        disabled={startTime ? false : true}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div style={{ color: "red" }}>{error}</div>
              <Group position="right" mt="md">
                <Button type="submit" onClick={createItem}>
                  Submit
                </Button>
              </Group>
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </Modal>
    </>
  );
}
