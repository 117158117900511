import {
  Alert,
  Box,
  Button,
  Group,
  Modal,
  NativeSelect,
  Select,
  Switch,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  corporatesListDataState,
  profileListDataState,
} from "../../recoil_state";
import { patchMethod } from "../../utilities/fetchMethod";
import { DeviceInfo } from "../../utilities/models";

interface Props {
  deviceInfo: DeviceInfo;
  onClose: () => void;
  onRefesh: () => void;
}

export default function EditDeviceModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [error, setError] = useState("");
  const form = useForm({
    initialValues: props.deviceInfo,
    validate: {},
  });

  const profileListData = useRecoilValue(profileListDataState);
  const corporatesListData = useRecoilValue(corporatesListDataState);
  const { state } = useLocation();
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit devices
  async function editItem(form: any) {
    setError("");

    let corporate: number | null;
    let profile: number | null;
    if (form.corporates) {
      corporate =
        parseInt(form.corporates) === 0 ? null : parseInt(form.corporates);
    } else {
      corporate = null;
    }

    if (form.profile) {
      profile = parseInt(form.profile) === 0 ? null : parseInt(form.profile);
    } else {
      profile = null;
    }

    let newForm: any;

    if (state === "superAdmin") {
      newForm = {
        active: form.active,
        corporates: corporate,
        name: form.name,
        profile: profile,
        modal: form.modal,
      };
    } else {
      newForm = {
        active: form.active,
        corporates: state,
        name: form.name,
        profile: profile,
      };
    }

    const _patchMethod = await patchMethod(newForm);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/device/${encodeURI(
        String(props.deviceInfo.id)
      )}`,
      _patchMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      props.onRefesh();
      props.onClose();
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("Please try again");
    }
  }

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Edit Device : ${props.deviceInfo.device_id}`}
        key={`${props.deviceInfo.id}`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <Alert>{`This device is currently linked to ${props.deviceInfo.profile? props.deviceInfo.profile.username : 'Nobody'}`}</Alert>

          <form onSubmit={form.onSubmit((form) => editItem(form))}>
            {Object.entries(props.deviceInfo).map(([key, info]) => (
              <div key={key}>
                {key === "name" && (
                  <TextInput
                    withAsterisk
                    label={key}
                    key={`${props.deviceInfo.id}--${key}`}
                    {...form.getInputProps(`${key}`)}
                  />
                )}

                {key === "profile" && (
                  <Select
                    data={profileListData}
                    label="select profile"
                    placeholder="profile username"
                    radius="xs"
                    searchable
                    withAsterisk
                    min={0}
                    {...form.getInputProps(`${key}`)}
                  />
                )}
                {key === "active" && (
                  <Switch
                    key={`${props.deviceInfo.id}--${key}`}
                    style={{ margin: 15 }}
                    defaultChecked={info}
                    onLabel="Yes"
                    offLabel="No"
                    label={key}
                    {...form.getInputProps(`${key}`, { type: "checkbox" })}
                  />
                )}
                {state === "superAdmin" && (
                  <>
                    {key === "corporates" && (
                      <NativeSelect
                        data={corporatesListData}
                        placeholder={"select corporates"}
                        label={"corporates name"}
                        withAsterisk
                        {...form.getInputProps(`${key}`)}
                      />
                    )}
                    {key === "modal" && (
                      <TextInput
                        placeholder={"Loop card"}
                        label={"modal type"}
                        withAsterisk
                        {...form.getInputProps(`${key}`)}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
            <div style={{ color: "red", fontSize: 15 }}>{error}</div>
            <Group position="right" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
          </form>
        </Box>
      </Modal>
    </>
  );
}
