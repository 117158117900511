import React, { useCallback, useEffect, useState } from "react";
import {
  Tabs,
  TextInput,
  Divider,
  Select,
  Alert,
  Switch,
  Button,
} from "@mantine/core";
import { useRecoilState } from "recoil";
import produce from "immer";
import { DatePicker, TimeInput } from "@mantine/dates";
import EditButton from "../Buttons/EditButton";
import dayjs from "dayjs";
import {
  getMethod,
  patchMethod,
  patchMethodwithFormData,
} from "../../utilities/fetchMethod";
import {
  OwnerProfile,
  ProfilesInfo,
  profileType,
  usernameRegex,
} from "../../utilities/models";
import RepresentativeCard from "./RepresentativeCard";
import { editingProfileState } from "../../recoil_state";
import { useLocation } from "react-router-dom";
import InputCSV from "../../Components/ModalBox/InputCSV";

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

interface EventPreviewInfoProps {
  headName: string;
}

export default function EventSection({ headName }: EventPreviewInfoProps) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [editingEvent, setEditingEvent] = useRecoilState(editingProfileState);
  const [profileList, setProfileList] = useState<OwnerProfile[]>();
  const [selectorProfileList, setSelectorProfileList] =
    useState<{ value: string; label: string }[]>();
  const [selectorCorporateList, setSelectorCorporateList] =
    useState<{ value: string; label: string }[]>();
  const [selected, setSelected] = useState<string | null>();
  const { state } = useLocation();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [statusSelected, setStatusSelected] = useState<string | null>(
    editingEvent.profile.eventSection.status
  );
  const [corporateSelected, setCorporateSelected] = useState<string | null>();
  const [error, setError] = useState<{ color: string; message: string }>({
    color: "red",
    message: "",
  });
  const [publicMode, setPublic] = useState(editingEvent.profile.public);
  const [topEvent, setTopEvent] = useState(
    editingEvent.profile.eventSection.topEvent
  );
  
  const [inputCsvVisible, setInputCsvVisible] = useState(false);
  //date related
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [startTime, setStartTime] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();

  const eventStatus = [
    {
      value: "awaitingApproval",
      label: "Awaiting Approval",
    },
    {
      value: "approved",
      label: "Approved",
    },
    {
      value: "inProgress",
      label: "In Progress",
    },
    {
      value: "finished",
      label: "Finished",
    },
    {
      value: "removed",
      label: "Removed",
    },
  ];

  //-------------------------------------------------------------------------------------------
  // save function
  //-------------------------------------------------------------------------------------------

  async function save() {
    setError({
      color: "red",
      message: "",
    });
    if (
      !editingEvent.profile.eventSection.owner_profile ||
      !editingEvent.profile.username ||
      usernameError !== "" ||
      !username ||
      !eventStatus
    ) {
      setError({
        color: "red",
        message: "please complete the form!",
      });
      return;
    }
    const formData = new FormData();

    formData.append(
      "profileInfo",
      JSON.stringify({
        username: editingEvent.profile.username,
        corporate: corporateSelected
          ? parseInt(corporateSelected) > 0
            ? parseInt(corporateSelected)
            : null
          : null,
        public: publicMode,
      })
    );

    //  fetch profile info to backend =============================================
    const _patchMethodwithFormData = await patchMethodwithFormData(formData);
    const profileRes = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/${encodeURI(
        String(editingEvent.profile.id)
      )}`,
      _patchMethodwithFormData
    );

    const profileResult = await profileRes.json();
    if (profileResult.message !== "success") {
      setError({
        color: "red",
        message: "Something wrong happened, please try again.",
      });
      return;
    }
    //  fetch eventInfo info to backend =============================================

    const start = {
      date: startDate as Date,
      start_time: checked ? (startDate as Date) : (startTime as Date),
      end_time: checked ? (startDate as Date) : (endTime as Date),
    };
    const end = {
      date: endDate as Date,
      start_time: checked ? (endDate as Date) : (startTime as Date),
      end_time: checked ? (endDate as Date) : (endTime as Date),
    };

    const body = {
      status: statusSelected,
      owner_profile: editingEvent.profile.eventSection.owner_profile.id,
      event_date: [start, end],
      topEvent: topEvent,
    };

    const _patchMethod = await patchMethod(body);
    const eventRes = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/events/${encodeURI(
        String(editingEvent.profile.eventSection.id)
      )}`,
      _patchMethod
    );
    const eventResult = await eventRes.json();
    if (eventResult.message === "success") {
      const _getMethod = await getMethod();
      // get the profile result
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/events/profile/${encodeURI(
          String(editingEvent.profile.id)
        )}`,
        _getMethod
      );
      const profileResult = await res.json();

      if (res.ok) {
        setEditingEvent(profileResult);
        window.history.replaceState(
          { key: window.history.state.key, usr: editingEvent },
          ""
        );
        setError({
          color: "green",
          message: "Event information successfully saved!",
        });
      } else {
        setError({
          color: "red",
          message: "Something wrong happened, please try again.",
        });
      }
    } else {
      setError({
        color: "red",
        message: "Something wrong happened, please try again.",
      });
    }
  }

  //-------------------------------------------------------------------------------------------
  // fetching and building resources
  //-------------------------------------------------------------------------------------------
  //building the profile selector
  const buildProfileSelector = useCallback((profiles: OwnerProfile[]) => {
    let list = [];
    for (const profile of profiles) {
      if (profile.active === true && profile.username) {
        list.push({
          value: String(profile.id),
          label: profile.username,
        });
      }
    }
    setSelectorProfileList(list);
  }, []);

  //building the profile selector
  const buildCorporateSelector = useCallback(
    (
      companies: {
        id: number;
        created_at: string;
        updated_at: string;
        corporate_name: string;
        active: boolean;
        deleted_time: string | null;
      }[]
    ) => {
      let list = [];

      for (const company of companies) {
        if (company.active === true) {
          list.push({
            value: String(company.id),
            label: company.corporate_name,
          });
        }
      }
      setSelectorCorporateList(list);
    },
    [setSelectorCorporateList]
  );

  //fetch all profiles
  const fetchAllProfiles = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/forselect/${encodeURI(
        String("superAdmin")
      )}`,
      _getMethod
    );
    const result = await res.json();
    if (res.ok) {
      setProfileList(result);
      buildProfileSelector(
        result.filter(
          (item: any) =>
            item.active === true && item.profile_type === profileType.individual
        )
      );
    }
  }, [buildProfileSelector]);

  //fetch all corporates
  const fetchAllCorporates = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/corporates/all`,
      _getMethod
    );
    const result = await res.json();
    if (res.ok) {
      buildCorporateSelector(result);
    }
  }, [buildCorporateSelector]);

  //-------------------------------------------------------------------------------------------
  // tags related
  //-------------------------------------------------------------------------------------------

  async function usernameValidation(username: string) {
    if (username === state.profile.username) {
      setUsernameError("");
      return true;
    }
    const _getMethod = await getMethod();
    const res = await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/profiles/username/unique/${encodeURI(username)}`,
      _getMethod
    );
    let result = await res.json();
    if (result.error) {
      setUsernameError(result.error);
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  }

  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------

  //fetching resources
  useEffect(() => {
    //get profiles
    fetchAllProfiles();
    //get corporates
    fetchAllCorporates();
  }, [fetchAllProfiles, fetchAllCorporates]);

  // setting default value
  useEffect(() => {
    setSelected(
      editingEvent.profile.eventSection.owner_profile
        ? String(editingEvent.profile.eventSection.owner_profile.id)
        : null
    );

    setUsername(editingEvent.profile.username);
    setStatusSelected(editingEvent.profile.eventSection.status);
    setCorporateSelected(
      editingEvent.profile.corporate == null
        ? null
        : String(editingEvent.profile.corporate.id)
    );
    setPublic(editingEvent.profile.public);

    //date related
    setStartDate(
      editingEvent.event_dates.length === 0
        ? null
        : new Date(editingEvent.event_dates[0].date)
    );
    setEndDate(
      editingEvent.event_dates.length === 0
        ? null
        : new Date(
            editingEvent.event_dates[editingEvent.event_dates.length - 1].date
          )
    );
    setStartTime(
      editingEvent.event_dates.length === 0
        ? null
        : new Date(editingEvent.event_dates[0].start_time)
    );
    setEndTime(
      editingEvent.event_dates.length === 0
        ? null
        : new Date(
            editingEvent.event_dates[
              editingEvent.event_dates.length - 1
            ].end_time
          )
    );

    const startTime = new Date(
      editingEvent.event_dates[0].start_time
    ).getHours();
    const endTime = new Date(editingEvent.event_dates[0].end_time).getHours();

    if (startTime === endTime) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [
    editingEvent,
    setSelected,
    setUsername,
    setStatusSelected,
    setCorporateSelected,
    setPublic,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setChecked,
  ]);

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <Tabs.Panel
      value={headName}
      pt="xs"
      style={{ padding: 10, marginBottom: 25, overflow: "auto" }}
    >
      {/* //============================================================================== */}
      {/* //CSV modal     ================================================================ */}
      {/* //============================================================================== */}
      {inputCsvVisible && (
        <InputCSV
          onClose={() => setInputCsvVisible(false)}
          onRefresh={() => {}}
          eventId={editingEvent.profile.id}
        />
      )}
      {/* //============================================================================== */}
      {/* //event status        ========================================================== */}
      {/* //============================================================================== */}
      <Select
        style={{ width: "100%", marginTop: 10 }}
        value={statusSelected}
        onChange={(value) => {
          setStatusSelected(value);
        }}
        data={eventStatus}
        label={`Event Status now : ${editingEvent.profile.eventSection.status}`}
        placeholder="Status"
        withAsterisk
      />
      {/* //============================================================================== */}
      {/* //event public status ========================================================== */}
      {/* //============================================================================== */}
      <div
        style={{
          marginTop: 10,
          fontSize: 15,
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
        }}
      >
        {`Public event : ${editingEvent.profile.public}`}
        <Switch
          checked={publicMode}
          style={{ marginTop: 10 }}
          onChange={(event) => {
            setPublic(event.currentTarget.checked);
          }}
          onLabel="Public"
          offLabel="Private"
        />
      </div>
      {/* //============================================================================== */}
      {/* //top event? ========================================================== */}
      {/* //============================================================================== */}
      <div
        style={{
          marginTop: 10,
          fontSize: 15,
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
        }}
      >
        {`Is it Top event? : ${editingEvent.profile.eventSection.topEvent}`}
        <Switch
          checked={topEvent}
          style={{ marginTop: 10 }}
          onChange={(event) => {
            setTopEvent(event.currentTarget.checked);
          }}
          onLabel="yes"
          offLabel="No"
        />
      </div>
      {/* //============================================================================== */}
      {/* //event invitation code ========================================================== */}
      {/* //============================================================================== */}
      <div
        style={{ marginTop: 10, fontSize: 15, fontWeight: 500 }}
      >{`Event code: ${editingEvent.profile.eventSection.code}`}</div>
      <Button
        color={"green"}
        onClick={() => setInputCsvVisible(true)}
        style={{ marginTop: 10 }}
      >
        {"Input CSV to send email with code for private events invitation"}
      </Button>

      <a href="/loopInvitationEmailCsv.csv" download>
        <Button style={{ marginTop: 15 }} color={"yellow"}>
          {"Download Invitation CSV template"}
        </Button>
      </a>

      {/* //============================================================================== */}
      {/* //event Corporate     ========================================================== */}
      {/* //============================================================================== */}
      {selectorCorporateList && (
        <Select
          style={{ width: "100%", marginBlock: 10 }}
          value={corporateSelected}
          onChange={(value) => {
            setCorporateSelected(value);
          }}
          data={selectorCorporateList}
          label={`Select Corporate`}
          placeholder="Status"
          withAsterisk
        />
      )}
      {/* //============================================================================== */}
      {/* //username        ============================================================== */}
      {/* //============================================================================== */}
      <TextInput
        placeholder="Username"
        label="Username"
        value={username}
        error={usernameError ? usernameError : ""}
        withAsterisk
        onChange={(event) => {
          const username = event.currentTarget.value.toLowerCase().trim();
          const isMatched = username.match(usernameRegex);

          if (isMatched) {
            setUsername(username);
            setUsernameError("");
          } else if (!username) {
            setUsername(username);
            setUsernameError("username should not be empty");
          } else {
            setUsernameError(
              "username should be in english, lowercase and have no other symbols and spaces"
            );
          }
        }}
        onBlurCapture={async (event) => {
          const value = event.currentTarget.value;
          if (await usernameValidation(value.trim())) {
            setEditingEvent(
              produce((draftState) => {
                draftState.profile.username = value.trim().toLowerCase();
              })
            );
          }
        }}
      />
      {/* //============================================================================== */}
      {/* //event holder    ============================================================== */}
      {/* //============================================================================== */}
      {selectorProfileList && (
        <>
          <Divider />
          <Select
            style={{ width: "100%", marginTop: 10 }}
            value={selected}
            onChange={(value) => {
              setSelected(value);
              setEditingEvent(
                produce((draft) => {
                  const selected = profileList!.find(
                    (profile) => String(profile.id) === value
                  );
                  if (selected) {
                    draft.profile.eventSection.owner_profile = selected;
                    draft.profile.corporate = selected.corporate;
                  }
                })
              );
            }}
            data={selectorProfileList}
            label="Select holder"
            placeholder="holder"
            searchable
            nothingFound="Nothing Found"
            withAsterisk
          />
        </>
      )}
      {editingEvent.profile.eventSection.owner_profile && (
        <>
          <div style={{ marginTop: 20 }}>{`Event Holder`}</div>
          <RepresentativeCard
            key={editingEvent.profile.eventSection.owner_profile.id}
            username={editingEvent.profile.eventSection.owner_profile.username}
            profileIcon={
              editingEvent.profile.eventSection.owner_profile.icon_url
            }
            firstName={
              editingEvent.profile.eventSection.owner_profile.profile_first_name
            }
            Lastname={
              editingEvent.profile.eventSection.owner_profile.profile_last_name
            }
            corporate={
              editingEvent.profile.eventSection.owner_profile.corporate
                ? editingEvent.profile.eventSection.owner_profile.corporate
                : null
            }
          />
        </>
      )}
      {/* //============================================================================== */}
      {/* //event date      ============================================================== */}
      {/* //============================================================================== */}
      <div style={{ border: "solid", padding: 10, marginTop: 25 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          {`Full day ?`}
          <Switch
            checked={checked}
            onChange={(event) => {
              setChecked(event.currentTarget.checked);
              if (event.currentTarget.checked) {
                
                setStartTime(startDate);
                setEndTime(endDate);
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <DatePicker
            style={{ marginRight: 10 }}
            placeholder="Pick date"
            label="Event Start Date"
            withAsterisk
            value={startDate}
            onChange={(value) => setStartDate(value)}
          />
          <div style={{ marginTop: 25 }}>{` to `}</div>
          <DatePicker
            style={{ marginLeft: 10 }}
            placeholder="Pick date"
            label="Event End Date"
            withAsterisk
            value={endDate}
            onChange={(value) => {
              setEndDate(value);
              setEndTime(dayjs(value).hour(dayjs(endTime, "HH:mm").hour()).minute(dayjs(endTime, "HH:mm").minute()).toDate())
            }}
            minDate={dayjs(startDate).startOf("days").toDate()}
            error={
              //@ts-ignore
              !dayjs(endDate).isSameOrAfter(dayjs(startDate), "day")
                ? "End Date must be the same or after Start Date"
                : false
            }
            disabled={startDate ? false : true}
          />
        </div>
        {!checked && (
          <div>
            <Divider />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TimeInput
                style={{ marginRight: 10 }}
                label="Event Start Time"
                value={startTime}
                withAsterisk
                onChange={(value) => startDate!=null? setStartTime(dayjs(startDate).hour(dayjs(value, "HH:mm").hour()).minute(dayjs(value, "HH:mm").minute()).toDate()):setStartTime(value)}
              />
              
              <div style={{ marginTop: 30 }}>{` to `}</div>
              <TimeInput
                style={{ marginLeft: 10 }}
                label="Event End Time"
                value={endTime}
                withAsterisk
                onChange={(value) => endDate!=null? setEndTime(dayjs(endDate).hour(dayjs(value, "HH:mm").hour()).minute(dayjs(value, "HH:mm").minute()).toDate()):setEndTime(value)}
                error={
                  !dayjs(endTime).isAfter(dayjs(startTime, "min"))
                    ? "End time must be later than Start time"
                    : false
                }
                disabled={startTime ? false : true}
              />
            </div>
          </div>
        )}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        ></div>
      </div>
      {error.message !== "" && (
        <Alert title="!!!!!" color={error.color}>
          {error.message}
        </Alert>
      )}
      <div
        style={{
          marginTop: 10,
          marginBottom: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <EditButton name={"Save"} onEdit={() => save()} />
      </div>
    </Tabs.Panel>
  );
}
