import React, { useEffect, useState } from "react";

import {

  Group,
  MultiSelect,
  NativeSelect,

} from "@mantine/core";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useRecoilValue } from "recoil";
import { jobTagsListDataState, jobSubTagsListDataState } from "../recoil_state";
import { ProfilesInfo, profileType } from "../utilities/models";

interface Props {
  filteredProfileData: (value: ProfilesInfo[]) => void;
  filteredCorporateMainListData: (value: ProfilesInfo[]) => void;
  backupProfileData: ProfilesInfo[];
  backupCorporateMainListData: ProfilesInfo[];
  searchText?: string;
}

export default function ProfileDashboardFilterBar(props: Props) {
  const { RangePicker } = DatePicker;
  const jobTagsListData = useRecoilValue(jobTagsListDataState);
  const jobSubTagsListData = useRecoilValue(jobSubTagsListDataState);

  const [dateString, setDateString] = useState<[string, string] | null>([
    "",
    "",
  ]);

  const [filterActive, setFilterActive] = useState("");
  const [filterAble, setFilterAble] = useState("");
  const [filterProfileType, setFilterProfileType] = useState("");

  const [selectedJobTags, setSelectedJobTags] = useState<string[]>([]);
  const [selectedJobSubTags, setSelectedJobSubTags] = useState<string[]>([]);

  dayjs.extend(customParseFormat);

  // defart useEffect

  //main useEffect

  //main useEffect
  useEffect(() => {
    let toFilterProfileData = props.backupProfileData;
    let toFilterCorporateMainListData = props.backupCorporateMainListData;
    //search bar
    if (props.searchText) {
      const lowerCaseSearchText = props.searchText.toLowerCase();
      toFilterProfileData = toFilterProfileData.filter(
        (item) =>
          item.profile.username.toLowerCase().includes(lowerCaseSearchText!) ||
          (item.profile.profile_first_name &&
            item.profile.profile_first_name
              .toLowerCase()
              .includes(lowerCaseSearchText!)) ||
          (item.profile.profile_last_name &&
            item.profile.profile_last_name
              .toLowerCase()
              .includes(lowerCaseSearchText!)) ||
          (item.profile.profile_email &&
            item.profile.profile_email
              .toLowerCase()
              .includes(lowerCaseSearchText!)) ||
          (item.profile.phone_number &&
            item.profile.phone_number.includes(lowerCaseSearchText!))
      );
      toFilterCorporateMainListData = toFilterCorporateMainListData.filter(
        (item) => item.profile.username.includes(props.searchText!)
      );
    }
    // Created_at
    if (dateString && dateString[0].length > 0) {
      toFilterProfileData = filterDate(dateString, toFilterProfileData);
      toFilterCorporateMainListData = filterDate(
        dateString,
        toFilterCorporateMainListData
      );
    }
    // Active ["All", "Active", "Non-Active"]
    if (filterActive.length > 0 && filterActive != "All") {
      if (filterActive == "Active") {
        toFilterProfileData = toFilterProfileData.filter(
          (item) => item.profile.active == true
        );
      } else if (filterActive == "Non-Active") {
        toFilterProfileData = toFilterProfileData.filter(
          (item) => item.profile.active == false
        );
      }
    }

    // Active ["All", "Able", "Disable"]
    if (filterAble.length > 0 && filterAble != "All") {
      if (filterAble == "Able") {
        toFilterProfileData = toFilterProfileData.filter((item) => {
          return item.profile.disable == false;
        });
      } else if (filterAble == "Disable") {
        toFilterProfileData = toFilterProfileData.filter(
          (item) => item.profile.disable == true
        );
      }
    }
    // filter JobTags
    if (selectedJobTags.length > 0 || selectedJobSubTags.length > 0) {
      toFilterProfileData = toFilterProfileData.filter((item) => {
        if (
          item.jobtags.filter((tag) => {
            let qurrey = false;

            if (selectedJobTags.length > 0) {
              for (const selectedJobTag of selectedJobTags) {
                const find = tag.jobtag.id == parseInt(selectedJobTag);
                if (find) {
                  qurrey = true;
                }
              }
            }
            if (selectedJobSubTags.length > 0) {
              for (const selectedJobSubTag of selectedJobSubTags) {
                const find = tag.id == parseInt(selectedJobSubTag);
                if (find) {
                  qurrey = true;
                }
              }
            }

            if (qurrey) {
              return true;
            } else {
              return false;
            }
          }).length > 0
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    // profileType  ["All", "individual", "corporate"]

    if (filterProfileType.length > 0 && filterProfileType != "All") {
      if (filterProfileType == profileType.individual) {
        toFilterProfileData = toFilterProfileData.filter(
          (item) => item.profile.profile_type == profileType.individual
        );
      } else if (filterProfileType == profileType.corporate) {
        toFilterProfileData = toFilterProfileData.filter(
          (item) => item.profile.profile_type == profileType.corporate
        );
      }
    }
    props.filteredProfileData(toFilterProfileData);
    props.filteredCorporateMainListData(toFilterCorporateMainListData);
  }, [
    filterProfileType,
    selectedJobSubTags,
    selectedJobTags,
    filterAble,
    filterActive,
    dateString,
    props.searchText,
  ]);

  //=======================================================================================

  //filter Date function
  function filterDate(
    dateString: [string, string],
    toFilterData: ProfilesInfo[]
  ) {
    const formatCreated_at = (created_at: string) => {
      return dayjs(created_at).format("YYYY-MM-DD HH:mm:ss");
    };

    const filter = toFilterData.filter(
      (item) =>
        formatCreated_at(item.profile.created_at) >= dateString[0] &&
        formatCreated_at(item.profile.created_at) <= dateString[1]
    );
    return filter;
  }
  //==========================================================================================
  const onChangeDateString = (
    date: null | (Dayjs | null)[],
    dateString: [string, string]
  ) => {
    if (date) {
      setDateString(dateString);
    } else {
      setDateString(null);
    }
  };

  return (
    <Group>
      <div style={{ display: "flex" }}>
        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>Created_at : </div>

          <RangePicker
            onChange={onChangeDateString}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [
                dayjs("00:00:00", "HH:mm:ss"),
                dayjs("11:59:59", "HH:mm:ss"),
              ],
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>Active :</div>

          <NativeSelect
            data={["All", "Active", "Non-Active"]}
            onChange={(e) => {
              setFilterActive(e.currentTarget.value);
            }}
            size="xs"
          />
        </div>

        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>Able :</div>

          <NativeSelect
            data={["All", "Able", "Disable"]}
            onChange={(e) => {
              setFilterAble(e.currentTarget.value);
            }}
            size="xs"
          />
        </div>

        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>Job Tags :</div>

          <MultiSelect
            placeholder="Pick a Tags"
            onChange={setSelectedJobTags}
            data={jobTagsListData}
            size="xs"
          />
        </div>
        <div style={{ paddingInline: "2px" }}>
          <div style={{ paddingBottom: "10px" }}>JobSub Tags :</div>
          <MultiSelect
            placeholder="Pick a SubTags"
            onChange={setSelectedJobSubTags}
            data={jobSubTagsListData}
            size="xs"
          />
        </div>
      </div>

      <div style={{ paddingInline: "2px" }}>
        <div style={{ paddingBottom: "10px" }}>profile Type :</div>
        <NativeSelect
          data={["All", profileType.corporate, profileType.individual]}
          onChange={(e) => {
            setFilterProfileType(e.currentTarget.value);
          }}
          size="xs"
        />
      </div>
    </Group>
  );
}
