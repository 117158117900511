import {
  Box,
  Button,
  Group,
  Modal,
  PasswordInput,
} from "@mantine/core";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../recoil_state";
import { patchMethod} from "../../utilities/fetchMethod";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
}

export default function EditPassword(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setComfirmPassword] = useState("");
  const [user] = useRecoilState(userState);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit admin
  async function editPassword() {
    setError("");
    if (!newPassword || !oldPassword || !confirmPassword) {
      setError("Please fill in the form");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Please retype the password correctly");
      return;
    }

    const body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      id: user.id,
    };

    const _patchMethod = await patchMethod(body);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/password`,
      _patchMethod
    );
    const result = await res.json();

    if (result.message === "success") {
      props.onRefresh();
      props.onClose();
    } else {
      if(result.error){
        setError(result.error);
      } else {
        setError("please try again")
      }
      
    }
  }

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Edit Password : ${user.id}`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <div style={{ color: "red", fontSize: 15 }}> {error} </div>

          <PasswordInput
            withAsterisk
            label={"Old Password"}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.currentTarget.value)}
          />
          <PasswordInput
            withAsterisk
            label={"New Password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.currentTarget.value)}
          />
          <PasswordInput
            withAsterisk
            label={"Comfirm Password"}
            value={confirmPassword}
            onChange={(e) => setComfirmPassword(e.currentTarget.value)}
          />

          <Group position="right" mt="md">
            <Button type="submit" onClick={editPassword}>
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
