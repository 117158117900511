import {
  Avatar,
  Box,
  Group,
  Modal,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { editingProfileState } from "../../recoil_state";
import {
  getMethod,
  postMethod,
  postMethodWithFormData,
} from "../../utilities/fetchMethod";
import { LinkReference } from "../../utilities/models";
import EditButton from "../Buttons/EditButton";
import ImageViewAndEdit from "../ImageViewAndEdit";
import { useRecoilState } from "recoil";

interface Props {
  fetchUrl: string;
  profile: boolean;
  custom: boolean;
  onClose: () => void;
  onRefresh: () => void;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

export default function CreateNewSocialLinkModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // setting
  //-------------------------------------------------------------------------------------------
  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);

  const [referenceList, setReferenceList] = useState<LinkReference[]>();
  const [buildReferenceList, setBuildReferenceList] =
    useState<{ value: string; label: string; image: string }[]>();

  const [selectedSocialLink, setSelectedSocialLink] = useState<string | null>(
    ""
  );
  const [linkTitle, setlinkTitle] = useState<string>("");
  const [iconUrl, setIconUrl] = useState<string>("");
  const [socialLinkURL, setSocialLinkURL] = useState<string>("");
  const [croppedIcon, setCroppedIcon] = useState<string>();
  const [error, setError] = useState("");

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //create social Links
  async function addNew() {

    if(!linkTitle || !socialLinkURL){
      return
    }

    let result;
    
    if(croppedIcon){
      const data = new FormData();
      const blob = await fetch(croppedIcon).then((r) => r.blob());

      data.append("file", blob);
      data.append("link_title", linkTitle);
      data.append("social_link", socialLinkURL);
      data.append("display", props.custom ? "list" : "grid");
      const _postMethod = await postMethodWithFormData(data);

      let fetchUrl = `${
        process.env.REACT_APP_BACKEND_URL
      }/profiles/links/${encodeURI(String(editingProfile.profile.id))}`;

      const res = await fetch(fetchUrl, _postMethod);
      result = await res.json();
    } else {

      const defaultItem = referenceList!.find(
        (item) => item.title ==='Custom'
      );
      if (!defaultItem) {
        return;
      }
      const body = {
        social_link: socialLinkURL,
        link_title: linkTitle,
        icon_url: iconUrl? iconUrl : defaultItem.icon_url,
        display: props.custom ? "list" : "grid",
      };

      const _postMethod = await postMethod(body);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/profiles/links/${encodeURI(
          String(editingProfile.profile.id)
        )}`,
        _postMethod
      );
      result = await res.json();
    }

    if (result && result.message === "success") {
      const _getMethod = await getMethod();
      const res = await fetch(props.fetchUrl, _getMethod);
      const result = await res.json();
      if (result) {
        setEditingProfile(result);
        window.history.replaceState(
          { key: window.history.state.key, usr: editingProfile },
          ""
        );
        props.onClose();
        props.onRefresh();
      } else {
        setError("Please try again");
      }
    } else {
      setError("Please try again");
    }
  }

  //fetch reference
  const fetchAllReference = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/profiles/links`,
      _getMethod
    );
    const result = await res.json();
    if (res.ok) {
      setReferenceList(result);
      buildReference(result);
    } else {
      console.log("something wrong happened");
    }
  }, []);

  //build reference list
  function buildReference(links: LinkReference[]) {
    let referenceList = [];
    referenceList.push({
      value: "-1",
      label: "upload",
      image: "",
    });
    for (const link of links) {
      const item = {
        value: String(link.id),
        label: link.title,
        image: link.icon_url,
      };
      referenceList.push(item);
    }
    setBuildReferenceList(referenceList);
  }

  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchAllReference();
  }, [fetchAllReference]);

  useEffect(() => {
    if (selectedSocialLink !== "-1" && selectedSocialLink) {
      const reference = referenceList?.find(
        (item) => String(item.id) === selectedSocialLink
      );
      setlinkTitle(reference!.title);
      setIconUrl(reference!.icon_url)
    }
  }, [selectedSocialLink, referenceList]);

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Avatar src={image} />

          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  );

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Create Links`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {buildReferenceList && (
            <Select
              data={buildReferenceList}
              itemComponent={SelectItem}
              label="Social icons"
              placeholder="Select Social icons"
              searchable
              nothingFound="No options"
              onChange={(value) => {
                setSelectedSocialLink(value);
              }}
            />
          )}
          {selectedSocialLink === "-1" && (
            <>
              <div>
                <ImageViewAndEdit
                  type={"icon"}
                  title={"profile icon"}
                  onRemove={() => {}}
                  imageBase64={(file) => setCroppedIcon(file)}
                  imageFile={() => {}}
                />
              </div>
            </>
          )}
          <TextInput
            placeholder="Icon Title"
            label="Icon Title"
            value={linkTitle}
            onChange={(event) => {
              setlinkTitle(event.currentTarget.value);
            }}
          />
          <TextInput
            placeholder="Icon Link"
            label="Icon Link"
            value={socialLinkURL}
            onChange={(event) => {
              setSocialLinkURL(event.currentTarget.value);
            }}
          />
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div style={{ color: "red", padding: 10 }}>{error}</div>
            <EditButton name={"Submit"} onEdit={() => addNew()} />
          </div>
        </Box>
      </Modal>
    </>
  );
}
