import { Button, Group, Header, Menu } from "@mantine/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import ContactLoopModal from "../Components/ModalBox/ContactLoopModal";
import EditPassword from "../Components/ModalBox/EditPassword";
import { userState } from "../recoil_state";

interface Props {
  superAdmin: boolean;
}
export default function TopBar(props: Props) {
  const [user] = useRecoilState(userState);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [contactLoopVisible, setContactLoopVisible] = useState(false);

  return (
    <Header height={70} p="xs">
      {changePasswordVisible && (
        <EditPassword
          onClose={() => setChangePasswordVisible(false)}
          onRefresh={() => {}}
        />
      )}
      {contactLoopVisible && (
        <ContactLoopModal onClose={() => setContactLoopVisible(false)} />
      )}

      <Group sx={{ height: "100%" }} px={20} position="apart">
        <div>LOOP CMS</div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 20 }}>
            User phone number : {user.phone_number}
          </div>
          <div>Super Admin : {user.super_admin ? "YES" : "NO"}</div>
        </div>
        <Group>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button>Options</Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>{"Actions"}</Menu.Label>
              {props.superAdmin && (
                <a
                  href={"https://loopcard-5523.myshopify.com/admin"}
                  target="_blank"
                  rel="noreferrer"
                  style={{textDecoration: 'none'}}
                >
                  <Menu.Item color={"green"}>{"Shopify admin"}</Menu.Item>
                </a>
              )}

              <Menu.Item
                color={"violet"}
                onClick={() => setContactLoopVisible(true)}
              >
                {"Contact LOOP"}
              </Menu.Item>
              <Menu.Item onClick={() => setChangePasswordVisible(true)}>
                {"Change Password"}
              </Menu.Item>
              <Menu.Divider />
              <Link to={"/dashboard/logout"}>
                <Menu.Item>{"Logout"}</Menu.Item>
              </Link>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </Header>
  );
}
