import React, { useEffect, useState } from "react";
import { Tabs } from "@mantine/core";
import { useRecoilState, useResetRecoilState } from "recoil";
import { editingProfileState, newGalleryState } from "../../recoil_state";
import EditButton from "../Buttons/EditButton";
import CreateNewGalleryModalBox from "../ModalBox/CreateNewGalleryModalBox";
import {
  deleteMethodWithBody,
  getMethod,
  patchMethodwithFormData,
  postMethod,
  postMethodWithFormData,
} from "../../utilities/fetchMethod";
import DeleteButton from "../Buttons/DeleteButton";
import EditGallery from "./EditGallery";
import { profileType } from "../../utilities/models";
interface ProfileGalleryProps {
  headName: string;
  createPath: string;
  editPath: string;
  delPath: string;
  products: boolean;
}

export default function ProfileGallery({
  headName,
  createPath,
  editPath,
  delPath,
  products,
}: ProfileGalleryProps) {
  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);
  const [newModal, setNewModal] = useState(false);

  const [newGallery, setNewGallery] = useRecoilState(newGalleryState);
  const resetNewGalleryState = useResetRecoilState(newGalleryState);

  async function galleryFormDataCreate(
    formData: FormData,
    withGallery: boolean
  ) {
    if (withGallery) {
      const blob = await fetch(newGallery.resized_url).then((r) => r.blob());
      formData.append("resized", blob);
      formData.append("original", newGallery.picture_url!);
    }

    formData.append("description", newGallery.description);
    formData.append("link", newGallery.link);
    return formData;
  }

  //=============================================================================
  // function
  //=============================================================================
  async function submitGallery() {
    let formData = new FormData();
    formData = await galleryFormDataCreate(formData, true);

    let res;

    res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${createPath}${encodeURI(
        String(editingProfile.profile.id)
      )}`,
      products
        ? await postMethodWithFormData(formData)
        : await patchMethodwithFormData(formData)
    );

    const result = await res.json();

    if (result.message === "file uploaded" || result.message === "success") {
      setEditingProfile(result.profile);
      window.history.replaceState(
        { key: window.history.state.key, usr: result.profile },
        ""
      );
    } else {
      console.log(result);
    }
  }

  async function editGallery(id: number, withGallery: boolean) {
    let formData = new FormData();
    formData = await galleryFormDataCreate(formData, withGallery);
    if (!products) {
      formData.set(
        "description",
        editingProfile.gallery[
          editingProfile.gallery.findIndex((item) => item.id == id)
        ].description
      );
      formData.set(
        "link",
        editingProfile.gallery[
          editingProfile.gallery.findIndex((item) => item.id == id)
        ].link
      );
    } else {
      formData.set(
        "description",
        editingProfile.products[
          editingProfile.products.findIndex((item) => item.id == id)
        ].description
      );
      formData.set(
        "link",
        editingProfile.products[
          editingProfile.products.findIndex((item) => item.id == id)
        ].link
      );
    }
    formData.append("profileId", String(editingProfile.profile.id));
    const _patchMethod = await patchMethodwithFormData(formData);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${editPath}${encodeURI(String(id))}`,
      _patchMethod
    );
    const result = await res.json();

    if (result.message === "success") {
      setEditingProfile(result.profile);
      window.history.replaceState(
        { key: window.history.state.key, usr: result.profile },
        ""
      );
    } else {
      console.log(result);
    }
  }
  async function removeGallery(id: number) {
    const body = [{ id: id }];
    const _deleteMethodWithBody = await deleteMethodWithBody(body);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${delPath}${encodeURI(
        String(editingProfile.profile.id)
      )}`,
      _deleteMethodWithBody
    );
    const result = await res.json();

    if (result.message === "success") {
      setEditingProfile(result.profile);
      window.history.replaceState(
        { key: window.history.state.key, usr: result.profile },
        ""
      );
    } else {
      console.log(result);
    }
  }
  return (
    <Tabs.Panel
      value={headName}
      pt="xs"
      style={{ height: "100%", padding: 10, overflowY: "visible" }}
    >
      {(editingProfile.profile.profile_type == profileType.corporate ||
        editingProfile.profile.profile_type == profileType.corporate_main) && (
        <div style={{ padding: 10, color: "rgb(255, 0, 255)" }}>
          *This section is applicable when executing the master update action
          for the Corp. Main Profile.
        </div>
      )}
      {products ? (
        <div style={{ padding: 10 }}>
          Products : {editingProfile.products.length}
        </div>
      ) : (
        <div style={{ padding: 10 }}>
          Gallery : {editingProfile.gallery.length}
        </div>
      )}
      {newModal && (
        <CreateNewGalleryModalBox
          onClose={() => setNewModal(false)}
          onRefresh={() => {}}
          onSubmit={() => {
            setNewModal(false);
            submitGallery();
            resetNewGalleryState();
          }}
          createNewGallery={true}
          products={products}
        />
      )}
      <EditButton
        name={products ? "+ New Products" : "+ New Gallery"}
        onEdit={() => setNewModal(true)}
        style={{ width: "100%" }}
      />

      <div>
        {!products &&
          editingProfile.gallery.length > 0 &&
          editingProfile.gallery.map((item) => (
            <EditGallery
              key={item.id}
              gallery={item}
              products={products}
              onEdit={(withGallery: boolean) =>
                editGallery(item.id, withGallery)
              }
              onRemove={() => removeGallery(item.id)}
            />
          ))}
        {products &&
          editingProfile.products.length > 0 &&
          editingProfile.products.map((item) => (
            <EditGallery
              key={item.id}
              products={products}
              gallery={item}
              onEdit={(withGallery: boolean) => {
                editGallery(item.id, withGallery);
              }}
              onRemove={() => removeGallery(item.id)}
            />
          ))}
      </div>
    </Tabs.Panel>
  );
}
