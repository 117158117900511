import React, { useEffect, useState } from "react";
import { Button, Group, Table, Tabs } from "@mantine/core";

import { tagsDeleteMethod, tagsPatchMethod } from "../../utilities/fetchMethod";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import ActivateButton from "../../Components/Buttons/ActiviateButton";
import EditButton from "../../Components/Buttons/EditButton";
import EditTagsModalBox from "../../Components/ModalBox/EditTagsModalBox";
import { EditTags } from "../../utilities/models";
import CreateTagModalBox from "../../Components/ModalBox/CreateTagModalBox";

export default function TagsDashboard() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [tags, settags] = useState<any>({
    Analysis_Actions: [[{}]],
    Contact_TagsList: [[{}]],
    Phone_Locations: [[{}]],
    Improvement: [[{}]],
    EventStatus: [[{}]],
    EventTags: [[{}]],
    JobTags: [[{}]],
    JobSubtags: [[{}]],
  });
  const [tagHeads, settagHeads] = useState<string[]>();

  const [openedEdit, setOpenedEdit] = useState(false);

  const [editTags, setEditTags] = useState<EditTags>();
  const [createTagTable, setCreateTagTable] = useState<string>("");
  const [createTagTableItems, setCreateTagTableItems] = useState<string[]>([]);

  const [openedCreate, setOpenedCreate] = useState(false);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------
  async function removeItem(table: string, id: number) {
    let removeTag = {
      table: table,
      id: id.toString(),
    };

    const _tagsDeleteMethod = await tagsDeleteMethod(removeTag);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/tags/removeTag`,
      _tagsDeleteMethod
    );
    const result = await res.json();
    settags(result);
  }

  async function activateItem(table: string, id: number) {
    let activateTag = {
      table: table,
      id: id.toString(),
    };

    const _tagsPatchMethod = await tagsPatchMethod(activateTag);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/tags/activateTag`,
      _tagsPatchMethod
    );
    const result = await res.json();
    settags(result);
  }
  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  const getAllTags = async () => {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tags/all`);
    let tagObj = await res.json();
    settagHeads(Object.keys(tagObj));
    settags(tagObj);
    return;
  };
  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <>
      {openedCreate && (
        <CreateTagModalBox
          onClose={() => {
            setOpenedCreate(false);
          }}
          onRefresh={() => getAllTags()}
          createTagTable={createTagTable}
          createTagTableItems={createTagTableItems}
          allTags={tags}
        />
      )}
      {openedEdit && editTags && (
        <>
          <EditTagsModalBox
            onClose={() => setOpenedEdit(false)}
            onRefresh={() => getAllTags()}
            editTags={editTags}
          />
        </>
      )}
      <Tabs defaultValue="Tags">
        <Tabs.List>
          {tagHeads &&
            tags &&
            tagHeads.map((head, index) => (
              <Tabs.Tab key={index} value={head}>
                {head}
              </Tabs.Tab>
            ))}
        </Tabs.List>

        {tagHeads &&
          tags &&
          tagHeads.map((head, index) => (
            <React.Fragment key={index}>
              <Tabs.Panel value={head} pt="xs">
                <Button
                  color={"cyan"}
                  onClick={() => {
                    setOpenedCreate(true);
                    setCreateTagTable(head);
                    setCreateTagTableItems(Object.keys(tags[head][0][0]));
                  }}
                >
                  Create Tag
                </Button>
                <Table>
                  <thead>
                    <tr>
                      {head === "InterestsTags" || head === "JobTags" ? (
                        <th></th>
                      ) : (
                        <th>Delete</th>
                      )}
                      <th>Edit</th>
                      {Object.keys(tags[head][0][0]).map((item, index) => (
                        <th key={index}>{item}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {tags[head][0].map((AObj: any) => (
                      <tr key={AObj.id}>
                        <td>
                          {head === "InterestsTags" ||
                            head === "JobTags" ||
                            (AObj.active ? (
                              <DeleteButton
                                onRemove={() => {
                                  removeItem(head, AObj.id);
                                }}
                              />
                            ) : (
                              <ActivateButton
                                onActive={() => {
                                  activateItem(head, AObj.id);
                                }}
                              />
                            ))}
                        </td>
                        <td>
                          <Group>
                            <EditButton
                              onEdit={() => {
                                setEditTags({ head: head, AObj: AObj });
                                setOpenedEdit(true);
                              }}
                            />
                          </Group>
                        </td>

                        {Object.keys(tags[head][0][0]).map((item, index) => (
                          <td key={index}>
                            {typeof AObj[item] === "boolean"
                              ? JSON.stringify(AObj[item])
                              : AObj[item]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tabs.Panel>
            </React.Fragment>
          ))}
      </Tabs>
    </>
  );
}
