import { Button, Divider, Group, Select } from "@mantine/core";
import React from "react";
import SearchBar from "../../Bars/SearchBar";
import AnalysisHomePage from "../analysisPages/AnalysisHomePage";

export default function AnalysisDashboard() {
  return (
    <>
      <div style={{ backgroundColor: "#F8F9FA" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <div style={{ fontSize: 25, fontWeight: "800" }}>
            Analysis Dashboard
          </div>
        </div>
        <Divider />
        {/* <div
          style={{
            display: "flex",
            marginBottom: 10,
            marginLeft: 15,
            marginTop: 10
          }}
        >
          <Select
            data={[
              { value: "Timeframe: All-time", label: "Timeframe: All-time" },
            ]}
            radius={"xl"}
            style={{ marginRight: 30, width: 330 }}
            value={"Timeframe: All-time"}
          />{" "}
          <Select
            data={[{ value: "People: All", label: "People: All" }]}
            radius={"xl"}
            style={{ marginRight: 30, width: 330 }}
            value={"People: All"}
          />{" "}
          <Select
            data={[{ value: "Topic: All", label: "Topic: All" }]}
            radius={"xl"}
            style={{ marginRight: 30, width: 330 }}
            value={"Topic: All"}
          />
        </div> */}
        <div>
          <AnalysisHomePage />
        </div>
      </div>
    </>
  );
}
