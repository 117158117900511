import { Tabs } from "@mantine/core";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import SocialLinksSection from "../../Components/SocialLinkSection";
import CustomLinksSection from "../../Components/CustomLinkSection";
import ProfileGallery from "../../Components/ProfileEditComponents/ProfileGallery";
import ProfileInfo from "../../Components/ProfileEditComponents/ProfileInfo";
import { editingProfileState } from "../../recoil_state";
import ProfileTags from "../../Components/ProfileEditComponents/ProfileTags";
import ProfileSections from "../../Components/ProfileEditComponents/ProfileSections";
import RepresentativeSection from "../../Components/EventEditComponents/RepresentativeSection";
import { profileType } from "../../utilities/models";

export default function Preview() {
  const previewPage = useRef<HTMLIFrameElement | null>(null);
  const [heads, setHeads] = useState<string[]>();
  let { state } = useLocation();
  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);
  const redStarList = [
    "Profile info",
    "Grid Links",
    "List Links",
    "Gallery",
    "Products",
  ];
  const shouldShowRedStar = (head: string, profile_type: string) => {
    return (
      [profileType.corporate_main, profileType.corporate].includes(
        profile_type
      ) && redStarList.includes(head)
    );
  };
  useEffect(() => {
    if (state && state.profile) {
      setEditingProfile(state);
      openPreview();
      if (state.profile.profile_type === profileType.individual) {
        const infoHeads = [
          "Profile info",
          "Grid Links",
          "List Links",
          "Gallery",
          "Profile sections",
          "Tags",
        ];
        setHeads(infoHeads);
      } else if (state.profile.profile_type === profileType.corporate_main) {
        const infoHeads = [
          "Profile info",
          "Grid Links",
          "List Links",
          "Gallery",
          "Profile sections",
          "Tags",
          "Representatives",
          "Products",
        ];
        setHeads(infoHeads);
      } else if (state.profile.profile_type === profileType.corporate) {
        const infoHeads = [
          "Profile info",
          "Grid Links",
          "List Links",
          "Gallery",
          "Profile sections",
          "Tags",
          "Products",
        ];
        setHeads(infoHeads);
      }
    }
    previewPage.current?.addEventListener("load", sendInfoToNextjsPreview);
    return () => {
      previewPage.current?.removeAttribute("src");
    };
  }, [state]);

  function openPreview() {
    if (!previewPage.current?.getAttribute("src")) {
      previewPage.current?.setAttribute(
        "src",
        `${process.env.REACT_APP_NEXTJS_FRONTEND_URL}/previewPage`
      );
    }
  }

  function sendInfoToNextjsPreview() {
    previewPage.current?.contentWindow?.postMessage(
      { data: state, type: "loopInfo" },
      "*"
    );
  }

  useEffect(() => {
    previewPage.current?.contentWindow?.postMessage(
      { data: editingProfile, type: "loopInfo" },
      "*"
    );
  }, [editingProfile]);

  return (
    <div
      style={{
        height: window.screen.availHeight - 230,
        width: "100%",
        display: "flex",
      }}
    >
      <div style={{ height: "100%", width: "65%", padding: 0 }}>
        <div style={{ height: "auto", width: "100%", paddingBottom: 20 }}>
          Editing profile id : {editingProfile.profile.id}, username :{" "}
          {editingProfile.profile.username}
          <br />
          {"Current profile type : "}
          {editingProfile.profile.profile_type}
        </div>
        <Tabs
          radius="lg"
          orientation="vertical"
          defaultValue="Profile info"
          style={{
            height: "100%",
            padding: 0,
            width: "100%",
          }}
        >
          <Tabs.List
            grow
            position="center"
            style={{ width: "20%", padding: 0 }}
          >
            {heads &&
              state &&
              state.profile &&
              heads.map((head) => (
                <Tabs.Tab key={head} value={head} style={{ padding: 0 }}>
                  <div style={{ overflowWrap: "anywhere", display: "flex" }}>
                    <div>{head}</div>
                    <div style={{ color: "red" }}>
                      {shouldShowRedStar(head, state.profile.profile_type)
                        ? "*"
                        : ""}
                    </div>
                  </div>
                </Tabs.Tab>
              ))}
          </Tabs.List>

          <div style={{ width: "80%", padding: 0, overflow: "scroll" }}>
            {state &&
            state.profile &&
            (state.profile.profile_type === profileType.individual ||
              state.profile.profile_type === profileType.corporate ||
              state.profile.profile_type === profileType.corporate_main) ? (
              <>
                <ProfileInfo headName={"Profile info"} />
                <ProfileGallery
                  headName={"Gallery"}
                  createPath={"/profiles/gallery/"}
                  editPath={"/profiles/gallery/update/"}
                  delPath={"/profiles/gallery/"}
                  products={false}
                />
                <SocialLinksSection
                  headName={"Grid Links"}
                  fetchUrl={`${
                    process.env.REACT_APP_BACKEND_URL
                  }/profiles/${encodeURI(String(editingProfile.profile.id))}`}
                />
                <CustomLinksSection
                  headName={"List Links"}
                  fetchUrl={`${
                    process.env.REACT_APP_BACKEND_URL
                  }/profiles/${encodeURI(String(editingProfile.profile.id))}`}
                />
                <ProfileTags headName={"Tags"} />
                <ProfileSections headName={"Profile sections"} />
                {(state.profile.profile_type === profileType.corporate ||
                  state.profile.profile_type ===
                    profileType.corporate_main) && (
                  <ProfileGallery
                    headName={"Products"}
                    createPath={"/profiles/products/"}
                    editPath={"/profiles/products/"}
                    delPath={"/profiles/products/"}
                    products={true}
                  />
                )}
                {(state.profile.profile_type === profileType.corporate ||
                  state.profile.profile_type ===
                    profileType.corporate_main) && (
                  <RepresentativeSection
                    headName={"Representatives"}
                    fetchUrl={`${
                      process.env.REACT_APP_BACKEND_URL
                    }/profiles/${encodeURI(String(editingProfile.profile.id))}`}
                    getProfileState={state.profile.corporate.id}
                  />
                )}
                <div style={{ height: 100 }}></div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Tabs>
      </div>

      <div style={{ height: "100%", width: "35%", padding: 0 }}>
        <iframe height="100%" width="100%" ref={previewPage}></iframe>
      </div>
    </div>
  );
}
