import React, { useEffect, useState } from "react";
import { deleteMethodWithBody, getMethod } from "../../utilities/fetchMethod";
import { initialCorpinfo, CorpInfoProps } from "../../utilities/models";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import EditButton from "../../Components/Buttons/EditButton";
import { Button, Checkbox, Group, Table } from "@mantine/core";
import ActivateButton from "../../Components/Buttons/ActiviateButton";
import { useRecoilState } from "recoil";
import { allCorpState, isLoadingAllCorpState } from "../../recoil_state";
import SearchBar from "../../Bars/SearchBar";
import DisableButton from "../../Components/Buttons/DisableButton";
import EditCorpModalBox from "../../Components/ModalBox/EditCorpModalBox";
import CreateCorpModalBox from "../../Components/ModalBox/CreateCoprModalBox";
import DeleteModalBox from "../../Components/ModalBox/DeleteModalBox";
import { useLocation } from "react-router";
import EditCorpLimitModalBox from "../../Components/ModalBox/EditCorpLimitModalBox";
export default function CorporatesDashboard() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [corpList, setCorpList] = useRecoilState(allCorpState);
  const [currentCorpList, setCurrentCorpList] = useState([initialCorpinfo]);
  const [openedEditName, setOpenedEditName] = useState(false);
  const [openedEditLimit, setOpenedEditLimit] = useState(false);
  const [openedCreate, setOpenedCreate] = useState(false);
  const [editInfo, setEditInfo] = useState(initialCorpinfo);
  const [isLoadingAllCorp, setLoadingAllCorp] = useRecoilState(
    isLoadingAllCorpState
  );
  const [openedDelete, setOpenedDelete] = useState(false);
  const [checked, setChecked] = useState([0]);
  const [deleteCorpName, setDeleteCorpName] = useState("");
  const [error, setError] = useState("");
  const { state } = useLocation();

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //fetch all Corporates
  async function fetchAll() {
    setLoadingAllCorp("fetching");

    let path = "";
    if (state == "superAdmin") {
      path = `${process.env.REACT_APP_BACKEND_URL}/corporates/all`;
    }

    const _getMethod = await getMethod();
    const res = await fetch(path, _getMethod);
    let result = await res.json();

    if (!result) {
      result = initialCorpinfo;
    }

    setCorpList(result);
    setCurrentCorpList(result);
    setLoadingAllCorp("fetched");
  }

  //search function
  async function search(text: string) {
    for (const item of corpList) {
    }
    const filter = corpList.filter((item) =>
      item.corporate_name.toLowerCase().includes(text.toLowerCase())
    );
    if (filter.length === 0) {
    } else {
      setCurrentCorpList(filter);
    }
  }

  //remove search function
  async function removeSearchText() {
    setCurrentCorpList(corpList);
  }

  //refresh
  async function refresh() {
    fetchAll();
  }
  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (isLoadingAllCorp !== "notFetch") {
      setCurrentCorpList(corpList);
    } else {
      fetchAll();
    }
  }, [isLoadingAllCorp, corpList, state]);

  //-------------------------------------------------------------------------------------------
  // setting rows & headers
  //-------------------------------------------------------------------------------------------

  //rows
  function Rows(props: CorpInfoProps) {
    const corpInfo = props.corpInfo;
    const array = Object.entries(corpInfo);
    if(!corpInfo.active)
    return(<></>)
    return (
      <tr key={corpInfo.id}>
        <td>
          {corpInfo.active ? (
            <EditButton
              name={"Edit Name"}
              onEdit={() => {
                setOpenedEditName(true);
                setEditInfo(corpInfo);
              }}
            />
          ) : (
            <DisableButton color={"grey"} name={"Edit"} />
          )}
        </td>
        <td>
          {corpInfo.active ? (
            <EditButton
              name={"Edit Limit"}
              onEdit={() => {
                setOpenedEditLimit(true);
                setEditInfo(corpInfo);
              }}
            />
          ) : (
            <DisableButton color={"grey"} name={"Edit"} />
          )}
        </td>
        <td>
          {corpInfo.active ? (
            <DeleteButton
              name={"Delete Corp"}
              onRemove={() => {
                setChecked([corpInfo.id]);
                setOpenedDelete(true);
                setDeleteCorpName(corpInfo.corporate_name);
              }}
            />
          ) : (
            <DisableButton color={"grey"} name={"Deleted Corporates"} />
          )}
        </td>
        {array.map(([key, value]) => (
          <td key={`${corpInfo.id}-${key}-${value}`}>
            {value === true || value === false ? `${value}` : value}
          </td>
        ))}
      </tr>
    );
  }

  //headers
  function Headers(props: CorpInfoProps) {
    const array = Object.keys(props.corpInfo);
    return (
      <tr>
        <th>Edit Name</th>
        <th>Edit Limit</th>
        <th>Delete Corp</th>
        {array.map((keys) => (
          <th key={keys}>{keys}</th>
        ))}
      </tr>
    );
  }

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <div style={{ fontSize: 25, fontWeight: "800" }}>
          Corporates Dashboard
        </div>
        <SearchBar
          onSearch={(text) => search(text)}
          onRemove={() => removeSearchText()}
        />
      </div>
      <div>{error}</div>
      <Group>
        <Button color={"cyan"} onClick={() => setOpenedCreate(true)}>
          Create Corporates
        </Button>
      </Group>
      {openedEditName && (
        <EditCorpModalBox
          onClose={() => setOpenedEditName(false)}
          onRefesh={() => refresh()}
          corpInfo={editInfo}
        />
      )}

      {openedEditLimit && (
        <EditCorpLimitModalBox
          onClose={() => setOpenedEditLimit(false)}
          onRefesh={() => refresh()}
          corpInfo={editInfo}
        />
      )}

      {openedCreate && (
        <CreateCorpModalBox
          onClose={() => setOpenedCreate(false)}
          onRefresh={() => refresh()}
        />
      )}
      {openedDelete && (
        <DeleteModalBox
          onClose={() => setOpenedDelete(false)}
          onRefresh={() => refresh()}
          itemArray={checked}
          link={`${process.env.REACT_APP_BACKEND_URL}/corporates/delete`}
          name={deleteCorpName}
        />
      )}
      <div style={{ overflow: "auto" }}>
        <Table>
          <thead>
            {corpList[0] ? <Headers corpInfo={corpList[0]} /> : <></>}
          </thead>
          <tbody>
            {currentCorpList[0] ? (
              currentCorpList.map((item) => (
                <Rows key={item.id} corpInfo={item} />
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}
