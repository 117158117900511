import { Divider, Loader, LoadingOverlay } from "@mantine/core";
import React from "react";
import LeaderboardCard from "./LeaderboardCard";
import Path from "../../images/path.svg";
import { Link } from "react-router-dom";
import { pushProfile } from "../../Pages/analysisPages/AnalysisHomePage";
import ConnectboardCard from "./ConnectboardCard";
import { useLocation } from "react-router-dom";
import {
  EditProfilesInfo,
  OwnerProfile,
  ProfilesInfo,
} from "../../utilities/models";

interface Props {
  title: string;
  connectonDatas: {
    corporateProfile: EditProfilesInfo;
    connectProfiles: pushProfile[];
  }[];
  loading: boolean;
}

export default function ConnectBoardContainer(props: Props) {
  const { title, connectonDatas } = props;
  let { state } = useLocation();
  return (
    <>
      <div
        style={{
          width: 605,
          backgroundColor: "white",
          paddingBlock: 20,
          paddingInline: 35,
          borderRadius: 15,
          boxShadow: "0px 3px 9px lightGrey",
          overflow: "hidden",
          margin: 20,
        }}
      >
        <p style={{ color: "grey" }}>{props.title}</p>
        <div>
          {props.loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Loader />
            </div>
          )}
          {connectonDatas.map((item, index) => (
            <ConnectboardCard
              key={index}
              image={
                item.corporateProfile.icon_url
                  ? item.corporateProfile.icon_url
                  : null
              }
              title={
                item.corporateProfile.profile_first_name +
                item.corporateProfile.profile_last_name
              }
              connectProfiles={item.connectProfiles}
            />
          ))}
        </div>
        <Divider />{" "}
        <Link style={{ textDecoration: "none" }} to={`analysis`}state={state}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 76,
            }}
          >
            <p
              style={{
                color: "#1B59F8",
                marginRight: 10,
                textDecoration: "none",
              }}
            >{`View full leaderboard`}</p>
            <img src={Path} />
          </div>
        </Link>
      </div>
    </>
  );
}
