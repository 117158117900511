import React, { useCallback, useEffect, useState } from "react";
import {
  Tabs,
  TextInput,
  Textarea,
  NativeSelect,
  Switch,
  Alert,
  Select,
  NumberInput,
  Text,
} from "@mantine/core";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  allUserState,
  corporatesListDataState,
  editingProfileState,
  phoneLocationsDataState,
  phoneLocationsState,
  selectUserListState,
  userState,
} from "../../recoil_state";
import produce from "immer";
import {
  getMethod,
  patchMethod,
  patchMethodwithFormData,
} from "../../utilities/fetchMethod";
import ImageViewAndEdit from "../ImageViewAndEdit";
import EditButton from "../Buttons/EditButton";
import { useLocation } from "react-router-dom";
import { profileType, usernameRegex } from "../../utilities/models";

interface ProfileInfoProps {
  headName: string;
}

export default function ProfileInfo({ headName }: ProfileInfoProps) {
  const user = useRecoilValue(userState);
  const corporatesListData = useRecoilValue(corporatesListDataState);
  const selectedUserList = useRecoilValue(selectUserListState);

  const userList = useRecoilValue(allUserState);
  const { state } = useLocation();

  const [editingProfile, setEditingProfile] =
    useRecoilState(editingProfileState);
  const [emailTemp, setEmailTemp] = useState<string>("");
  const [phoneNumberTemp, setPhoneNumberTemp] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const phoneLocationsData = useRecoilValue(phoneLocationsDataState);
  const phoneLocations = useRecoilValue(phoneLocationsState);
  const [usernameTemp, setUsernameTemp] = useState<string>("");
  const [usernameError, setUsernameError] = useState<string>("");
  const [corporate_mainTypeError, setCorporate_mainTypeError] =
    useState<string>("");
  const [userSelected, setUserSelected] = useState<string>();
  const [disable, setDisable] = useState(false);

  const [userPhoneNumber, setUserPhoneNumber] = useState<number>();
  const [userPhoneLocationNumber, setUserPhoneLocationNumber] = useState<
    string | null
  >(null);
  const [userPhoneNumberError, setUserPhoneNumberError] = useState<string>();

  const [error, setError] = useState<{ color: string; message: string }>({
    color: "red",
    message: "",
  });

  const [fileInputValue, setFileInputValue] = useState<
    { type: string; file: File }[]
  >([]);

  const [base64InputValue, setBase64InputValue] = useState<
    { type: string; base64: string }[]
  >([]);

  function emailValidation(email: string): boolean {
    if (email == "") {
      return true;
    }
    const emailRegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegExp.test(email);
  }
  async function usernameCheck(value: string) {
    if (await usernameValidation(value.trim().toLowerCase())) {
      setEditingProfile(
        produce((draftState) => {
          draftState.profile.username = value;
        })
      );
      return true;
    }
    return false;
  }
  function phoneValidation(phone: string): boolean {
    if (phone == "") {
      return true;
    }

    const phoneRegExp = /^[0-9]+$/;
    return phoneRegExp.test(phone);
  }

  async function usernameValidation(username: string) {
    if (username === state.profile.username) {
      setUsernameError("");
      return true;
    }
    const _getMethod = await getMethod();
    const res = await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/profiles/username/unique/${encodeURI(username)}`,
      _getMethod
    );
    let result = await res.json();

    if (result.error) {
      setUsernameError(result.error);
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  }

  useEffect(() => {
    let profile = { ...editingProfile.profile };

    if (profile.profile_email) {
      setEmailTemp(profile.profile_email);
    } else {
      setEmailTemp("");
    }
    if (profile.phone_number) {
      setPhoneNumberTemp(profile.phone_number);
    } else {
      setPhoneNumberTemp("");
    }
    setUsernameTemp(profile.username);
    setUserSelected(
      editingProfile.profile.user === null
        ? "0"
        : String(editingProfile.profile.user.id)
    );

    let user = editingProfile.profile.user;
    if (user) {
      let userPhone_number = parseInt(user.phone_number);
      let userPhoneLocationNumber = user.phone_location;
      setUserPhoneNumber(userPhone_number);

      const type = typeof userPhoneLocationNumber;
      if (type == "number") {
        setUserPhoneLocationNumber(userPhoneLocationNumber.toString());
      } else if (type == "object" && userPhoneLocationNumber.id) {
        setUserPhoneLocationNumber(userPhoneLocationNumber.id.toString());
      } else {
        setUserPhoneLocationNumber("95");
      }
    } else {
      //set the phone location to default +852
      setUserPhoneLocationNumber("95");
    }
  }, [editingProfile, setEmailTemp, setPhoneNumberTemp, setUsernameTemp]);

  //===============================================================================================
  // send info to backend =========================================================================
  //===============================================================================================

  async function save() {
    if (usernameError != "" && !(await usernameCheck(usernameTemp))) {
      return;
    }

    setDisable(true);

    setError({ color: "red", message: "" });
    let coporateFoundUserId = null;

    //get the user_id
    if (!user.super_admin && userPhoneNumber && userPhoneLocationNumber) {
      const _patchMethod = await patchMethod({
        phone_location_id: userPhoneLocationNumber,
        phone_number: `${userPhoneNumber}`,
      });
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/admin/checkPhoneNumber`,
        _patchMethod
      );

      const result = await res.json();

      if (res.ok && result.userId) {
        coporateFoundUserId = result.userId;
      } else {
        setError({
          color: "red",
          message: result.message ?? "please try again",
        });
        setDisable(false);
        return;
      }
    }

    //  image=============================================
    const formData = new FormData();

    for (const item of base64InputValue) {
      const blob = await fetch(item.base64).then((r) => r.blob());
      formData.append(item.type, blob);
    }

    //  info =============================================

    let profileInfoDto;

    if (user.super_admin) {
      profileInfoDto = {
        admin: editingProfile.profile.admin,
        corporate: editingProfile.profile.corporate,
        about_me: editingProfile.profile.about_me,
        profile_salute: editingProfile.profile.profile_salute,
        profile_first_name: editingProfile.profile.profile_first_name,
        profile_last_name: editingProfile.profile.profile_last_name,
        profile_email: editingProfile.profile.profile_email,
        profile_type: editingProfile.profile.profile_type,
        phone_location: editingProfile.profile.phone_location
          ? editingProfile.profile.phone_location.id
          : 95,
        phone_number: editingProfile.profile.phone_number,
        company_name: editingProfile.profile.company_name,
        profile_title: editingProfile.profile.profile_title,
        profile_location: editingProfile.profile.profile_location,
        public: editingProfile.profile.public,
        prefer_exchange: editingProfile.profile.prefer_exchange,
        allow_listing: editingProfile.profile.allow_listing,
        contact_message: editingProfile.profile.contact_message,
        phone_public: editingProfile.profile.phone_public,
        email_public: editingProfile.profile.email_public,
        info_public: editingProfile.profile.info_public,
        location_public: editingProfile.profile.location_public,
        username: usernameTemp, // To avoid the onblur set state function haven't run before sending the info to backend
        user: editingProfile.profile.user
          ? editingProfile.profile.user.id
          : null,
      };
    } else {
      profileInfoDto = {
        corporate: editingProfile.profile.corporate,
        user: coporateFoundUserId,
        admin: editingProfile.profile.admin,
        about_me: editingProfile.profile.about_me,
        profile_salute: editingProfile.profile.profile_salute,
        profile_first_name: editingProfile.profile.profile_first_name,
        profile_last_name: editingProfile.profile.profile_last_name,
        profile_email: editingProfile.profile.profile_email,
        phone_location: editingProfile.profile.phone_location
          ? editingProfile.profile.phone_location.id
          : 95,
        phone_number: editingProfile.profile.phone_number,
        company_name: editingProfile.profile.company_name,
        profile_title: editingProfile.profile.profile_title,
        profile_location: editingProfile.profile.profile_location,
        public: editingProfile.profile.public,
        prefer_exchange: editingProfile.profile.prefer_exchange,
        allow_listing: editingProfile.profile.allow_listing,
        contact_message: editingProfile.profile.contact_message,
        phone_public: editingProfile.profile.phone_public,
        email_public: editingProfile.profile.email_public,
        info_public: editingProfile.profile.info_public,
        location_public: editingProfile.profile.location_public,
        username: usernameTemp, // To avoid the onblur set state function haven't run before sending the info to backend
      };
    }

    formData.append("profileInfo", JSON.stringify(profileInfoDto));

    const _patchMethod = await patchMethodwithFormData(formData);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/${encodeURI(
        String(editingProfile.profile.id)
      )}`,
      _patchMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      setEditingProfile(result.profile);
      window.history.replaceState(
        { key: window.history.state.key, usr: result.profile },
        ""
      );
      setError({ color: "green", message: "successully edited!" });
      setDisable(false);
    } else {
      setError({
        color: "red",
        message: result.message,
      });
      setDisable(false);
    }
  }
  return (
    <Tabs.Panel
      value={headName}
      pt="xs"
      style={{ padding: 10, marginBottom: 25 }}
    >
      
      {user.super_admin && (
        <div>
          {/* //============================================================================== */}
          {/* //profile admin   ============================================================== */}
          {/* //============================================================================== */}
          {editingProfile.profile.profile_type != profileType.corporate_main &&
            editingProfile.profile.profile_type != profileType.events && (
              <div style={{ paddingBlock: 3 }}>
                <Switch
                  label="profile corp admin"
                  onLabel="Yes"
                  offLabel="NO"
                  checked={
                    editingProfile.profile.corporate
                      ? editingProfile.profile.admin
                      : false
                  }
                  onChange={(event) => {
                    if (editingProfile.profile.corporate) {
                      setEditingProfile(
                        produce((draftState) => {
                          draftState.profile.admin =
                            event.currentTarget.checked;
                          if (event.currentTarget.checked) {
                            draftState.profile.profile_type = "corporate";
                          }
                        })
                      );
                    }
                  }}
                />
              </div>
            )}

          {/* //============================================================================== */}
          {/* //profile corporate============================================================== */}
          {/* //============================================================================== */}
          {editingProfile.profile.profile_type !=
            profileType.corporate_main && (
            <div>
              <NativeSelect
                data={corporatesListData}
                placeholder={"select corporate"}
                label="User corporate"
                value={
                  editingProfile.profile.corporate
                    ? editingProfile.profile.corporate.id
                    : 0
                }
                onChange={(event) => {
                  if (event.currentTarget.value === "0") {
                    setEditingProfile(
                      produce((draftState) => {
                        draftState.profile.admin = false;
                        draftState.profile.corporate = null;
                        draftState.profile.profile_type = "individual";
                      })
                    );
                  } else
                    setEditingProfile(
                      produce((draftState) => {
                        draftState.profile.corporate = {
                          id: parseInt(event.currentTarget.value),
                          corporate_name: "",
                        };
                        draftState.profile.profile_type = "corporate";
                      })
                    );
                }}
              />
            </div>
          )}

          {/* //============================================================================== */}
          {/* //profile user============================================================== */}
          {/* //============================================================================== */}
          {selectedUserList && (
            <div>
              <Select
                error={corporate_mainTypeError ? corporate_mainTypeError : ""}
                data={selectedUserList}
                placeholder={"select user"}
                label="User phone number"
                searchable
                value={userSelected}
                onChange={(value) => {
                  if (!value) return;
                  const isUser = (item: { id: number }) =>
                    item.id == parseInt(value);
                  let index = userList.findIndex(isUser);
                  let corporate_mainType =
                    editingProfile.profile.profile_type === "corporate_main";
                  if (value == "0" || corporate_mainType) {
                    if (corporate_mainType) {
                      setCorporate_mainTypeError(
                        "Corporate main profile can not have user"
                      );
                    }
                    setUserSelected(value);
                    setEditingProfile(
                      produce((draftState) => {
                        draftState.profile.user = null;
                      })
                    );
                  } else {
                    setUserSelected(value);
                    setEditingProfile(
                      produce((draftState) => {
                        draftState.profile.user = userList[index];
                      })
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
      )}

      {!user.super_admin &&
        editingProfile.profile.profile_type != profileType.corporate_main && (
          <div
            style={{
              border: "solid",
              padding: 15,
              borderRadius: 5,
              borderColor: "lightGrey",
            }}
          >
            <Alert>
              {`please enter the user phone number that you want to link to
             (leave it to blank if no user is linked to this profile)`}{" "}
              <br />
              <Text style={{ color: "blue" }}>{`Profile currently linked to : ${
                editingProfile.profile.user
                  ? editingProfile.profile.user?.phone_number
                  : "not linking to anyone"
              }`}</Text>
            </Alert>
            <Select
              data={phoneLocationsData}
              label="Phone Locations"
              placeholder="select Phone Locations"
              radius="xs"
              value={userPhoneLocationNumber}
              onChange={setUserPhoneLocationNumber}
              searchable
            />
            <NumberInput
              withAsterisk
              placeholder="phone number"
              label={`Phone Number`}
              value={userPhoneNumber}
              error={userPhoneNumberError}
              onChange={(value) => setUserPhoneNumber(value)}
            />
          </div>
        )}

      {/* //============================================================================== */}
      {/* //profile salute  ============================================================== */}
      {/* //============================================================================== */}

      <NativeSelect
        style={{ paddingBlock: 3 }}
        data={["Mr.", "Mrs.", "Ms.", "Miss"]}
        placeholder="profile salute"
        label="profile salute"
        value={editingProfile.profile.profile_salute}
        onChange={(event) =>
          setEditingProfile(
            produce((draftState) => {
              draftState.profile.profile_salute = event.currentTarget.value;
            })
          )
        }
      />
      {/* //============================================================================== */}
      {/* //profile first name============================================================== */}
      {/* //============================================================================== */}
      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile first name"
        label="profile first name"
        value={editingProfile.profile.profile_first_name}
        onChange={(event) =>
          setEditingProfile(
            produce((draftState) => {
              draftState.profile.profile_first_name = event.currentTarget.value;
            })
          )
        }
      />

      {/* //============================================================================== */}
      {/* //profile last name============================================================== */}
      {/* //============================================================================== */}

      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile last name"
        label="profile last name"
        value={editingProfile.profile.profile_last_name}
        onChange={(event) =>
          setEditingProfile(
            produce((draftState) => {
              draftState.profile.profile_last_name = event.currentTarget.value;
            })
          )
        }
      />
      {/* //============================================================================== */}
      {/* //profile title============================================================== */}
      {/* //============================================================================== */}

      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile title"
        label="profile title "
        value={editingProfile.profile.profile_title}
        onChange={(event) =>
          setEditingProfile(
            produce((draftState) => {
              draftState.profile.profile_title = event.currentTarget.value;
            })
          )
        }
      />
      {/* //============================================================================== */}
      {/* //profile company name============================================================== */}
      {/* //============================================================================== */}

      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile company name"
        label="profile company name"
        value={editingProfile.profile.company_name}
        onChange={(event) =>
          setEditingProfile(
            produce((draftState) => {
              draftState.profile.company_name = event.currentTarget.value;
            })
          )
        }
      />
      {/* //============================================================================== */}
      {/* //profile about me============================================================== */}
      {/* //============================================================================== */}

      <>
        <Textarea
          style={{ paddingBlock: 3 }}
          placeholder="profile about me"
          label="profile about me"
          value={
            editingProfile.profile.about_me
              ? editingProfile.profile.about_me
              : ""
          }
          maxLength={2600}
          onChange={(event) =>
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.about_me = event.currentTarget.value;
              })
            )
          }
        />
        <div>
          {editingProfile.profile.about_me?.length
            ? editingProfile.profile.about_me.length
            : 0}{" "}
          / 2600 characters
        </div>
      </>
      {/* //============================================================================== */}
      {/* //profile email============================================================== */}
      {/* //============================================================================== */}

      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile email"
        label="profile email"
        value={emailTemp}
        error={emailError ? emailError : ""}
        onChange={(event) => {
          const value = event.currentTarget.value.trim();
          if (value) {
            setEmailTemp(value);
          } else {
            setEmailTemp("");
          }
          if (emailValidation(value)) {
            setEmailError("");
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.profile_email = value;
              })
            );
          } else {
            setEmailError("Invalid email");
          }
        }}
      />
      {/* //============================================================================== */}
      {/* //Phone location============================================================== */}
      {/* //============================================================================== */}

      <NativeSelect
        style={{ paddingBlock: 3 }}
        data={phoneLocationsData}
        placeholder={"Phone location"}
        label={"Phone location"}
        value={
          editingProfile.profile.phone_location
            ? editingProfile.profile.phone_location.id
            : "95"
        }
        onChange={(event) => {
          setEditingProfile(
            produce((draftState) => {
              if (
                phoneLocations.find(
                  (item) => item.id == parseInt(event.currentTarget.value)
                )
              ) {
                draftState.profile.phone_location = phoneLocations.find(
                  (item) => item.id == parseInt(event.currentTarget.value)
                )!;
              }
            })
          );
        }}
      />
      {/* //============================================================================== */}
      {/* //profile phone============================================================== */}
      {/* //============================================================================== */}

      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile phone"
        label="profile phone"
        value={phoneNumberTemp}
        error={phoneError ? phoneError : ""}
        onChange={(event) => {
          const value = event.currentTarget.value.trim();

          if (value) {
            setPhoneNumberTemp(value);
          } else {
            setPhoneNumberTemp("");
          }

          if (phoneValidation(value)) {
            setPhoneError("");
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.phone_number = value;
              })
            );
          } else {
            setPhoneError("Invalid phone");
          }
        }}
      />

      {/* //============================================================================== */}
      {/* //profile username============================================================== */}
      {/* //============================================================================== */}

      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile username"
        label="profile username"
        value={usernameTemp}
        error={usernameError ? usernameError : ""}
        onChange={(event) => {
          const username = event.currentTarget.value.toLowerCase().trim();
          const isMatched = username.match(usernameRegex);

          if (isMatched) {
            setUsernameTemp(username);
            setUsernameError("");
          } else if (!username) {
            setUsernameTemp(username);
            setUsernameError("username should not be empty");
          } else {
            setUsernameTemp(username);
            setUsernameError(
              "username should be in english, lowercase and have no other symbols and spaces"
            );
          }
        }}
        onBlurCapture={async (event) => {
          const value = event.currentTarget.value;
          await usernameCheck(value);
        }}
      />

      {/* //============================================================================== */}
      {/* //profile location============================================================== */}
      {/* //============================================================================== */}

      <TextInput
        style={{ paddingBlock: 3 }}
        placeholder="profile location"
        label="profile location"
        value={editingProfile.profile.profile_location}
        onChange={(event) =>
          setEditingProfile(
            produce((draftState) => {
              draftState.profile.profile_location = event.currentTarget.value;
            })
          )
        }
      />

      {/* //============================================================================== */}
      {/* //profile contact message============================================================== */}
      {/* //============================================================================== */}

      <>
        <Textarea
          style={{ paddingBlock: 3 }}
          placeholder="profile contact message"
          label="profile contact message"
          value={
            editingProfile.profile.contact_message
              ? editingProfile.profile.contact_message
              : ""
          }
          maxLength={500}
          onChange={(event) =>
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.contact_message = event.currentTarget.value;
              })
            )
          }
        />
        <div>
          {editingProfile.profile.contact_message?.length
            ? editingProfile.profile.contact_message.length
            : 0}{" "}
          / 500 characters
        </div>
      </>
      {/* //============================================================================== */}
      {/* //profile banner============================================================== */}
      {/* //============================================================================== */}
      {(editingProfile.profile.profile_type == profileType.corporate ||
        editingProfile.profile.profile_type == profileType.corporate_main) && (
        <div style={{ padding: 10, color: "rgb(255, 0, 255)" }}>
          *This section is applicable when executing the master update action
          for the Corp. Main Profile.
        </div>
      )}
      <div style={{ paddingBlock: 3 }}>
        <ImageViewAndEdit
          src={
            editingProfile.profile.banner_url
              ? editingProfile.profile.banner_url
              : null
          }
          type={"banner"}
          title={"profile banner"}
          onRemove={() => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.banner_url = state.profile.banner_url;
              })
            );

            setFileInputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_banner"),
                  1
                );
              })
            );
            setBase64InputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_banner"),
                  1
                );
              })
            );
          }}
          imageBase64={function base64(base64: string): void {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.banner_url = base64;
              })
            );

            const index = base64InputValue.findIndex(
              (item) => item.type === "profile_banner"
            );
            if (index != -1) {
              setBase64InputValue(
                produce((draftState) => {
                  draftState[index].base64 = base64;
                })
              );
            } else {
              setBase64InputValue(
                produce((draftState) => {
                  draftState.push({ type: "profile_banner", base64: base64 });
                })
              );
            }
          }}
          imageFile={function imageFile(imageFile: File): void {
            const index = fileInputValue.findIndex(
              (item) => item.type === "profile_banner"
            );
            if (index != -1) {
              setFileInputValue(
                produce((draftState) => {
                  draftState[index].file = imageFile;
                })
              );
            } else {
              setFileInputValue(
                produce((draftState) => {
                  draftState.push({ type: "profile_banner", file: imageFile });
                })
              );
            }
          }}
        />
      </div>
      {/* //============================================================================== */}
      {/* //profile icon============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <ImageViewAndEdit
          src={editingProfile.profile?.icon_url}
          type={"icon"}
          title={"profile icon"}
          onRemove={() => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.icon_url = state.profile.icon_url;
              })
            );
            setFileInputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_icon"),
                  1
                );
              })
            );

            setBase64InputValue(
              produce((draftState) => {
                draftState.splice(
                  draftState.map((item) => item.type).indexOf("profile_icon"),
                  1
                );
              })
            );
          }}
          imageBase64={function (base64: string): void {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.icon_url = base64;
              })
            );

            const index = base64InputValue.findIndex(
              (item) => item.type === "profile_icon"
            );
            if (index != -1) {
              setBase64InputValue(
                produce((draftState) => {
                  draftState[index].base64 = base64;
                })
              );
            } else {
              setBase64InputValue(
                produce((draftState) => {
                  draftState.push({ type: "profile_icon", base64: base64 });
                })
              );
            }
          }}
          imageFile={function (imageFile: File): void {
            setFileInputValue(
              produce((draftState) => {
                draftState.push({ type: "profile_icon", file: imageFile });
              })
            );
          }}
        />
      </div>
      {/* //============================================================================== */}
      {/* //profile public============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <Switch
          label="profile public"
          onLabel="YES"
          offLabel="NO"
          checked={editingProfile.profile.public}
          onChange={(event) => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.public = event.currentTarget.checked;
              })
            );
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //profile prefer exchange============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <Switch
          label="profile prefer exchange"
          onLabel="YES"
          offLabel="NO"
          checked={editingProfile.profile.prefer_exchange}
          onChange={(event) => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.prefer_exchange =
                  event.currentTarget.checked;
              })
            );
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //profile allow listing============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <Switch
          label="profile allow listing"
          onLabel="YES"
          offLabel="NO"
          checked={editingProfile.profile.allow_listing}
          onChange={(event) => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.allow_listing = event.currentTarget.checked;
              })
            );
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //profile phone public============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <Switch
          label="profile phone public"
          onLabel="YES"
          offLabel="NO"
          checked={editingProfile.profile.phone_public}
          onChange={(event) => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.phone_public = event.currentTarget.checked;
              })
            );
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //profile email public============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <Switch
          label="profile email public"
          onLabel="YES"
          offLabel="NO"
          checked={editingProfile.profile.email_public}
          onChange={(event) => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.email_public = event.currentTarget.checked;
              })
            );
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //profile info public============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <Switch
          label="profile info public"
          onLabel="YES"
          offLabel="NO"
          checked={editingProfile.profile.info_public}
          onChange={(event) => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.info_public = event.currentTarget.checked;
              })
            );
          }}
        />
      </div>

      {/* //============================================================================== */}
      {/* //profile location public============================================================== */}
      {/* //============================================================================== */}
      <div style={{ paddingBlock: 3 }}>
        <Switch
          label="profile location public"
          onLabel="YES"
          offLabel="NO"
          checked={editingProfile.profile.location_public}
          onChange={(event) => {
            setEditingProfile(
              produce((draftState) => {
                draftState.profile.location_public =
                  event.currentTarget.checked;
              })
            );
          }}
        />
      </div>
      {error.message != "" && (
        <Alert title="!!!!!" color={error.color}>
          {error.message}
        </Alert>
      )}

      <div
        style={{ paddingBlock: 3, display: "flex", justifyContent: "flex-end" }}
      >
        <EditButton
          name={"Save profile info"}
          onEdit={save}
          disable={disable}
          style={{ marginRight: 10 }}
        />
      </div>
    </Tabs.Panel>
  );
}
