import React from "react";

interface Props {
  username: string;
  profileIcon: string;
  firstName: string;
  Lastname: string;
  corporate: number | null;
}

export default function RepresentativeCard(props: Props) {
  return (
    <div
      style={{
        borderWidth: 0.5,
        borderColor: "grey",
        border: "solid",
        padding: 5,
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: 'center'
      }}
    >
      <div>
        <img style={{ width: 100, height: 100 }} src={props.profileIcon} alt=""/>
      </div>
      <div style={{ paddingLeft: 10 }}>
        <p>{`username: ${props.username}`}</p>
        <p>{`name: ${props.firstName + " " + props.Lastname}`}</p>
        <p>{`corporate: ${props.corporate}`}</p>
      </div>
    </div>
  );
}
