import { AppShell, ScrollArea } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import SideBar from "../Bars/SideBar";
import TopBar from "../Bars/TopBar";
import {
  allUserState,
  corpListState,
  corporatesListDataState,
  interestSubTagsListDataState,
  interestSubTagsState,
  interestTagsListDataState,
  interestTagsState,
  isLoadingAllUserState,
  jobSubTagsListDataState,
  jobSubTagsState,
  jobTagsListDataState,
  jobTagsState,
  phoneLocationsDataState,
  phoneLocationsState,
  selectUserListState,
  userState,
} from "../recoil_state";
import { getMethod } from "../utilities/fetchMethod";
import {
  InterestsSubTagsReference,
  InterestsTagsReference,
  jobSubTagsReference,
  jobTagsReference,
} from "../utilities/models";
import AnalysisDashboard from "./Dashboards/AnalysisDashboard";
import CorporatesDashboard from "./Dashboards/CorporatesDashboard";
import DeviceDashboard from "./Dashboards/DeviceDashboard";
import EventPreview from "./Dashboards/EventPreview";
import EventsDashboard from "./Dashboards/EventsDashboard";
import FutureCorpAdmin from "./Dashboards/FutureCorpAdmin";
import FutureDirectory from "./Dashboards/FutureDirectory";
import FutureEventDashboard from "./Dashboards/FutureEvent";
import FutureEventAnalytics from "./Dashboards/FutureEventAnalytics";
import HomeDashboard from "./Dashboards/HomeDashboard";
import Preview from "./Dashboards/Peview";
import ProfileDashboard from "./Dashboards/ProfileDashboard";
import StaffDashboard from "./Dashboards/StaffDashboard";
import TagsDashboard from "./Dashboards/TagsDashboard";
import UserDashboard from "./Dashboards/UserDashboard";
import TempPhoneNumber from "./Dashboards/TempPhoneNumber";

export default function Dashboard() {
  //-------------------------------------------------------------------------------------------
  // hook
  //-------------------------------------------------------------------------------------------
  const location = useLocation();
  const [pathname, setPathname] = useState("");
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [, setUserList] = useRecoilState(allUserState);
  const [, setLoadingAllUser] = useRecoilState(isLoadingAllUserState);
  const [corpList, setCorpList] = useRecoilState(corpListState);

  const setPhoneLocations = useSetRecoilState(phoneLocationsState);
  const setPhoneLocationsData = useSetRecoilState(phoneLocationsDataState);

  const setCorporatesListData = useSetRecoilState(corporatesListDataState);

  const setJobTags = useSetRecoilState(jobTagsState);
  const setJobSubTags = useSetRecoilState(jobSubTagsState);

  const setJobTagsListData = useSetRecoilState(jobTagsListDataState);
  const setJobSubTagsListData = useSetRecoilState(jobSubTagsListDataState);

  const setinterestTags = useSetRecoilState(interestTagsState);
  const setinterestSubTags = useSetRecoilState(interestSubTagsState);

  const setinterestTagsListData = useSetRecoilState(interestTagsListDataState);
  const setinterestSubTagsListData = useSetRecoilState(
    interestSubTagsListDataState
  );

  const [selectUserList, setSelectUserList] =
    useRecoilState(selectUserListState);
  //-------------------------------------------------------------------------------------------
  // function fetch data
  //-------------------------------------------------------------------------------------------
  // if super admin get all corp info
  const fetchCorpList = async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/corporates/list`,
      _getMethod
    );
    const result = await res.json();

    let data: { value: string; label: string }[] = [
      { value: "0", label: " Not a Company" },
    ];
    for (const corporate of result.corpList) {
      let label = corporate.corporate_name as string;
      let value = String(corporate.id);
      data.push({ value: value, label: label });
    }
    setCorporatesListData(data);
    setCorpList(result.corpList);
  };

  const fetchAllUser = async () => {
    setLoadingAllUser("fetching");
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user`,
      _getMethod
    );
    const result = await res.json();
    setUserList(result);

    let list: { value: string; label: string }[] = [
      { value: "0", label: " No User" },
    ];
    for (const user of result) {
      if (user.active) {
        const item = { value: user.id.toString(), label: user.phone_number };
        list.push(item);
      }
    }
    setSelectUserList(list);

    setLoadingAllUser("fetched");
  };
  //-------------------------------------------------------------------------------------------
  // function fetch Tags Data
  //-------------------------------------------------------------------------------------------

  //  Phone Locations ==========================================================================

  const fetchPhoneLocations = async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/tags/findPhoneLocationsTags`,
      _getMethod
    );
    const result: {
      active: boolean;
      created_at: string;
      delete_time: null;
      id: number;
      location: string;
      location_number: string;
      updated_at: string;
    }[] = await res.json();
    let data: { value: string; label: string }[] = [];
    for (const location of result) {
      let label = location.location + " : " + location.location_number;
      let value = location.id.toString();
      data.push({ value: value, label: label });
    }
    setPhoneLocations(result);
    setPhoneLocationsData(data);
  };

  //  JobTags And JobSubTags  ==========================================================================

  const fetchJobTagsAndJobSubTags = async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/tags/findJobTagsAndJobSubTags`,
      _getMethod
    );
    const result: {
      JobSubtags: jobSubTagsReference[];
      JobTags: jobTagsReference[];
    } = await res.json();
    let JobTagsListData: { value: string; label: string }[] = [];
    for (const JobTags of result.JobTags) {
      let label = JobTags.job_tag;
      let value = JobTags.id.toString();
      JobTagsListData.push({ value: value, label: label });
    }

    let JobSubTagsListData: { value: string; label: string; group: string }[] =
      [];
    for (const JobSubtags of result.JobSubtags) {
      let label = JobSubtags.job_subtag;
      let value = JobSubtags.id.toString();
      let group = JobSubtags.jobtag.job_tag;
      JobSubTagsListData.push({ value: value, label: label, group: group });
    }
    setJobTags(result.JobTags);
    setJobSubTags(result.JobSubtags);
    setJobTagsListData(JobTagsListData);
    setJobSubTagsListData(JobSubTagsListData);
  };

  //  JobTags And JobSubTags  ==========================================================================

  const fetchInterestTagsAndInterestSubTags = async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/tags/findInterestTagsAndInterestSubTags`,
      _getMethod
    );
    const result: {
      interestSubTags: InterestsSubTagsReference[];
      interestTags: InterestsTagsReference[];
    } = await res.json();
    let interestTagsListData: { value: string; label: string }[] = [];
    for (const interestTags of result.interestTags) {
      let label = interestTags.interest;
      let value = interestTags.id.toString();
      interestTagsListData.push({ value: value, label: label });
    }

    let interestSubTagsListData: {
      value: string;
      label: string;
      group: string;
    }[] = [];
    for (const interestSubTags of result.interestSubTags) {
      let label = interestSubTags.interest_subtag;
      let value = interestSubTags.id.toString();
      let group = interestSubTags.interest_tag.interest;
      interestSubTagsListData.push({
        value: value,
        label: label,
        group: group,
      });
    }
    setinterestTags(result.interestTags);
    setinterestSubTags(result.interestSubTags);
    setinterestTagsListData(interestTagsListData);
    setinterestSubTagsListData(interestSubTagsListData);
  }; 

  //-------------------------------------------------------------------------------------------
  // use Effect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    //if get the logout path

    if (location.pathname === "/dashboard/logout") {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      navigate("/");
    }
    // if is not logout, set pathname and change the component
    setPathname(location.pathname);
  }, [location, navigate]);

  //-------------------------------------------------------------------------------------------
  // super admin get all user
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (user.super_admin) {
      fetchAllUser();
    }
    fetchCorpList();
    fetchPhoneLocations();
    fetchJobTagsAndJobSubTags();
    fetchInterestTagsAndInterestSubTags();
  }, [user]);

  //-------------------------------------------------------------------------------------------
  // component
  //-------------------------------------------------------------------------------------------

  return (
    <AppShell
      padding="md"
      navbar={<SideBar superAdmin={user.super_admin} />}
      header={<TopBar superAdmin={user.super_admin} />}
      styles={(theme) => ({
        main: {
          backgroundColor: "white",
          height: "110%",
        },
      })}
    >
      {pathname === "/dashboard/home" && <HomeDashboard />}
      {user.super_admin && pathname === "/dashboard/corp" && (
        <CorporatesDashboard />
      )}
      {user.super_admin && pathname === "/dashboard/staff" && (
        <StaffDashboard />
      )}
      {user.super_admin && pathname === "/dashboard/user" && <UserDashboard />}
      {user.super_admin && pathname === "/dashboard/tags" && <TagsDashboard />}
      {user.super_admin && pathname === "/dashboard/tempPhoneNumber" && (
        <TempPhoneNumber />
      )}
      {user.super_admin && pathname === "/dashboard/futureEvent" && (
        <FutureEventDashboard />
      )}
      {user.super_admin && pathname === "/dashboard/futureEventAnalytics" && (
        <FutureEventAnalytics />
      )}
      {user.super_admin && pathname === "/dashboard/futureCorpAdmin" && (
        <FutureCorpAdmin />
      )}
      {user.super_admin && pathname === "/dashboard/futureDirectory" && (
        <FutureDirectory />
      )}
      {pathname === "/dashboard/profile" && <ProfileDashboard />}
      {pathname === "/dashboard/events" && <EventsDashboard />}
      {pathname === "/dashboard/device" && <DeviceDashboard />}
      {pathname === "/dashboard/preview" && <Preview />}
      {pathname === "/dashboard/eventpreview" && <EventPreview />}
      {pathname === "/dashboard/analysis" && <AnalysisDashboard />}
    </AppShell>
  );
}
