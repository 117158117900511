import React, { useState } from "react";
import { TextInput, Button, Group, Box, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { LoginDto } from "../utilities/models";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { corpListState, userState } from "../recoil_state";

export default function Login() {
  //-------------------------------------------------------------------------------------------
  // usestate
  //-------------------------------------------------------------------------------------------

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const [corpList, setCorpList] = useRecoilState(corpListState);
  //-------------------------------------------------------------------------------------------
  // form
  //-------------------------------------------------------------------------------------------

  const form = useForm({
    initialValues: {
      phone_number: "",
      password: "",
    },

    validate: {
      phone_number: (value) =>
        !isNaN(parseInt(value))
          ? null
          : "Invalid Phone Number",
      password: (value) =>
        value.length > 0 ? null : "Please enter your password",
    },
  });

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //superlogin
  async function login(dto: LoginDto) {
    setError("");
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/adminlogin`,
      {
        method: "POST",
        body: JSON.stringify(dto),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await res.json();
    if (!res.ok) {
      setError("incorrect phone number or password");
    } else {
      localStorage.setItem("token", result.token);
      localStorage.setItem("id", result.user.id);
      setCorpList(result.corpList);
      setUser(result.user);

      if(result.user.super_admin){
        return navigate("/dashboard/profile", {state: "superAdmin"});
      } else if (result.corpList && result.corpList.length > 0){
        return navigate("/dashboard/profile", {state: result.corpList[0].id})
      } else {
        return navigate("/dashboard/home")
      }
      
    }
  }

  //-------------------------------------------------------------------------------------------
  // component
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
      <Box sx={{ maxWidth: 300 }} mx="auto" style={{paddingTop: 100}}>
        <img src={`${process.env.REACT_APP_FRONTEND_URL}/looplogo.png`} style={{height: 300, width: 300, borderRadius: 30}}/>
        <form onSubmit={form.onSubmit((dto) => login(dto))}>
          <TextInput
            withAsterisk
            label="Phone Number"
            placeholder="eg. 12345678"
            style={{paddingTop: 30}}
            {...form.getInputProps("phone_number")}
          />

          <PasswordInput
            withAsterisk
            label="Password"
            placeholder="Please enter your password"
            {...form.getInputProps("password")}
          />
          <div style={{color: 'red'}}>{error}</div>
          <Group mt="md">
            <Button style={{width: '100%'}} type="submit">Login</Button>
          </Group>
        </form>
      </Box>
    </>
  );
}
