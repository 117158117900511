import {
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { postMethod } from "../../utilities/fetchMethod";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  onsetIds: (data: object[]| null) => void
}
export default function CreateDeviceModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [number, setNumber] = useState(0)
  const [modal, setModal] = useState("")

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //create users
  async function createUUID() {
    if(!number || !modal){
      setError("Please fill in the form")
      return
    }
    const _postMethod = await postMethod({modal: modal});
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/device/uuid/${encodeURI(String(number))}`,
      _postMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      props.onsetIds(result.data)
      props.onRefresh();
      props.onClose();
    } else {
      setError(result.error);
    }
  }

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Create uuids`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          <div style={{ color: "red", fontSize: 15 }}> {error} </div>
            <NumberInput
              placeholder={"number"}
              label={"How many uuids you want to generate?"}
              withAsterisk
              value={number}
              min={0}
              onChange={(value) => setNumber(value as number)}
            />
             <TextInput
              placeholder={"modal"}
              label={"Please Input the modal"}
              withAsterisk
              value={modal}
              onChange={(event) => setModal(event.currentTarget.value)}
              style={{marginTop: 10}}
            />
            <Group position="right" mt="md">
              <Button onClick={() => createUUID()}>Submit</Button>
            </Group>
        </Box>
      </Modal>
    </>
  );
}
