import React, { useState } from "react";
import Avatar from "../../images/44-avatar-user-in-circle.svg";
import { pushProfile } from "../../Pages/analysisPages/AnalysisHomePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mantine/core";
interface Props {
  title: string;
  number: number;
}

export default function AnalysisNumber(props: Props) {
  return (
    <>
      <div
        style={{
          // width: 605,
          backgroundColor: "white",
          paddingBlock: 20,
          paddingInline: 35,
          borderRadius: 15,
          boxShadow: "0px 3px 9px lightGrey",
          overflow: "hidden",
          margin: 20,
        }}
      >
        <div style={{ fontSize: 15 }}>{props.title}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 30,
            paddingTop: 15,
          }}
        >
          {props.number}
        </div>
      </div>
    </>
  );
}
