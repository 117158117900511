import React, { memo, useCallback, useEffect, useState } from "react";
import {
  deleteMethod,
  deleteMethodWithBody,
  getMethod,
} from "../../utilities/fetchMethod";
import {
  ProfileInfoProps,
  ProfilesInfo,
  profileType,
} from "../../utilities/models";
import EditButton from "../../Components/Buttons/EditButton";
import { Button, Checkbox, Group, LoadingOverlay, Table } from "@mantine/core";
import SearchBar from "../../Bars/SearchBar";
import CreateEventsModalBox from "../../Components/ModalBox/CreateEventsModalBox";
import { Link, useLocation } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { editingProfileState } from "../../recoil_state";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import DisableButton from "../../Components/Buttons/DisableButton";
import InputCSV from "../../Components/ModalBox/InputCSV";
import SendEmailToProfile from "../../Components/ModalBox/SendEmailToProfile";

export default function EventsDashboard() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [eventList, setEventList] = useState<ProfilesInfo[]>();
  const [currentEventList, setCurrentEventList] = useState<ProfilesInfo[]>();
  const [openedCreate, setOpenedCreate] = useState(false);
  const [profileList, setProfileList] =
    useState<{ value: string; label: string }[]>();
  const [error] = useState("");
  const resetProfileState = useResetRecoilState(editingProfileState);
  const [loading, setLoading] = useState(true);
  const [inputCsvVisible, setInputCsvVisible] = useState(false);
  const [sendEmailToProfile, setSendemailToProfile] = useState(false);
  let { state } = useLocation();
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //fetch all users
  const fetchAll = useCallback(async () => {
    setLoading(true);
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/events/all`,
      _getMethod
    );
    const result = await res.json();
    setEventList(result);
    setCurrentEventList(result.filter((item: any) => item.profile.active));
    setLoading(false);
  }, [setEventList, setCurrentEventList]);

  //search function
  async function search(text: string) {
    const filter = eventList!.filter((item) =>
      item.profile.phone_number.includes(text)
    );
    if (filter.length === 0) {
    } else {
      setCurrentEventList(filter);
    }
  }

  //build list
  const buildProfileList = useCallback(
    (result: any) => {
      let data: { value: string; label: string }[] = [];
      for (const profile of result) {
        if (profile.active) {
          let label = profile.username;
          let value = profile.id.toString();
          data.push({ value: value, label: label });
        }
      }
      setProfileList(data);
    },
    [setProfileList]
  );

  //fetch all profile
  const fetchAllProfile = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/forselect/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const result = await res.json();
    buildProfileList(
      result.filter(
        (item: any) =>
          item.active === true && item.profile_type == profileType.individual
      )
    );
  }, [buildProfileList]);

  //remove search function
  async function removeSearchText() {
    setCurrentEventList(eventList!);
  }

  //remove event
  async function removeEvent(id: number) {
    const _deleteMethod = await deleteMethodWithBody([id]);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile`,
      _deleteMethod
    );
    fetchAll();
  }

  //refresh
  async function refresh() {
    fetchAll();
  }
  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchAll();
    fetchAllProfile();
  }, [fetchAll, fetchAllProfile]);

  //-------------------------------------------------------------------------------------------
  // setting rows & headers
  //-------------------------------------------------------------------------------------------

  //rows
  const Rows = memo((props: ProfileInfoProps) => {
    if (!props.profileInfo.profile) {
      return <tr></tr>;
    }
    const profileInfo = props.profileInfo;
    const array = Object.entries(profileInfo.profile);

    return (
      <tr key={profileInfo.profile.id}>
        <td>
          {profileInfo.profile.active ? (
            <DeleteButton
              onRemove={() => removeEvent(profileInfo.profile.id)}
            />
          ) : (
            <DisableButton name={"Delete"} color={"grey"} />
          )}
        </td>
        <td>
          {profileInfo.profile.active ? (
            <Link
              style={{ textDecoration: "none" }}
              to={"/dashboard/eventpreview"}
              state={profileInfo}
              onClick={() => resetProfileState}
            >
              <EditButton onEdit={() => {}} />
            </Link>
          ) : (
            <DisableButton name={"Edit"} color={"grey"} />
          )}
        </td>
        {array.map(([key, value]) => (
          <td key={`${profileInfo.profile.id}-${key}-${value}`}>
            {value === true || value === false
              ? JSON.stringify(value)
              : typeof value === "string" && value.length > 10
              ? value.slice(0, 10) + "..."
              : Array.isArray(value)
              ? value.length
              : `${value}`}
          </td>
        ))}
      </tr>
    );
  });

  //headers
  const Headers = memo((props: ProfileInfoProps) => {
    if (!props.profileInfo.profile) {
      return <tr></tr>;
    }
    const array = Object.keys(props.profileInfo.profile);
    return (
      <tr>
        <th>Delete</th>
        <th>Edit</th>
        {array.map((keys) => (
          <th key={keys}>{keys}</th>
        ))}
      </tr>
    );
  });

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
          style={{ position: "fixed" }}
        />
        <div style={{ fontSize: 25, fontWeight: "800" }}>EventsDashboard</div>
        {eventList && (
          <SearchBar
            onSearch={(text) => search(text)}
            onRemove={() => removeSearchText()}
          />
        )}

        {inputCsvVisible && eventList && (
          <InputCSV
            onClose={() => setInputCsvVisible(false)}
            onRefresh={() => {}}
            eventList={eventList}
          />
        )}

        {sendEmailToProfile && eventList && (
          <SendEmailToProfile
            onClose={() => setSendemailToProfile(false)}
            onRefresh={() => {}}
            eventList={eventList}
          />
        )}
      </div>
      <div>{error}</div>
      <Group>
        <Button color={"cyan"} onClick={() => setOpenedCreate(true)}>
          Create
        </Button>
        <Button color={"green"} onClick={() => setInputCsvVisible(true)}>
          {"Input CSV to create user by event"}
        </Button>
        <a href="/loopCreate.csv" download>
          <Button color={"yellow"}>{"Download CSV template"}</Button>
        </a>
        <Button color={"blue"} onClick={() => setSendemailToProfile(true)}>
          {"Send email to profile"}
        </Button>
      </Group>
      {openedCreate && profileList && (
        <CreateEventsModalBox
          onClose={() => setOpenedCreate(false)}
          onRefresh={() => refresh()}
          buildedProfileList={profileList}
        />
      )}
      {eventList && eventList.length > 0 && (
        <div style={{ overflow: "auto" }}>
          <Table>
            <thead>
              <Headers profileInfo={eventList[0]} />
            </thead>
            {currentEventList && (
              <tbody>
                {currentEventList.map((item) => (
                  <Rows key={item.profile.id} profileInfo={item} />
                ))}
              </tbody>
            )}
          </Table>
        </div>
      )}
    </>
  );
}
