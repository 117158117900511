import {
  Box,
  Button,
  Group,
  Modal,
  NativeSelect,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { postMethod } from "../../utilities/fetchMethod";

interface Props {
  createTagTable: string;
  createTagTableItems: string[];
  allTags: any;
  onClose: () => void;
  onRefresh: () => void;
}
export default function CreateTagModalBox(props: Props) {
  let createTagTable = props.createTagTable;
  let createTagTableItems = props.createTagTableItems;
  const [error, setError] = useState("");
  let submitValue: any = undefined || {};

  console.log(error);

  let jobtagData: { value: string; label: string }[] = [
    { value: "--", label: "--" },
  ];
  props.allTags.JobTags.map((jobTags: []) => {
    jobTags.map((JobTag: { id: string; job_tag: string }) => {
      jobtagData.push({ value: JobTag.id, label: JobTag.job_tag });
      return 1;
    });
    return 2;
  });

  let interestsData: { value: string; label: string }[] = [
    { value: "--", label: "--" },
  ];
  props.allTags.InterestsTags.map((interestsTags: []) => {
    interestsTags.map((Interests: { id: string; interest: string }) => {
      interestsData.push({ value: Interests.id, label: Interests.interest });
      return 1;
    });

    return 2;
  });

  let itemHead = createTagTableItems.filter(
    (item) =>
      item !== "id" &&
      item !== "created_at" &&
      item !== "updated_at" &&
      item !== "delete_time" &&
      item !== "active"
  );

  async function submitCreate(value: object) {
    let valueheadArray = Object.keys(value);

    if (valueheadArray.length > 0) {
      let submitValue = { table: createTagTable };
      submitValue = {
        ...submitValue,
        ...value,
      };

      const _postMethod = await postMethod(submitValue);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/tags/create`,
        _postMethod
      );
      const result = await res.json();

      if (result.message === "success") {
        props.onClose();
        props.onRefresh();
      } else if (result.error) {
        setError(result.error);
      } else {
        setError("Please try again");
      }
    }
  }

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.1}
        overlayBlur={3}
        overflow="inside"
        title={`Edit table name : ${createTagTable}`}
        key={`${createTagTable}`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {itemHead.map((items, index) => {
            if (items === "jobtag") {
              return (
                <NativeSelect
                  withAsterisk
                  data={jobtagData}
                  key={index}
                  // error={selectError ? "Please pick one item" : false}
                  onChange={(e) => {
                    if (e.currentTarget.value !== "--") {
                      submitValue[items] = e.currentTarget.value;
                    }

                    // console.log(submitValue);
                    // setSelectError(false);
                  }}
                  label="Select job tag"
                />
              );
            } else if (items === "interest_tag") {
              return (
                <NativeSelect
                  withAsterisk
                  defaultValue={"--"}
                  data={interestsData}
                  // error={selectError ? "Please pick one item" : false}
                  onChange={(e) => {
                    if (e.currentTarget.value !== "--") {
                      submitValue[items] = e.currentTarget.value;
                    }
                    // setSelectError(false);
                    // console.log(submitValue);
                  }}
                  label="Select interest tag"
                />
              );
            } else {
              return (
                <TextInput
                  withAsterisk
                  label={items}
                  key={`${items}`}
                  onChange={(e) => {
                    submitValue[items] = e.target.value;
                  }}
                />
              );
            }
          })}
          <Group position="right" mt="md">
            <Button
              type="submit"
              onClick={() => {
                submitCreate(submitValue);
              }}
            >
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
