import React, { useEffect, useState } from "react";
import { deleteMethodWithBody, getMethod } from "../../utilities/fetchMethod";
import {
  initialUserinfo,
  UserInfo,
  UserInfoProps,
} from "../../utilities/models";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import EditButton from "../../Components/Buttons/EditButton";
import { Button, Checkbox, Group, Table } from "@mantine/core";
import ActivateButton from "../../Components/Buttons/ActiviateButton";
import EditUserModalBox from "../../Components/ModalBox/EditUserModalBox";
import CreateUserModalBox from "../../Components/ModalBox/CreateUserModalBox";
import { useRecoilState } from "recoil";
import { allUserState, isLoadingAllUserState } from "../../recoil_state";
import SearchBar from "../../Bars/SearchBar";
import DisableButton from "../../Components/Buttons/DisableButton";
import DeleteUserModalBox from "../../Components/ModalBox/DeleteModalBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import UserDashboardFilterBar from "../../Bars/UserDashboardFilterBar";
import produce from "immer";
export default function UserDashboard() {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [userList, setUserList] = useRecoilState(allUserState);
  const [currentUserList, setCurrentUserList] = useState([initialUserinfo]);
  const [openedEdit, setOpenedEdit] = useState(false);
  const [openedCreate, setOpenedCreate] = useState(false);
  const [editInfo, setEditInfo] = useState(initialUserinfo);
  const [isLoadingAllUser, setLoadingAllUser] = useRecoilState(
    isLoadingAllUserState
  );
  const [openedDelete, setOpenedDelete] = useState(false);
  const [checked, setChecked] = useState([0]);
  const [error, setError] = useState("");

  const [openFilters, setOpenFilters] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [backupCurrentUserList, setBackupCurrentUserList] = useState<
    UserInfo[]
  >([initialUserinfo]);
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //fetch all users
  async function fetchAll() {
    setLoadingAllUser("fetching");
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user`,
      _getMethod
    );
    const result = await res.json();

    setUserList(result);
    setCurrentUserList(result.filter((item: UserInfo) => item.active === true));
    setLoadingAllUser("fetched");
  }

  //deactivate users
  async function disableItem(item: number[]) {
    if (item.length === 1 && item[0] === 0) {
      return;
    }

    const _deleteMethod = await deleteMethodWithBody(item);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user/disable/disable`,
      _deleteMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      fetchAll();
      setChecked([0]);
      setError("");
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("please try again");
    }
  }

  //activiate users
  async function activateItem(item: number[]) {
    const _deleteMethod = await deleteMethodWithBody(item);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/user/disable/activate`,
      _deleteMethod
    );
    const result = await res.json();
    if (result.message === "success") {
      setError("");
      fetchAll();
      setChecked([0]);
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("please try again");
    }
  }

  //refresh
  async function refresh() {
    fetchAll();
  }
  //-------------------------------------------------------------------------------------------
  // useEffects
  //-------------------------------------------------------------------------------------------
  // userList.filter((item: UserInfo) => item.active === true)
  useEffect(() => {
    if (isLoadingAllUser !== "notFetch") {
      const newUserList = produce(userList, (draftState) => {});
      setCurrentUserList(newUserList);
      setBackupCurrentUserList(newUserList);
    } else {
      fetchAll();
    }
  }, [isLoadingAllUser, userList]);

  //-------------------------------------------------------------------------------------------
  // setting rows & headers
  //-------------------------------------------------------------------------------------------

  //rows
  function Rows(props: UserInfoProps) {
    const userInfo = props.userInfo;
    const array = Object.entries(userInfo);
    if(!userInfo.active)
    return(<></>)
    return (
      <tr key={userInfo.id}>
        <td>
          {userInfo.active ? (
            <Checkbox
              checked={
                checked.findIndex((id) => id === userInfo.id) > -1
                  ? true
                  : false
              }
              onChange={(event) => {
                event.currentTarget.checked
                  ? setChecked([...checked, userInfo.id])
                  : setChecked(checked.filter((id) => id !== userInfo.id));
              }}
            />
          ) : (
            <Checkbox disabled />
          )}
        </td>
        <td>
          {userInfo.active ? (
            !userInfo.disable ? (
              <DeleteButton
                name={"disable"}
                onRemove={() => {
                  disableItem([0, userInfo.id]);
                }}
              />
            ) : (
              <ActivateButton
                onActive={() => {
                  activateItem([0, userInfo.id]);
                }}
              />
            )
          ) : (
            <DisableButton color={"grey"} name={"Disable"} />
          )}
        </td>
        <td>
          {userInfo.active ? (
            <EditButton
              onEdit={() => {
                setOpenedEdit(true);
                setEditInfo(userInfo);
              }}
            />
          ) : (
            <DisableButton color={"grey"} name={"Edit"} />
          )}
        </td>
        <td>
          {userInfo.active ? (
            <DeleteButton
              name={"Delete User"}
              onRemove={() => {
                setChecked([userInfo.id]);
                setOpenedDelete(true);
              }}
            />
          ) : (
            <DisableButton color={"grey"} name={"Deleted User"} />
          )}
        </td>
        {array.map(([key, value]) => {
          // if (key == "phone_location") {
          //   console.log(value);
          // }

          return (
            <td key={`${userInfo.id}-${key}-${value}`}>
              {value === true || value === false
                ? `${value}`
                : key == "phone_location"
                ? value.location_number
                : value}
            </td>
          );
          // }
        })}
      </tr>
    );
  }

  //headers
  function Headers(props: UserInfoProps) {
    const array = Object.keys(props.userInfo);
    return (
      <tr>
        <th>
          <Checkbox
            checked={
              checked.filter((id) => id !== 0).length === userList.length
            }
            onChange={(event) => {
              event.currentTarget.checked
                ? setChecked(userList.map((item) => item.id))
                : setChecked([0]);
            }}
          />
        </th>
        <th>Disable</th>
        <th>Edit</th>
        <th>Delete User</th>
        {array.map((keys) => (
          <th key={keys}>{keys}</th>
        ))}
      </tr>
    );
  }

  //-------------------------------------------------------------------------------------------
  // components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <div style={{ fontSize: 25, fontWeight: "800" }}>UserDashboard</div>
        <SearchBar
          onSearch={setSearchText}
          onRemove={() => {
            setSearchText("");
          }}
        />
      </div>
      <div>{error}</div>
      <Group>
        <Button color={"cyan"} onClick={() => setOpenedCreate(true)}>
          Create User
        </Button>
        <DeleteButton onRemove={() => disableItem(checked)} name={"disable"} />
        <ActivateButton onActive={() => activateItem(checked)} />
        <Button
          size="xs"
          variant="subtle"
          onClick={() => setOpenFilters(!openFilters)}
          // disabled={connectProfiles.length > 0 ? false : true}
        >
          <FontAwesomeIcon icon={faFilter} size={"xl"} />
        </Button>
      </Group>
      <div
        style={{
          display: "flex",
          marginBottom: 10,
          marginLeft: 15,
        }}
      >
        <div style={{ display: openFilters ? "flex" : "none" }}>
          <UserDashboardFilterBar
            filteredData={setCurrentUserList}
            backupData={backupCurrentUserList}
            searchText={searchText}
          />
        </div>
      </div>
      {openedEdit && (
        <EditUserModalBox
          onClose={() => setOpenedEdit(false)}
          onRefesh={() => refresh()}
          userInfo={editInfo}
        />
      )}
      {openedCreate && (
        <CreateUserModalBox
          onClose={() => setOpenedCreate(false)}
          onRefresh={() => refresh()}
        />
      )}
      {openedDelete && (
        <DeleteUserModalBox
          onClose={() => {
            setOpenedDelete(false);
            setChecked([]);
          }}
          onRefresh={() => refresh()}
          itemArray={checked}
          link={`${process.env.REACT_APP_BACKEND_URL}/admin/user`}
        />
      )}
      <div style={{ overflow: "auto" }}>
        <Table>
          <thead>
            <Headers userInfo={userList[0]} />
          </thead>
          <tbody>
            {currentUserList.map((user) => (
              <Rows key={user.id} userInfo={user} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
