import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import ProtectedRoute from "./utilities/ProtectedRoute";
import { RecoilRoot } from "recoil";
import UserDashboard from "./Pages/Dashboards/UserDashboard";
import TagsDashboard from "./Pages/Dashboards/TagsDashboard";
import StaffDashboard from "./Pages/Dashboards/StaffDashboard";
import ProfileDashboard from "./Pages/Dashboards/ProfileDashboard";
import EventsDashboard from "./Pages/Dashboards/EventsDashboard";
import HomeDashboard from "./Pages/Dashboards/HomeDashboard";
import DeviceDashboard from "./Pages/Dashboards/DeviceDashboard";
import CorporatesDashboard from "./Pages/Dashboards/CorporatesDashboard";
import Preview from "./Pages/Dashboards/Peview";
import AnalysisDashboard from "./Pages/Dashboards/AnalysisDashboard";
import FutureEventDashboard from "./Pages/Dashboards/FutureEvent";
import FutureEventAnalytics from "./Pages/Dashboards/FutureEventAnalytics";
import FutureCorpAdmin from "./Pages/Dashboards/FutureCorpAdmin";
import FutureDirectory from "./Pages/Dashboards/FutureDirectory";
import TempPhoneNumber from "./Pages/Dashboards/TempPhoneNumber";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "home",
        element: <HomeDashboard />,
      },
      {
        path: "corp",
        element: <CorporatesDashboard />,
      },
      {
        path: "staff",
        element: <StaffDashboard />,
      },
      {
        path: "user",
        element: <UserDashboard />,
      },
      {
        path: "profile",
        element: <ProfileDashboard />,
      },
      {
        path: "tags",
        element: <TagsDashboard />,
      },
      {
        path: "events",
        element: <EventsDashboard />,
      },
      {
        path: "device",
        element: <DeviceDashboard />,
      },
      {
        path: "logout",
        element: <Dashboard />,
      },
      {
        path: "preview",
        element: <Preview />,
      },
      {
        path: "eventpreview",
        element: <Preview />,
      },
      {
        path: "analysis",
        element: <AnalysisDashboard />,
      },
      {
        path: "tempPhoneNumber",
        element: <TempPhoneNumber />,
      },
      {
        path: "futureEvent",
        element: <FutureEventDashboard />,
      },
      {
        path: "futureEventAnalytics",
        element: <FutureEventAnalytics />,
      },
      {
        path: "futureCorpAdmin",
        element: <FutureCorpAdmin />,
      },
      {
        path: "futureDirectory",
        element: <FutureDirectory />,
      },
    ],
  },
  {
    path: "*",
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider>
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
