import { Grid, Tabs } from "@mantine/core";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import EditButton from "../../Components/Buttons/EditButton";
import CustomLinksSection from "../../Components/CustomLinkSection";
import EventInfo from "../../Components/EventEditComponents/EventInfo";
import EventSection from "../../Components/EventEditComponents/EventSection";
import RepresentativeSection from "../../Components/EventEditComponents/RepresentativeSection";
import ProfileGallery from "../../Components/ProfileEditComponents/ProfileGallery";
import SocialLinksSection from "../../Components/SocialLinkSection";
import { editingProfileState } from "../../recoil_state";

export default function EventPreview() {
  //-------------------------------------------------------------------------------------------
  // setting
  //-------------------------------------------------------------------------------------------

  const previewPage = useRef<HTMLIFrameElement | null>(null);
  const [heads, setHeads] = useState<string[]>();
  let { state } = useLocation();
  const [editingEvent, setEditingEvent] = useRecoilState(editingProfileState);


  const openPreview = useCallback(() => {
    if (!previewPage.current?.getAttribute("src")) {
      previewPage.current?.setAttribute(
        "src",
        `${process.env.REACT_APP_NEXTJS_FRONTEND_URL}/previewPage`
      );
    }
  }, []);

  const sendInfoToNextjsPreview = useCallback(() => {
    previewPage.current?.contentWindow?.postMessage(
      { data: state, type: "loopInfo" },
      "*"
    );
  }, []);

  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (state) {
      setEditingEvent(state);
      openPreview();
      const infoHeads = [
        "Event Basic",
        "Event Info",
        "Grid Links",
        "List Links",
        "Gallery",
        "Representative",
        "Products",
      ];
      setHeads(infoHeads);
    }
    previewPage.current?.addEventListener("load", sendInfoToNextjsPreview);
    return () => {
      previewPage.current?.removeAttribute("src");
    };
  }, [openPreview, sendInfoToNextjsPreview]);

  useEffect(() => {
    previewPage.current?.contentWindow?.postMessage(
      { data: editingEvent, type: "loopInfo" },
      "*"
    );
  }, [editingEvent]);

  //-------------------------------------------------------------------------------------------
  // component
  //-------------------------------------------------------------------------------------------

  return (
    <div
      style={{
        height: window.screen.availHeight - 230,
        width: "100%",
        display: "flex",
      }}
    >
      <div style={{ height: "100%", width: "65%", padding: 0 }}>
        <div
          style={{
            height: "auto",
            width: "100%",
            padding: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {editingEvent.profile && (
            <p>
              Editing event id : {editingEvent.profile.id}, username : {editingEvent.profile.username},
               corporate : {editingEvent.profile.corporate ? editingEvent.profile.corporate.corporate_name : "not a company event"}
            </p>
          )}
        </div>
        <Tabs
          radius="lg"
          orientation="vertical"
          defaultValue="Event Basic"
          style={{ height: "100%" }}
        >
          <Tabs.List grow position="center">
            {heads &&
              heads.map((head) => (
                <Tabs.Tab key={head} value={head} style={{ padding: 0 }}>
                  {head}
                </Tabs.Tab>
              ))}
          </Tabs.List>
          <div style={{ width: "80%", padding: 0, overflow: "auto" }}>
            {editingEvent.profile.eventSection && (
              <>
                <EventSection headName={"Event Basic"} />
                <EventInfo headName={"Event Info"} />
                <RepresentativeSection
                  headName={"Representative"}
                  fetchUrl={`${
                    process.env.REACT_APP_BACKEND_URL
                  }/events/profile/${encodeURI(
                    String(editingEvent.profile.id)
                  )}`}
                  getProfileState ={"superAdmin"}
                />
                <SocialLinksSection
                  headName={"Grid Links"}
                  fetchUrl={`${
                    process.env.REACT_APP_BACKEND_URL
                  }/events/profile/${encodeURI(
                    String(editingEvent.profile.id)
                  )}`}
                />
                <CustomLinksSection
                  headName="List Links"
                  fetchUrl={`${
                    process.env.REACT_APP_BACKEND_URL
                  }/events/profile/${encodeURI(
                    String(editingEvent.profile.id)
                  )}`}
                />
                <ProfileGallery
                  headName={"Gallery"}
                  createPath={"/profiles/gallery/"}
                  editPath={"/profiles/gallery/update/"}
                  delPath={"/profiles/gallery/"}
                  products={false}
                />
                <ProfileGallery
                  headName={"Products"}
                  createPath={"/profiles/products/"}
                  editPath={"/profiles/products/"}
                  delPath={"/profiles/products/"}
                  products={true}
                />
                 <div style={{height: 100}}></div>
              </>
            )}
          </div>
        </Tabs>
      </div>

      <div style={{ height: "100%", width: "35%", padding: 0 }}>
        <iframe height="100%" width="100%" ref={previewPage}></iframe>
      </div>
    </div>
  );
}
