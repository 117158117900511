import {
  Box,
  Button,
  Group,
  Loader,
  Modal,
  NativeSelect,
  NumberInput,
  Switch,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { phoneLocationsDataState } from "../../recoil_state";
import { getMethod, patchMethod } from "../../utilities/fetchMethod";
import { OwnerProfile, ProfilesInfo, UserInfo } from "../../utilities/models";

interface Props {
  userInfo: UserInfo;
  onClose: () => void;
  onRefesh: () => void;
}

export default function EditUserModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------

  const [error, setError] = useState("");
  const phoneLocationsData = useRecoilValue(phoneLocationsDataState);
  const [profileListData, setProfileListData] =
    useState<{ value: string; label: string }[]>();

  const form = useForm({
    initialValues: props.userInfo,
    validate: {
      phone_number: (value) =>
        !isNaN(parseInt(value)) ? null : "Invalid phone number",
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  let { state } = useLocation();

  const [phoneLocation, setPhoneLocation] = useState(
    String(props.userInfo.phone_location.id)
  );
  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //edit users
  async function editItem(form: Object) {
    setError("");

    const array = Object.entries(form);
    const newForm = [];
    for (const item of array) {
      if (
        item[0] === "id" ||
        item[0] === "password" ||
        item[0] === "created_at" ||
        item[0] === "updated_at" ||
        item[0] === "active" ||
        item[0] === "last_login" ||
        item[0] === "phone_location"
      ) {
        continue;
      } else if (item[0] === "primary_profile_id") {
        const selectID = parseInt(item[1]);
        newForm.push({ [item[0]]: selectID });
      } else {
        newForm.push({ [item[0]]: item[1] });
      }
    }

    newForm.push({
      phone_location: parseInt(phoneLocation),
    });

    const _patchMethod = await patchMethod(newForm);
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/users/${encodeURI(
        String(props.userInfo.id)
      )}`,
      _patchMethod
    );
    const result = await res.json();
    if (res.status === 200) {
      props.onRefesh();
      props.onClose();
    } else if (result.error) {
      setError(result.error);
    } else {
      setError("Please try again");
    }
  }

  const userProfileSelectData = useCallback(
    (profileList: OwnerProfile[]) => {
      const newProfileList = profileList.filter(
        (item) => item.user == props.userInfo.id && item.active === true
      );
      let data: { value: string; label: string }[] = [];
      for (const profile of newProfileList) {
        let label = profile.username;
        let value = profile.id!.toString();
        data.push({ value: value, label: label });
      }
      setProfileListData(data);
    },
    [setProfileListData]
  );

  //fetch all profile
  const fetchAllProfile = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/profile/forselect/${encodeURI(
        String(state)
      )}`,
      _getMethod
    );
    const result = await res.json();
    userProfileSelectData(result);
  }, [userProfileSelectData]);

  //-------------------------------------------------------------------------------------------
  // UseEffect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchAllProfile();
  }, [fetchAllProfile]);

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Edit User Id : ${props.userInfo.id}`}
        key={`${props.userInfo.id}`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {profileListData ? (
            <>
              <div style={{ color: "red", fontSize: 15 }}>{error}</div>
              <form onSubmit={form.onSubmit((form) => editItem(form))}>
                <NativeSelect
                  data={phoneLocationsData}
                  label={"phone location"}
                  placeholder={"phone location"}
                  value={phoneLocation}
                  onChange={(event) =>
                    setPhoneLocation(event.currentTarget.value)
                  }
                  withAsterisk
                />
                {Object.entries(props.userInfo).map(([key, info]) => (
                  <div key={key}>
                    {(key === "phone_number" || key === "email") && (
                      <TextInput
                        withAsterisk
                        label={key}
                        key={`${props.userInfo.id}--${key}`}
                        {...form.getInputProps(`${key}`)}
                      />
                    )}
                    {key === "primary_profile_id" && (
                      <NativeSelect
                        data={profileListData}
                        label={key}
                        placeholder={info}
                        withAsterisk
                        {...form.getInputProps(`${key}`)}
                      />
                    )}
                    {key === "profile_top_limit" && (
                      <NumberInput
                        data={profileListData}
                        label={key}
                        placeholder={info}
                        withAsterisk
                        {...form.getInputProps(`${key}`)}
                      />
                    )}
                    {(key === "allow_chatroom_message" ||
                      key === "allow_event_message" ||
                      key === "allow_loop_message") && (
                      <Switch
                        key={`${props.userInfo.id}--${key}`}
                        style={{ margin: 15 }}
                        defaultChecked={info}
                        onLabel="Yes"
                        offLabel="No"
                        label={key}
                        {...form.getInputProps(`${key}`, { type: "checkbox" })}
                      />
                    )}
                  </div>
                ))}

                <Group position="right" mt="md">
                  <Button type="submit">Submit</Button>
                </Group>
              </form>
            </>
          ) : (
            <Loader style={{ width: "100%" }} />
          )}
        </Box>
      </Modal>
    </>
  );
}
