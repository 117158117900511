import React from "react";
import EventPng from "../../images/Event.png";

export default function FutureEventDashboard() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <img
        style={{ padding: 5 }}
        height={"100%"}
        width={"100%"}
        src={EventPng}
      />
    </div>
  );
}
