import { Tabs } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { editingProfileState } from "../recoil_state";
import { useRecoilState } from "recoil";
import EditButton from "./Buttons/EditButton";
import CreateNewSocialLinkModalBox from "./ModalBox/CreateNewSocialLinkModalBox";
import { LinkReference, Links, profileType } from "../utilities/models";
import { getMethod } from "../utilities/fetchMethod";
import SocialLinkCard from "./SocialLinkCard";
import * as lodash from "lodash";
import ChangeOrderingSocialLinkModalBox from "./ModalBox/ChangeOrderingSocialLinkModalBox";

interface SocialLinkPreviewInfoProps {
  headName: string;
  fetchUrl: string;
}

export default function CustomLinksSection({
  headName,
  fetchUrl,
}: SocialLinkPreviewInfoProps) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [editingProfile] = useRecoilState(editingProfileState);
  const [newModal, setNewModal] = useState(false);
  const [orderingList, setOrderingList] = useState<string[]>();
  const [orderingModal, setOrderingModal] = useState(false);
  const [referenceList, setReferenceList] = useState<LinkReference[]>();
  const [buildReferenceList, setBuildReferenceList] =
    useState<{ value: string; label: string; image: string }[]>();

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------
  //fetch reference
  const fetchAllReference = useCallback(async () => {
    const _getMethod = await getMethod();
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/profiles/links`,
      _getMethod
    );
    const result = await res.json();
    if (res.ok) {
      setReferenceList(result);
      buildReference(result);
    } else {
      console.log("something wrong happened");
    }
  }, []);

  //build reference list
  function buildReference(links: LinkReference[]) {
    let referenceList = [];
    referenceList.push({
      value: "-1",
      label: "upload",
      image: "",
    });
    for (const link of links) {
      const item = {
        value: String(link.id),
        label: link.title,
        image: link.icon_url,
      };
      referenceList.push(item);
    }
    setBuildReferenceList(referenceList);
  }

  const buildOrderingList = useCallback(async () => {
    let ordering: string[] = [];
    for (let i = 1; i <= editingProfile.links.length; i++) {
      ordering.push(String(i));
    }
    setOrderingList(ordering);
  }, [editingProfile.links.length]);

  //-------------------------------------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchAllReference();
  }, [fetchAllReference]);

  useEffect(() => {
    buildOrderingList();
  }, [buildOrderingList, editingProfile]);

  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <Tabs.Panel
      value={headName}
      pt="xs"
      style={{ padding: 10, marginBottom: 25, overflow: "auto" }}
    >
      {(editingProfile.profile.profile_type == profileType.corporate ||
        editingProfile.profile.profile_type == profileType.corporate_main) && (
        <div style={{ padding: 10, color: "rgb(255, 0, 255)" }}>
          *This section is applicable when executing the master update action
          for the Corp. Main Profile.
        </div>
      )}
      {newModal && (
        <CreateNewSocialLinkModalBox
          profile={true}
          custom={true}
          onClose={() => setNewModal(false)}
          onRefresh={() => {}}
          fetchUrl={fetchUrl}
        />
      )}
      {orderingModal && (
        <ChangeOrderingSocialLinkModalBox
          custom={true}
          links={lodash.sortBy(
            editingProfile.links.filter((item) => item.custom === true),
            ["customOrdering"]
          )}
          fetchUrl={fetchUrl}
          onClose={() => setOrderingModal(false)}
          onRefresh={() => {}}
        />
      )}
      <div style={{ display: "flex" }}>
        <EditButton
          name={"+ New Social Link"}
          onEdit={() => setNewModal(true)}
          style={{ marginRight: 10 }}
        />
        <EditButton
          name={"Change Order"}
          onEdit={() => setOrderingModal(true)}
        />
      </div>
      <>
        {editingProfile.links.length > 0 &&
          referenceList &&
          lodash
            .sortBy(
              editingProfile.links.filter((item) => item.custom === true),
              ["customOrdering"]
            )
            .map((item: Links) => (
              <SocialLinkCard
                reference={referenceList}
                buildReference={buildReferenceList}
                link={item}
                key={item.id}
                fetchUrl={fetchUrl}
              />
            ))}
      </>
    </Tabs.Panel>
  );
}
