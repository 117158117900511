import {
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  Select,
  Alert,
} from "@mantine/core";
import React, {  useState } from "react";
import { useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { phoneLocationsDataState } from "../../recoil_state";
import {
  getMethod,
  patchMethod,
  postMethod,
} from "../../utilities/fetchMethod";
import { profileType } from "../../utilities/models";
import { CreateProfileDto } from "./InputCSV";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  selectUserList: { value: string; label: string }[];
  corporate: boolean;
}

export default function CreateProfileModalBox(props: Props) {
  //-------------------------------------------------------------------------------------------
  // States
  //-------------------------------------------------------------------------------------------
  const [error, setError] = useState("");
  const [userId, setUserId] = useState<string | null>(null);
  const { state } = useLocation();
  const phoneLocations = useRecoilValue(phoneLocationsDataState);

  const [userPhoneNumber, setUserPhoneNumber] = useState<number | undefined>(
  );
  const [userPhoneLocationNumber, setUserPhoneLocationNumber] = useState<
    string | null
  >(null);


  const [disable, setDisable] = useState(false);

  //-------------------------------------------------------------------------------------------
  // functions
  //-------------------------------------------------------------------------------------------

  //create users
  async function createItem() {
    setError("");
    setDisable(true);
    let parseIntID = null;

    //get the user_id
    if (state !== "superAdmin" && userPhoneNumber && userPhoneLocationNumber) {
      const _patchMethod = await patchMethod({
        phone_location_id: userPhoneLocationNumber,
        phone_number: `${userPhoneNumber}`,
      });
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/admin/checkPhoneNumber`,
        _patchMethod
      );

      const result = await res.json();

      if (res.ok && result.userId) {
        parseIntID = result.userId;
      } else {
        setError(result.message ?? "please try again");
        setDisable(false);
        return;
      }
    } else {
      parseIntID = userId ? parseInt(userId) : null;
    }

      const body:CreateProfileDto = {
        user_id: parseIntID,
        profile_type:
          state === "superAdmin"
            ? profileType.individual
            : profileType.corporate,
        profile_first_name: "",
        profile_last_name: "",
      };
      const _postMethod = await postMethod(body);

      const fetchUrl =
        state === "superAdmin"
          ? `${process.env.REACT_APP_BACKEND_URL}/admin/profile`
          : `${
              process.env.REACT_APP_BACKEND_URL
            }/admin/profile?corporate=${encodeURI(String(state))}`;

      const res = await fetch(fetchUrl, _postMethod);

      const result = await res.json();


      if (result.message === "profile successfully created" || result.message === 'success') {
        props.onRefresh();
        props.onClose();
      } else {
        setError(result.message);
        setDisable(false);
      }
    
  }


  //-------------------------------------------------------------------------------------------
  // Components
  //-------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        opened={true}
        onClose={props.onClose}
        overlayColor={"grey"}
        overlayOpacity={0.55}
        overlayBlur={3}
        overflow="inside"
        title={`Create Profile`}
      >
        <Box sx={{ maxWidth: 300 }} mx="auto">
          {state === "superAdmin" ? (
            <Select
              data={props.selectUserList}
              label="User phone number"
              placeholder="select user"
              radius="xs"
              value={userId}
              onChange={setUserId}
              searchable
            />
          ) : (
            <>
              <Alert>{`please enter the user phone number that you want to link to
                 (leave it to blank if no user is linked to this profile) `}</Alert>
              <Select
                data={phoneLocations}
                label="Phone Locations"
                placeholder="select Phone Locations"
                radius="xs"
                value={userPhoneLocationNumber}
                onChange={setUserPhoneLocationNumber}
                searchable
              />
              <NumberInput
                withAsterisk
                placeholder="phone number"
                label={`Phone Number`}
                value={userPhoneNumber}
                onChange={(value) => setUserPhoneNumber(value)}
              />
            </>
          )}


          <div style={{ color: "red", fontSize: 15 }}> {error} </div>
          <Group position="right" mt="md">
            <Button
              disabled={disable}
              onClick={() => createItem()}
              type="submit"
            >
              Submit
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
}
